import React, { useContext } from 'react';
import { WorkDto } from '../../api/interfaces/frames/work/work-dto';
import './swiping-works.css';
import { getFileUrl } from '../../api/url';

import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ModalContext } from '../../context/modal-context';

interface SwipingWorksProps {
  works: WorkDto[] | undefined;
}

export const SwipingWorks: React.FC<SwipingWorksProps> = (
  {
    works
  }
) => {
  const {
    showProperWorkStateModal
  } = useContext(ModalContext);

  return (
    <div className='swiping-works'>
      <div className='swiping-works-title'>
        <span>Примеры работ</span> участников курса
      </div>

      <div className='swiping-works-swiper'>
        <Swiper
          className="swiper-WidthFull"
          slidesPerView={'auto'}
          slidesPerGroup={1}
          spaceBetween={16}
          navigation={{
            nextEl: '.swiper-buttonCont-next',
            prevEl: '.swiper-buttonCont-prev'
          }}
          pagination={{
            type: 'fraction',
            el: '.swiper-buttonCont-pagination',
            clickable: true,
            renderFraction: (currentClass: string, totalClass: string) => `<span class='${currentClass}'></span> из <span class='${totalClass}'></span>`
          }}
          modules={[
            Navigation,
            Pagination
          ]}
        >
          {works && works.map((work, index) => (
            <SwiperSlide key={index}>
              <div
                className='swiping-works-slide'
                onClick={() => showProperWorkStateModal({
                  index,
                  works
                })}
              >
                <div className='swiping-works-slide-img-area'>
                  <img
                    src={getFileUrl(work.workId)}
                    alt=''
                    className='swiping-works-slide-img'
                  />
                </div>

                {work.author &&
                  <div className='work-slide__author'>
                    Автор: {work.author}
                  </div>}
              </div>
            </SwiperSlide>
          ))}

          <div className='swiper-buttonCont swiper-buttonCont--grid'>
            <div className='swiper-buttonCont-button swiper-buttonCont-prev'>
              <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='28' cy='28' r='27.25' transform='matrix(-1 0 0 1 56 0)' fill='white'
                        stroke='#0D0D0D' strokeWidth='1.5'/>
                <path
                  d='M16.6619 27.9811H39.3378M16.6619 27.9811C20.835 27.7513 29.2342 27.5261 29.2342 16.9941M16.6619 27.9811C20.8217 28.2038 29.2342 28.4737 29.2342 39.0057'
                  stroke='#0D0D0D' strokeWidth='1.5'/>
              </svg>
            </div>

            <div className='swiper-buttonCont-pagination'></div>

            <div className='swiper-buttonCont-button swiper-buttonCont-next'>
              <svg width='56' height='57' viewBox='0 0 56 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='28' cy='28' r='27.25' transform='matrix(-1 0 0 1 56 0.333008)' fill='white'
                        stroke='#0D0D0D' strokeWidth='1.5'/>
                <path
                  d='M39.3359 28.3141H16.66M39.3359 28.3141C35.1628 28.0843 26.7636 27.8591 26.7636 17.3271M39.3359 28.3141C35.1761 28.5368 26.7636 28.8068 26.7636 39.3387'
                  stroke='#0D0D0D' strokeWidth='1.5'/>
              </svg>
            </div>
            <div className='work-community-linePos'></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};
