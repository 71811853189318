import React, { useContext, useLayoutEffect, useRef } from 'react';
import { Frame } from '../../../components/frame';
import { IdProps } from '../../../interfaces/id-props';
import { CourseFormat } from '../../../api/interfaces/course/course-format';
import { CourseDto } from '../../../api/interfaces/course/course-dto';
import './index.css';
import { CoursesFilter } from './filter/use-courses-filter';
import { DynamicCoursesEnumeration } from './courses-enumeration/dynamic-courses-enumeration';
import gsap from "gsap";
import { CategoryDto } from "../../../api/interfaces/category/category-dto";
import { ModalContext } from "../../../context/modal-context";

interface CoursesFrameProps extends IdProps {
  coursesFilter: CoursesFilter;
  courseAmountInfo: CourseAmountInfo;
  allCatalog: boolean;
  specificEnabled: boolean;
}

export interface CourseAmountInfo {
  formatAmountsMap: Map<CourseFormat, CourseDto[]>;
  categoriesAmountsMap: Map<CategoryDto, CourseDto[]>;
}

export const CoursesFrame: React.FC<CoursesFrameProps> = (
  {
    id,
    coursesFilter,
    courseAmountInfo,
    allCatalog,
    specificEnabled
  }
) => {
  const dynamicCoursesRef = useRef({} as HTMLDivElement);
  const dynamicCoursesDivHeight = useRef(0);

  useLayoutEffect(() => {
    dynamicCoursesDivHeight.current = dynamicCoursesRef.current.clientHeight;
  }, [coursesFilter]);

  useLayoutEffect(() => {
    gsap.from('.courses-frame-address-area', {
      opacity: 0,
      ease: 'bounce.in'
    });

    gsap.to('.courses-frame-address-area', {
      opacity: 1
    });
  }, [coursesFilter.selectedFormat]);

  const {
    showProperLocationModal
  } = useContext(ModalContext);

  return (
    <Frame
      id={id}
      coverClassName={'bg-design-bim-true-white'}
      bgClassName={'bg-brand-itmo-bluetiful'}
      sectionClassName={'with-cover no-padding-x no-padding-top'}
    >
      <div className='courses-frame'>
        {!allCatalog ? coursesFilter.CoursesFilter : (
          <div className='courses-frame-title'>
            <span>Весь каталог</span> ИТМО
          </div>
        )}

        {!allCatalog && coursesFilter.selectedFormat === CourseFormat.offline && (
          <div className='courses-frame-address-area'>
            <div
              onClick={() => showProperLocationModal()}
              className='courses-frame-address-area-wrapper'
            >
              <div className='courses-frame-address-text'>
                <svg className='courses-frame-address-icon' xmlns="http://www.w3.org/2000/svg" width="24"
                     height="24" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="7" stroke="#0B68FE" strokeWidth="1.5"/>
                  <circle id='inner-circle' cx="12" cy="12" r="2" fill="#0B68FE" stroke="#0B68FE" strokeWidth="1.5"/>
                  <path d="M12 5V3" stroke="#0B68FE" strokeWidth="1.5" strokeLinecap="round"/>
                  <path d="M19 12L21 12" stroke="#0B68FE" strokeWidth="1.5" strokeLinecap="round"/>
                  <path d="M12 21L12 19" stroke="#0B68FE" strokeWidth="1.5" strokeLinecap="round"/>
                  <path d="M3 12H5" stroke="#0B68FE" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
                Очное обучение проводится по адресу: <span>г. Санкт-Петербург, корпус ИТМО на ул. Гастелло, д. 12 (ауд. 406)</span>
              </div>
            </div>
          </div>
        )}

        <div
          className='dynamic-courses-enumeration-area'
          ref={dynamicCoursesRef}
        >
          <DynamicCoursesEnumeration
            courseAmountInfo={courseAmountInfo}
            coursesFilter={coursesFilter}
            dynamicCoursesDivHeight={dynamicCoursesDivHeight}
            allCatalog={allCatalog}
            specificEnabled={specificEnabled}
          />
        </div>
      </div>
    </Frame>
  );
};
