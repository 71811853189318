import { FormatSelector } from "./index";
import React, { useMemo, useState } from "react";
import { CourseFormat } from "../../../../api/interfaces/course/course-format";

export const useFormatSelector = (
  anyOffline: boolean,
  anyOnline: boolean
) => {
  const [
    courseFormat,
    setCourseFormat
  ] = useState<CourseFormat | undefined>(undefined);

  const formatSelector = useMemo(() => (
    <FormatSelector
      anyOffline={anyOffline}
      anyOnline={anyOnline}
      courseFormat={courseFormat}
      setCourseFormat={setCourseFormat}
    />
  ), [
    courseFormat,
    setCourseFormat,
    anyOffline,
    anyOnline
  ]);

  return {
    courseFormat,
    formatSelector
  }
}
