import React from 'react';
import './index.css';

export const BurgerMenuIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66602 9.33337H25.3327" stroke="#0D0D0D" strokeWidth="2" strokeLinecap="square"/>
    <path d="M6.66602 16H25.3327" stroke="#0D0D0D" strokeWidth="2" strokeLinecap="square"/>
    <path d="M6.66602 22.6666H25.3327" stroke="#0D0D0D" strokeWidth="2" strokeLinecap="square"/>
  </svg>
);

export const BurgerMenuCloseIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M24 8L8 24" stroke="#0D0D0D" strokeWidth="2" strokeLinecap="square"
          strokeLinejoin="round"/>
    <path d="M8 8L24 24" stroke="#0D0D0D" strokeWidth="2" strokeLinecap="square"
          strokeLinejoin="round"/>
  </svg>
);
