import { useState } from 'react';
import s from './style.module.css'
import threeDImg from "./images/3d.svg"
import bimImg from "./images/bim.svg"
import cadImg from "./images/cad.svg"
import codeImg from "./images/code.svg"
import motionImg from "./images/motion.svg"

const Block_redirects = ({ course, logo_up }) => {
    const [open, setOpen] = useState('close');
    let imgPath = [
        ['78c70041-f5e6-4b08-8fae-d34613f859e9', [codeImg, "https://code.itmo.ru/"]],
        ['b5cbd2e3-5cad-4b5c-943c-872bc2b9105c', [threeDImg, "https://3d.itmo.ru/"]],
        ['4d6f356f-e56a-4436-82f3-d5378a742122', [bimImg, "https://bim.itmo.ru/"]],
        ['f033572d-9a83-4056-bad3-6e3f943e438d', [cadImg, "https://cad.itmo.ru"]],
        ['f13d2748-d389-475b-ba7f-19b12f42c83b', [motionImg, "https://md.itmo.ru/"]]
    ]
    const changeRedirect = function () {
        open == 'open' ? setOpen('close') : setOpen('open')
    }
    return (
        <div className={s.redirect_block} data-open={open}>
            <div className={s.redirect_content}>
                <div className={s.redir_list} data-open={open}>
                    <div onClick={changeRedirect}>
                        {imgPath.map(item => {
                            if (item[0] == course) {
                                return <img src={logo_up} alt={item[0]} className={s.logo} />
                            }
                        })}
                    </div>
                    <ul>
                        {imgPath.map(item => {
                            if (item[0] != course) {
                                return <li key={item[0]}><a href={item[1][1]}><img src={item[1][0]} className={s.logo} alt={item[0]} /></a></li>
                            }
                        })}
                    </ul>
                </div>
                <button className={s.redir_butt} data-open={open} onClick={changeRedirect}>
                    <svg width="15" height="9" viewBox="0 0 15 9" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.665 1L7.66504 7L1.66504 1" stroke="#fff" stroke-width="2" />
                    </svg>
                </button>
            </div>
            <span className={s.redir_help}>Другие направления</span>
        </div>
    )
} 
export default Block_redirects;