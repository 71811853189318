import React, { useMemo, useState } from 'react';
import { useModal } from '../../hooks/use-modal';
import { SignupModalContent, SignupModalState } from './index';

export const useSignupModal = (
  onClick?: () => void
) => {
  const [
    signupModalState,
    setSignupModalState
  ] = useState({} as SignupModalState);

  const {
    Modal,
    showModal,
  } = useModal('signup-modal-frame-id', onClick);

  const showStatedModal = (signupModalState: SignupModalState) => {
    setSignupModalState(signupModalState);
    showModal();
  }

  const SignupModal = useMemo(() => (
    <Modal
      classList={['modalMain-wrapper--main']}
    >
      <SignupModalContent
        signupModalState={signupModalState}
      />
    </Modal>
  ), [
    Modal,
    signupModalState
  ]);

  return (
    {
      SignupModal,
      showModal,
      showStatedModal
    }
  );
};
