import React, { useContext, useEffect } from 'react';
import "./index.css";
import { Provider } from "react-redux";
import { navBarStore } from "../../components/nav-bar/store/store";
import { NavBar } from "../../components/nav-bar";
import { DirectionContext } from "../../context/direction-context";
import { RelevantLink } from "../../components/relevant-link";
import { useFadeAnimation } from "../../hooks/animation/use-fade-animation";
import { colors } from "../../constants/colors";
import { CustomButton } from "../../components/button";
import { SplashScreenContext } from "../../context/splash-screen-context";

export const NotFoundPage: React.FC = () => {
  const {
    direction
  } = useContext(DirectionContext);

  useEffect(() => {
    document.title = "Страница не найдена";
  }, []);

  useFadeAnimation(".not-found-inner-wrapper", 800);

  const {
    setLoading
  } = useContext(SplashScreenContext);

  return (
    <div className='online-course'>

      <Provider store={navBarStore}>
        <NavBar
          className={`catalog`}
          navBar={direction?.navBar}
        />
      </Provider>

      <div className="not-found">
        <div className="not-found-inner-wrapper">
          <svg className="background-image" width="700" height="700" viewBox="0 0 700 700" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <circle cx="350" cy="350" r="350" fill="#E3EAF7" fill-opacity="0.02"/>
            <circle cx="350" cy="350" r="262.5" fill="#E3EAF7" fill-opacity="0.04"/>
            <circle cx="350" cy="350" r="175" fill="#E3EAF7" fill-opacity="0.06"/>
          </svg>
          <div className="nf-info-area">
            <div className="nf-status">
              404
            </div>

            <div className="nf-text-area">
              <div className="nf-text-area-inner-wrapper">
                <div className="nf-text">
                  Упс... Вы попали на несуществующую страницу
                </div>
              </div>

              <div className="nf-button-area">
                <RelevantLink to="/" relative="route">
                  <CustomButton
                    buttonType="button"
                    type="button"
                    title="Перейти в каталог"
                    onClick={() => setLoading(true)}
                    classList={[
                      "button--green",
                      'border--offline-color',
                      'color--online-color-text'
                    ]}
                    arrowVisible
                    arrowClassName='color--offline-color'
                  />
                </RelevantLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
