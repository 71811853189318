import React, { useEffect } from 'react';
import { ChildrenProps } from '../../../interfaces/children-props';
import parse from 'html-react-parser';

interface QuoteAreaProps extends ChildrenProps {
  quoteFacts: string[] | undefined;
}

export const QuoteArea: React.FC<QuoteAreaProps> = (
  {
    quoteFacts,
    children
  }
) => {
  useEffect(() => {
    const adjustFontSize = () => {
      const quote = document.getElementById('refresher-secondScreen-content-description__quote');
      const quoteContent = document.getElementById('refresher-secondScreen-content-description-col');

      if (quoteContent) {
        let fontSize = parseInt(window.getComputedStyle(quoteContent).getPropertyValue('height'));
        fontSize = fontSize < 148 ? 148 : fontSize;
        quote?.style.setProperty('height', fontSize + "px");
        quote?.style.setProperty('display', "unset");
      }
    };

    adjustFontSize();

    window.addEventListener('resize', adjustFontSize);

    return () => {
      window.removeEventListener('resize', adjustFontSize);
    }
  }, []);

  return quoteFacts && (
    <div className='refresher-secondScreen-content'>
      <div className='refresher-secondScreen-content-description'>
        <div
          id={"refresher-secondScreen-content-description__quote"}
          className='refresher-secondScreen-content-description__quote'
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 48 145" fill="none">
            <path
              d="M47.94 144.33C20.06 141.78 11.73 130.56 11.73 112.37V97.75C11.73 83.98 7.65 81.43 0 81.43V62.9C7.65 62.9 11.73 60.35 11.73 46.75V31.96C11.73 13.77 19.89 2.55 47.6 0V12.92C33.15 15.98 28.73 21.08 28.73 33.83V48.62C28.73 68.51 17 68.68 12.75 69.19V75.14C16.83 75.82 28.73 75.82 28.73 95.71V110.67C28.73 123.42 33.49 128.35 47.94 131.41V144.33Z"
              fill="#00CCFF"/>
          </svg>
        </div>

        <div
          id={'refresher-secondScreen-content-description-col'}
          className='refresher-secondScreen-content-description-col'
        >
          {quoteFacts.map((quoteFact, index) => (
            <p
              key={index}
              className='refresher-secondScreen-content-description__text'
            >
              {parse(quoteFact)}
            </p>
          ))}
        </div>
      </div>

      {children}
    </div>
  );
};
