import "./form-commons.css";
import { FieldError } from "react-hook-form";

export const errorMessage = (error: FieldError | undefined) => {
  if (error) {
    switch (error.type) {
      case "maxLength":
        return <span className="error-text">Поле ограничено по длине</span>;
      case "pattern":
        return <span className="error-text">Поле заполнено неверно</span>;
      case "required":
        return <span className="error-text">Поле обязательно к заполнению</span>;
    }
  }
}; 