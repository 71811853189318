import React, { useContext } from 'react';
import './index.css';
import { DirectionContext } from '../../../../context/direction-context';
import parse from "html-react-parser";

export const InfoName: React.FC = () => {
  const {
    direction
  } = useContext(DirectionContext);

  return (
    <div className='info-name'>
      {parse(direction?.name ?? "")}
    </div>
  );
};
