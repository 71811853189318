import React, { useContext, useMemo } from 'react';
import './index.css';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import { IdProps } from '../../interfaces/id-props';
import { CourseContext } from '../../context/course-context';
import { CourseType } from '../../api/interfaces/course/course-type';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { useParams } from 'react-router-dom';
import { CourseCard } from '../../components/course-card';
import { SplashScreenContext } from "../../context/splash-screen-context";

export const OtherCoursesFrame: React.FC<IdProps> = ({ id }) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;
  const allCourses = courseData?.allCourses;

  const {
    coursePath
  } = useParams();

  const otherCourses = useMemo(() => {
    return (allCourses ?? []).filter(course => course.path !== coursePath);
  }, [
    allCourses,
    coursePath
  ]);

  const {
    setLoading
  } = useContext(SplashScreenContext);

  const OtherCoursesFrameContent = useMemo(() => (
    <div className='other-courses-frame-cover'>
      <BorderedTitle
        titleClassList={['text-design-bim-true-white', `${course!.type.toLowerCase()}`, `${course!.format.toLowerCase()}`]}
      >
        <span>Другие курсы</span> ИТМО
      </BorderedTitle>

      <div className='oc-courses-area'>
        {(course?.recommendedCourses ?? []).map(recommendedCourse => {
          const foundRecommendedCourse = otherCourses?.find(otherCourse => otherCourse.fullPath === recommendedCourse);

          return (
            <CourseCard
              course={foundRecommendedCourse}
              relevantClassName={`${foundRecommendedCourse?.type.toLowerCase()} ${foundRecommendedCourse?.format.toLowerCase()}`}
              setLoading={setLoading}
            />
          )
        })}
      </div>
    </div>
  ), [setLoading, course, otherCourses]);

  return useMemo(() => {
    switch (course!.type) {
      case CourseType.plain:
        switch (course!.format) {
          case CourseFormat.online:
          case CourseFormat.offline:
            return (
              <div className='outer-background-margin bg-brand-itmo-bluetiful border--design-bim-primary-black--top-bottom'>
                <Frame
                  id={id}
                  bgClassName='inner-background-margin'
                  sectionClassName={'with-cover'}
                  // bgClassName={`bg-brand-itmo-bluetiful`}
                  coverClassName={`other-courses-frame bg-design-bim-true-white border--design-bim-primary-black`}
                >
                  {OtherCoursesFrameContent}
                </Frame>
              </div>
            );
        }
        break;
      case CourseType.refresher:
        switch (course!.format) {
          case CourseFormat.online:
            return (
              <Frame
                id={id}
                sectionClassName={''}
                coverClassName={`other-courses-frame no-padding-bottom ${course!.type.toLowerCase()}`}
              >
                {OtherCoursesFrameContent}
              </Frame>
            );
        }
        break;
    }
  }, [OtherCoursesFrameContent, course, id]);
};
