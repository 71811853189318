export interface CategoryDto {
  id: string;
  type: string;
  name: string;
  iconId: string;
}

export const nearestCategory: CategoryDto = {
  name: "Ближайшие",
  type: "nearest",
} as CategoryDto;
