import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Frame } from '../../components/frame';
import { IdProps } from '../../interfaces/id-props';
import './index.css';
import { CourseContext } from '../../context/course-context';
import parse from 'html-react-parser';
import { Module } from './module';
import { ifClassExists } from '../../utils/if-class-exists';
import { useTrigger } from '../../hooks/use-trigger';

export const ComposedProgramFrame: React.FC<IdProps> = (
  {
    id
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;
  const courseModules = course?.composedProgramFrame?.modules;

  const [currentModule, setCurrentModule] = useState<string | undefined>();

  const onScroll = useCallback(() => {
    const coordinate = window.scrollY;

    if (courseModules) {
      for (let index = 0; index < courseModules.length; index++) {
        const element = document.getElementById(`course-module-${index}-id`)?.getBoundingClientRect();

        if (element && element.top + coordinate <= coordinate + 500 && element.bottom + coordinate >= coordinate + 500) {
          setCurrentModule(`course-module-${index}-id`);
          break;
        }
      }
    }
  }, [
    courseModules
  ]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [
    onScroll
  ]);

  const [allModulesVisible, {
    offHandler: allModulesVisibleOff,
    onHandler: allModulesVisibleOn,
  }] = useTrigger(false);

  const Modules = useMemo(() => (
    <div className='cpf-left-modules'>
      {courseModules && courseModules.map((courseModule, index) => (
        <Module
          id={`course-module-${index}-id`}
          composedProgramModule={courseModule}
          index={index}
          className={`${ifClassExists('all-modules-visible', allModulesVisible)}`}
          isIntroductionModule={!!(index === 0 && course?.composedProgramFrame?.hasIntroductionModule)}
          hasIntroductionModule={!!course?.composedProgramFrame?.hasIntroductionModule}
        />
      ))}

      {!allModulesVisible && (
        <div
          className='cpf-left-modules-all-button'
          onClick={allModulesVisibleOn}
        >
          <div className='cpf-left-modules-all-button--text'>Все модули</div>
          <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M18.5 9L12.5 15L6.5 9' stroke='#33363F' strokeWidth='2'/>
          </svg>
        </div>
      )}
    </div>
  ), [allModulesVisible, allModulesVisibleOn, courseModules]);

  return (
    <Frame
      id={id}
      sectionClassName={'padding-top-60 padding-bottom-60'}
      bgClassName={'bg-gradient-black'}
    >
      <div className='composed-program-frame'>
        <div className='cpf-area'>
          <div className='cpf-area-left'>
            <div className='cpf-left-title-area'>
              <div className='cpf-left-title-area-text'>
                {parse(course?.composedProgramFrame?.title ?? '')}
              </div>

              <div className='cpf-left-title-area-tags-area'>
                {(course?.composedProgramFrame?.tags ?? []).map(tag => (
                  <div className='cpf-left-title-area-tag'>
                    {tag}
                  </div>
                ))}
              </div>
            </div>

            {Modules}
          </div>

          <div className='cpf-area-right'>
            <div className='cpf-right-modules'>
              {courseModules && courseModules.map((courseModule, index) => (
                courseModule && (
                  <div
                    className={`cpf-right-modules-item ${ifClassExists('current', `course-module-${index}-id` === currentModule)}`}
                    onClick={() => document.getElementById(`course-module-${index}-id`)?.scrollIntoView({ behavior: 'smooth' })}
                  >
                    {courseModule.moduleName ? courseModule.moduleName : parse(courseModule.course?.name ?? "") }
                  </div>
                )
              ))}
            </div>
          </div>
        </div>
      </div>
    </Frame>
  );
};
