import React from 'react';

export const PlayLogo = () => (
  <>
    <svg
      className="ed-procces-imgPlay-svg-svgRotate"
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
    >
      <g clipPath="url(#clip0_5087_645)">
        <path
          d="M139.034 119.646C139.303 119.241 139.627 118.909 139.995 118.639C140.363 118.379 140.759 118.208 141.199 118.136C141.639 118.064 142.106 118.082 142.609 118.208C143.113 118.334 143.634 118.576 144.182 118.945L144.424 119.107C144.936 119.448 145.332 119.826 145.61 120.248C145.889 120.67 146.077 121.102 146.158 121.533C146.239 121.973 146.23 122.405 146.131 122.836C146.033 123.267 145.853 123.672 145.601 124.049C145.35 124.427 145.071 124.723 144.766 124.957C144.46 125.19 144.146 125.352 143.813 125.442C143.481 125.541 143.149 125.577 142.807 125.55C142.466 125.523 142.133 125.442 141.801 125.316L142.52 124.238C142.708 124.274 142.906 124.301 143.095 124.301C143.283 124.301 143.463 124.274 143.643 124.22C143.822 124.157 143.993 124.067 144.164 123.932C144.334 123.798 144.487 123.627 144.64 123.402C144.793 123.169 144.9 122.917 144.963 122.647C145.026 122.378 145.026 122.099 144.963 121.821C144.9 121.542 144.775 121.254 144.568 120.976C144.361 120.697 144.074 120.428 143.688 120.167L143.445 120.005C143.041 119.736 142.654 119.547 142.304 119.457C141.945 119.358 141.621 119.34 141.334 119.385C141.046 119.43 140.786 119.538 140.552 119.691C140.327 119.853 140.13 120.041 139.977 120.275C139.716 120.661 139.591 121.057 139.6 121.452C139.609 121.848 139.752 122.216 140.004 122.548L139.285 123.627C138.755 123.052 138.468 122.414 138.423 121.695C138.378 120.985 138.575 120.293 139.016 119.637L139.034 119.646Z"
          fill="#0B68FE"
        />
        <path
          d="M142.323 128.488L141.443 129.513L136.079 128.461L137.894 133.62L137.067 134.581L131.299 129.603L132.126 128.641L136.312 132.263L134.857 128.012L135.513 127.248L139.924 128.075L135.737 124.453L136.564 123.501L142.332 128.479L142.323 128.488Z"
          fill="#0B68FE"
        />
        <path
          d="M127.417 132.981C127.776 132.657 128.18 132.406 128.612 132.226C129.043 132.046 129.492 131.965 129.959 131.983C130.426 132.001 130.894 132.118 131.37 132.352C131.846 132.576 132.304 132.936 132.735 133.421L132.924 133.637C133.337 134.095 133.625 134.562 133.787 135.048C133.948 135.533 134.011 136 133.966 136.459C133.921 136.917 133.796 137.348 133.571 137.762C133.346 138.175 133.059 138.543 132.691 138.867C132.331 139.19 131.927 139.442 131.496 139.622C131.064 139.801 130.615 139.882 130.157 139.873C129.699 139.873 129.241 139.757 128.773 139.541C128.306 139.325 127.875 138.993 127.462 138.534L127.273 138.319C126.833 137.833 126.527 137.339 126.357 136.845C126.177 136.351 126.105 135.865 126.141 135.407C126.177 134.94 126.303 134.508 126.527 134.095C126.752 133.682 127.039 133.313 127.408 132.99L127.417 132.981ZM128.189 133.834C127.974 134.023 127.794 134.257 127.65 134.526C127.507 134.796 127.417 135.084 127.399 135.389C127.381 135.695 127.435 136.027 127.56 136.378C127.686 136.728 127.92 137.088 128.252 137.456L128.441 137.672C128.746 138.013 129.061 138.265 129.384 138.409C129.708 138.561 130.022 138.633 130.319 138.651C130.624 138.66 130.912 138.606 131.19 138.48C131.469 138.364 131.711 138.202 131.927 138.013C132.143 137.824 132.322 137.591 132.475 137.33C132.628 137.07 132.709 136.782 132.735 136.476C132.762 136.171 132.718 135.847 132.601 135.515C132.484 135.182 132.277 134.841 131.972 134.499L131.783 134.284C131.451 133.915 131.118 133.646 130.786 133.484C130.453 133.322 130.13 133.232 129.825 133.214C129.519 133.205 129.223 133.25 128.944 133.367C128.666 133.484 128.414 133.637 128.198 133.834H128.189Z"
          fill="#0B68FE"
        />
        <path
          d="M126.06 142.651L128.162 141.123L128.818 142.031L123.58 145.832L122.925 144.924L125.027 143.397L121.209 138.14L122.233 137.394L126.051 142.651H126.06Z"
          fill="#0B68FE"
        />
        <path
          d="M121.936 146.902L120.912 147.486L120.193 146.237C120.22 146.803 120.104 147.324 119.834 147.792C119.564 148.259 119.169 148.645 118.639 148.951C117.812 149.427 116.986 149.535 116.177 149.283C115.369 149.023 114.65 148.358 114.039 147.288L113.895 147.037C113.572 146.471 113.356 145.94 113.248 145.446C113.141 144.952 113.132 144.494 113.212 144.071C113.293 143.658 113.464 143.28 113.716 142.948C113.976 142.615 114.3 142.328 114.713 142.094C115.279 141.771 115.818 141.618 116.339 141.654C116.86 141.69 117.327 141.834 117.732 142.094L115.62 138.419L116.716 137.79L121.945 146.884L121.936 146.902ZM115.512 142.939C115.243 143.092 115.027 143.289 114.857 143.523C114.686 143.757 114.569 144.026 114.515 144.323C114.461 144.619 114.47 144.943 114.542 145.302C114.623 145.653 114.767 146.03 114.991 146.417L115.135 146.668C115.342 147.028 115.575 147.315 115.827 147.549C116.078 147.783 116.357 147.935 116.636 148.034C116.914 148.133 117.202 148.16 117.498 148.133C117.786 148.097 118.064 148.007 118.334 147.854C118.63 147.684 118.882 147.477 119.07 147.234C119.259 146.992 119.403 146.74 119.475 146.471C119.555 146.201 119.573 145.922 119.537 145.626C119.502 145.338 119.412 145.06 119.259 144.79L118.684 143.793C118.513 143.496 118.307 143.244 118.064 143.056C117.821 142.867 117.561 142.732 117.291 142.66C117.013 142.588 116.725 142.57 116.42 142.615C116.114 142.66 115.809 142.768 115.512 142.939Z"
          fill="#0B68FE"
        />
        <path
          d="M107.696 145.571C108.145 145.383 108.603 145.266 109.07 145.23C109.538 145.194 109.987 145.266 110.418 145.427C110.849 145.589 111.263 145.859 111.64 146.227C112.017 146.596 112.341 147.09 112.601 147.692L112.718 147.953C112.97 148.537 113.096 149.076 113.104 149.579C113.104 150.082 113.033 150.541 112.862 150.954C112.691 151.368 112.44 151.727 112.107 152.041C111.775 152.347 111.397 152.599 110.966 152.787C109.933 153.228 109.017 153.291 108.217 152.967C107.408 152.644 106.788 151.97 106.348 150.936L106.034 150.199L111.344 147.917C111.164 147.503 110.948 147.18 110.697 146.955C110.445 146.731 110.176 146.578 109.888 146.488C109.601 146.407 109.313 146.38 109.017 146.407C108.72 146.443 108.442 146.515 108.181 146.623C107.651 146.847 107.283 147.108 107.076 147.413C106.869 147.719 106.761 148.043 106.77 148.402L105.638 148.887C105.567 148.519 105.567 148.177 105.638 147.836C105.71 147.503 105.836 147.198 106.025 146.91C106.213 146.623 106.447 146.371 106.744 146.137C107.04 145.904 107.363 145.715 107.741 145.553L107.696 145.571ZM110.427 151.754C110.697 151.637 110.948 151.484 111.173 151.305C111.397 151.116 111.568 150.9 111.703 150.658C111.829 150.406 111.901 150.136 111.919 149.84C111.937 149.543 111.874 149.22 111.721 148.878L107.507 150.685C107.669 151.044 107.867 151.323 108.082 151.529C108.298 151.736 108.531 151.871 108.783 151.943C109.035 152.015 109.304 152.033 109.583 151.997C109.861 151.961 110.149 151.88 110.427 151.763V151.754Z"
          fill="#0B68FE"
        />
        <path
          d="M103.581 154.19L106.061 153.408L106.394 154.477L100.221 156.409L99.8888 155.34L102.369 154.558L100.428 148.357L101.632 147.98L103.572 154.181L103.581 154.19Z"
          fill="#0B68FE"
        />
        <path
          d="M98.3533 156.876L97.1135 157.137L96.5295 154.324L94.7416 154.692C93.8072 154.89 93.0705 154.827 92.5404 154.504C92.0103 154.18 91.6689 153.659 91.5162 152.922C91.3724 152.212 91.4713 151.601 91.8306 151.089C92.19 150.577 92.8369 150.235 93.7803 150.037L96.808 149.408L98.3623 156.867L98.3533 156.876ZM94.0767 151.125C93.4927 151.242 93.1064 151.43 92.9177 151.691C92.7291 151.943 92.6752 152.275 92.756 152.679C92.8369 153.084 93.0166 153.362 93.2951 153.524C93.5736 153.677 94.0049 153.704 94.5799 153.578L96.2959 153.219L95.7838 150.765L94.0678 151.125H94.0767Z"
          fill="#0B68FE"
        />
        <path
          d="M86.5818 158.826L80.2658 159.132L79.8974 151.52L81.1642 151.457L81.4787 157.946L85.2701 157.766L84.9557 151.278L86.2225 151.215L86.5908 158.826H86.5818Z"
          fill="#0B68FE"
        />
        <path
          d="M77.348 159.113L76.1711 159.032L76.2699 157.595C75.9734 158.08 75.5871 158.448 75.1109 158.691C74.6348 158.934 74.0777 159.032 73.4668 158.987C72.5144 158.925 71.7687 158.556 71.2386 157.891C70.7086 157.226 70.4839 156.274 70.5648 155.042L70.5828 154.755C70.6277 154.108 70.7445 153.542 70.9242 153.065C71.1039 152.589 71.3554 152.203 71.6519 151.906C71.9484 151.601 72.2988 151.385 72.6941 151.25C73.0894 151.115 73.5297 151.061 73.9969 151.097C74.6437 151.142 75.1828 151.322 75.5961 151.637C76.0094 151.951 76.3148 152.337 76.5035 152.778L76.791 148.545L78.0488 148.626L77.348 159.113ZM74.2125 152.239C73.907 152.221 73.6105 152.257 73.341 152.355C73.0625 152.454 72.8199 152.616 72.6133 152.832C72.3976 153.047 72.2269 153.326 72.0922 153.659C71.9574 153.991 71.8765 154.386 71.8496 154.836L71.8316 155.123C71.8047 155.537 71.8316 155.905 71.9215 156.238C72.0113 156.57 72.1461 156.858 72.3257 157.091C72.5054 157.334 72.7301 157.514 72.9906 157.648C73.2511 157.783 73.5297 157.855 73.8351 157.882C74.1765 157.909 74.5 157.873 74.7965 157.783C75.093 157.693 75.3445 157.559 75.5601 157.37C75.7758 157.181 75.9465 156.966 76.0812 156.705C76.216 156.444 76.2879 156.157 76.3148 155.851L76.3957 154.701C76.4227 154.359 76.3867 154.036 76.2879 153.739C76.1891 153.443 76.0543 153.191 75.8656 152.976C75.6769 152.76 75.4433 152.589 75.1648 152.454C74.8863 152.32 74.5719 152.239 74.2304 152.221L74.2125 152.239Z"
          fill="#0B68FE"
        />
        <path
          d="M67.94 158.305L66.7002 158.071L67.7603 152.419L62.8279 157.343L61.588 157.109L62.9986 149.624L64.2384 149.857L63.1783 155.51L68.1107 150.585L69.3506 150.819L67.94 158.305Z"
          fill="#0B68FE"
        />
        <path
          d="M58.6945 156.418L57.4097 155.996L56.2957 150.64L52.2437 154.306L51.0398 153.911L53.4027 146.668L54.6066 147.063L52.8906 152.329L56.2418 149.337L57.2031 149.651L58.1465 154.046L59.8625 148.779L61.0664 149.175L58.7035 156.418H58.6945Z"
          fill="#0B68FE"
        />
        <path
          d="M48.7938 144.403C49.234 144.609 49.6204 144.879 49.9528 145.203C50.2852 145.526 50.5368 145.912 50.6985 146.344C50.8692 146.775 50.9321 147.26 50.9051 147.791C50.8782 148.321 50.7254 148.878 50.4379 149.48L50.3122 149.741C50.0426 150.316 49.7192 150.765 49.3508 151.107C48.9825 151.448 48.5782 151.682 48.1559 151.835C47.7336 151.987 47.2934 152.032 46.8442 151.987C46.3949 151.942 45.9547 151.817 45.5324 151.619C44.5172 151.143 43.8613 150.496 43.5738 149.678C43.2863 148.86 43.3762 147.943 43.8524 146.928L44.1938 146.2L49.4137 148.671C49.6024 148.267 49.7012 147.89 49.7012 147.548C49.7012 147.207 49.6383 146.901 49.5125 146.631C49.3868 146.362 49.2161 146.128 48.9914 145.921C48.7758 145.724 48.5332 145.562 48.2727 145.436C47.7516 145.194 47.3114 145.086 46.943 145.131C46.5836 145.167 46.2692 145.319 45.9996 145.562L44.8856 145.032C45.1102 144.735 45.3707 144.502 45.6672 144.34C45.9637 144.169 46.2782 144.061 46.6106 144.016C46.943 143.971 47.2934 143.98 47.6618 144.043C48.0301 144.106 48.3895 144.232 48.7579 144.403H48.7938ZM45.9727 150.541C46.2422 150.666 46.5207 150.756 46.8082 150.801C47.0957 150.846 47.3743 150.837 47.6438 150.765C47.9133 150.693 48.1649 150.567 48.4075 150.388C48.6411 150.199 48.8387 149.938 49.0004 149.606L44.8586 147.647C44.6969 148.006 44.616 148.339 44.607 148.635C44.607 148.932 44.652 149.202 44.7598 149.435C44.8676 149.669 45.0293 149.884 45.2449 150.073C45.4606 150.262 45.7031 150.415 45.9817 150.549L45.9727 150.541Z"
          fill="#0B68FE"
        />
        <path
          d="M40.3135 148.815L39.3162 148.195L40.0799 146.973C39.5947 147.27 39.0736 147.404 38.5435 147.395C38.0134 147.386 37.4744 147.216 36.9533 146.883C36.1357 146.38 35.6595 145.706 35.4978 144.87C35.3361 144.034 35.5876 143.091 36.2435 142.039L36.3962 141.797C36.7376 141.24 37.106 140.799 37.4923 140.476C37.8787 140.152 38.274 139.919 38.6783 139.793C39.0826 139.667 39.4959 139.64 39.9092 139.703C40.3224 139.775 40.7357 139.928 41.131 140.179C41.6881 140.521 42.0654 140.934 42.29 141.41C42.5056 141.887 42.5955 142.363 42.5596 142.848L44.8057 139.245L45.8838 139.919L40.3314 148.824L40.3135 148.815ZM40.7717 141.276C40.5111 141.114 40.2326 141.015 39.9451 140.97C39.6576 140.934 39.3611 140.952 39.0736 141.042C38.7861 141.132 38.5076 141.294 38.2291 141.527C37.9505 141.761 37.699 142.066 37.4564 142.453L37.3037 142.695C37.088 143.046 36.9353 143.387 36.8544 143.72C36.7736 144.052 36.7646 144.367 36.8095 144.663C36.8545 144.96 36.9623 145.23 37.133 145.463C37.3037 145.706 37.5103 145.904 37.7709 146.065C38.0673 146.245 38.3638 146.371 38.6603 146.425C38.9658 146.479 39.2533 146.479 39.5228 146.425C39.8013 146.362 40.0529 146.254 40.2955 146.083C40.5381 145.913 40.7357 145.706 40.8974 145.445L41.5084 144.466C41.6881 144.169 41.8049 143.873 41.8678 143.567C41.9306 143.262 41.9217 142.974 41.8498 142.695C41.7779 142.417 41.6611 142.156 41.4814 141.905C41.3017 141.653 41.0592 141.437 40.7717 141.258V141.276Z"
          fill="#0B68FE"
        />
        <path
          d="M29.3781 141.33L26.8894 139.155C26.1796 138.535 25.7933 137.933 25.7125 137.349C25.6406 136.765 25.8023 136.244 26.2066 135.785C26.3144 135.66 26.4402 135.561 26.6019 135.471C26.7547 135.381 26.9164 135.318 27.0871 135.273C27.2578 135.228 27.4285 135.219 27.6082 135.228C27.7789 135.237 27.9496 135.282 28.1203 135.354C27.8418 134.959 27.716 134.563 27.7429 134.159C27.7699 133.754 27.9226 133.395 28.2101 133.072C28.6414 132.577 29.1625 132.326 29.7465 132.317C30.3305 132.308 30.9863 132.613 31.6961 133.233L34.4004 135.597L29.3781 141.33ZM30.9504 134.069C30.5461 133.719 30.1957 133.539 29.9082 133.539C29.6207 133.539 29.3511 133.674 29.1175 133.934C28.875 134.204 28.7761 134.491 28.8121 134.788C28.848 135.084 29.0726 135.408 29.4769 135.758L31.2289 137.295L32.7023 135.606L30.9504 134.069ZM29.0097 136.729C28.5965 136.37 28.2461 136.19 27.9586 136.19C27.6711 136.19 27.4195 136.316 27.1949 136.567C26.9882 136.81 26.9074 137.062 26.9433 137.34C26.9882 137.619 27.2129 137.942 27.6261 138.302L29.1625 139.65L30.5461 138.068L29.0097 136.72V136.729Z"
          fill="#0B68FE"
        />
        <path
          d="M22.9283 135.479L22.0748 134.545L26.3244 130.672L19.4244 131.633L18.5709 130.699L24.2041 125.567L25.0576 126.502L20.808 130.375L27.708 129.414L28.5615 130.348L22.9283 135.479Z"
          fill="#0B68FE"
        />
        <path
          d="M22.5236 125.352L21.9756 124.597C21.7959 124.597 21.5623 124.642 21.2748 124.749C20.9783 124.857 20.6549 124.992 20.2865 125.172C19.9181 125.352 19.5318 125.567 19.1275 125.819C18.7232 126.07 18.3189 126.34 17.9146 126.628L16.2795 127.796L13.1798 123.473L18.4627 119.69L17.8338 118.818L20.2056 117.12L20.9064 118.1L19.451 119.151L22.7303 123.734L24.1947 122.683L24.8955 123.662L22.5236 125.361V125.352ZM19.1994 120.715L14.833 123.851L16.4771 126.142L17.4384 125.459C17.7619 125.226 18.1033 125.001 18.4357 124.794C18.7771 124.588 19.1095 124.399 19.433 124.237C19.7564 124.076 20.0709 123.932 20.3853 123.815C20.6908 123.698 20.9783 123.608 21.2299 123.554L19.1994 120.724V120.715Z"
          fill="#0B68FE"
        />
        <path
          d="M16.7193 114.254C16.9529 114.685 17.1146 115.126 17.1955 115.584C17.2764 116.042 17.2584 116.501 17.1416 116.95C17.0248 117.399 16.8002 117.831 16.4678 118.244C16.1353 118.657 15.6861 119.026 15.1111 119.349L14.8595 119.493C14.3025 119.799 13.7814 119.987 13.2783 120.041C12.7752 120.095 12.317 120.068 11.8857 119.934C11.4544 119.808 11.0681 119.592 10.7267 119.295C10.3853 118.999 10.0978 118.639 9.87319 118.235C9.32514 117.256 9.17241 116.348 9.41499 115.512C9.65757 114.676 10.2595 113.984 11.2388 113.445L11.9396 113.05L14.7517 118.1C15.147 117.885 15.4435 117.633 15.6412 117.363C15.8388 117.094 15.9646 116.806 16.0185 116.51C16.0724 116.213 16.0724 115.926 16.0095 115.638C15.9467 115.35 15.8478 115.081 15.7041 114.829C15.4256 114.326 15.1291 113.984 14.8056 113.805C14.4912 113.625 14.1498 113.562 13.7904 113.607L13.1884 112.529C13.5478 112.421 13.8892 112.385 14.2306 112.421C14.572 112.457 14.8865 112.556 15.192 112.708C15.4884 112.861 15.776 113.077 16.0275 113.346C16.2881 113.616 16.5127 113.922 16.7103 114.272L16.7193 114.254ZM10.8525 117.597C10.9962 117.858 11.1759 118.091 11.3826 118.289C11.5892 118.496 11.8228 118.648 12.0834 118.747C12.3439 118.846 12.6224 118.891 12.9189 118.882C13.2154 118.873 13.5298 118.774 13.8623 118.586L11.6341 114.578C11.2927 114.775 11.0322 114.991 10.8525 115.234C10.6728 115.467 10.556 115.719 10.5111 115.979C10.4662 116.24 10.4751 116.501 10.538 116.779C10.6009 117.058 10.7087 117.327 10.8615 117.597H10.8525Z"
          fill="#0B68FE"
        />
        <path
          d="M13.2425 107.038C13.4312 107.487 13.539 107.945 13.557 108.422C13.5839 108.889 13.5121 109.338 13.3414 109.77C13.1707 110.201 12.9011 110.605 12.5238 110.983C12.1464 111.36 11.6613 111.666 11.0593 111.917L10.7898 112.025C10.2238 112.259 9.6847 112.376 9.17258 112.367C8.66047 112.367 8.20226 112.259 7.78 112.07C7.36671 111.881 6.99836 111.612 6.68391 111.261C6.36945 110.911 6.11788 110.515 5.92921 110.066C5.74053 109.617 5.63273 109.159 5.61476 108.691C5.58781 108.224 5.65968 107.775 5.8214 107.343C5.98312 106.912 6.23468 106.517 6.59406 106.148C6.95344 105.78 7.41164 105.483 7.97765 105.249L8.24719 105.142C8.84915 104.89 9.41516 104.764 9.94525 104.764C10.4753 104.764 10.9515 104.854 11.3738 105.043C11.796 105.231 12.1644 105.492 12.4878 105.843C12.8023 106.184 13.0539 106.588 13.2425 107.038ZM12.1824 107.478C12.0746 107.217 11.9128 106.966 11.7152 106.741C11.5085 106.517 11.266 106.337 10.9874 106.22C10.6999 106.103 10.3765 106.04 10.0081 106.04C9.63978 106.04 9.21751 106.139 8.75931 106.337L8.48977 106.445C8.06751 106.624 7.7261 106.84 7.48352 107.092C7.23196 107.343 7.06125 107.613 6.94445 107.9C6.83664 108.188 6.79172 108.476 6.80969 108.781C6.82766 109.087 6.89952 109.365 7.00734 109.635C7.11515 109.904 7.26789 110.147 7.47453 110.381C7.67219 110.605 7.91477 110.785 8.19329 110.911C8.4718 111.037 8.78626 111.1 9.14563 111.1C9.49603 111.1 9.89134 111.019 10.3136 110.839L10.5831 110.731C11.0414 110.542 11.4097 110.318 11.6703 110.057C11.9398 109.797 12.1285 109.527 12.2363 109.239C12.3531 108.952 12.398 108.655 12.38 108.35C12.3621 108.044 12.2992 107.757 12.1914 107.496L12.1824 107.478Z"
          fill="#0B68FE"
        />
        <path
          d="M13.9445 100.693C13.9805 100.828 14.0074 100.972 14.0254 101.115L12.9203 101.439C12.9113 101.295 12.8844 101.151 12.8394 101.017C12.8035 100.882 12.7406 100.765 12.6687 100.675C12.5969 100.585 12.4801 100.513 12.3183 100.459C12.1656 100.405 11.95 100.361 11.6894 100.325C11.4289 100.289 11.0875 100.253 10.6652 100.226L4.25931 105.465L3.864 104.108L9.10192 100.001L2.5433 99.5787L2.16594 98.2847L10.2879 98.9047C10.8629 98.9497 11.357 99.0036 11.7703 99.0665C12.1926 99.1294 12.543 99.2282 12.8394 99.363C13.1359 99.4889 13.3695 99.6596 13.5402 99.8663C13.7109 100.073 13.8547 100.343 13.9535 100.675L13.9445 100.693Z"
          fill="#0B68FE"
        />
        <path
          d="M1.73412 96.3348L1.53646 95.1755L2.956 94.9329C2.40795 94.7621 1.96771 94.4746 1.61732 94.0612C1.26692 93.6478 1.04231 93.1446 0.943484 92.5335C0.781765 91.5899 0.961459 90.7811 1.48256 90.1072C2.00365 89.4332 2.87514 88.9928 4.08803 88.7861L4.37554 88.7412C5.01343 88.6334 5.58844 88.6064 6.09157 88.6783C6.5947 88.7502 7.02594 88.894 7.39431 89.1097C7.76267 89.3253 8.05016 89.6129 8.27478 89.9724C8.49939 90.3318 8.65213 90.7362 8.73299 91.2035C8.8408 91.8505 8.79587 92.4077 8.58024 92.884C8.3736 93.3602 8.06813 93.7467 7.6818 94.0342L11.8596 93.3153L12.0752 94.5644L1.73412 96.3438V96.3348ZM7.67282 91.6708C7.61891 91.3653 7.5111 91.0957 7.34938 90.853C7.18766 90.6104 6.97204 90.4127 6.71149 90.2509C6.45095 90.0982 6.14548 89.9903 5.7861 89.9454C5.42672 89.8915 5.02241 89.9095 4.58218 89.9813L4.29468 90.0263C3.89038 90.0982 3.531 90.215 3.23452 90.3767C2.92904 90.5385 2.68647 90.7362 2.4978 90.9699C2.30913 91.2035 2.18334 91.4551 2.11146 91.7427C2.03959 92.0303 2.03959 92.3178 2.08451 92.6234C2.13842 92.9648 2.24623 93.2614 2.40795 93.531C2.56967 93.7916 2.75834 94.0073 2.98295 94.178C3.20756 94.3398 3.46812 94.4566 3.74663 94.5285C4.03413 94.6004 4.32163 94.6004 4.6271 94.5554L5.75913 94.3577C6.10054 94.2948 6.39704 94.187 6.66657 94.0253C6.92712 93.8635 7.14274 93.6658 7.30446 93.4321C7.46618 93.1985 7.58298 92.9289 7.64587 92.6323C7.70876 92.3358 7.71774 92.0123 7.65485 91.6708H7.67282Z"
          fill="#0B68FE"
        />
        <path
          d="M7.89629 83.43C7.92325 83.9152 7.86935 84.3825 7.73458 84.8318C7.59981 85.2812 7.38418 85.6766 7.07871 86.036C6.77324 86.3865 6.38692 86.6741 5.91074 86.9077C5.43457 87.1414 4.86855 87.2672 4.22167 87.3031L3.93416 87.3211C3.32322 87.357 2.77518 87.2851 2.299 87.1054C1.82283 86.9257 1.41852 86.6831 1.0861 86.3595C0.753678 86.045 0.502122 85.6676 0.322434 85.2362C0.142745 84.8049 0.0349269 84.3466 0.00797367 83.8523C-0.0189795 83.3671 0.034934 82.8998 0.1697 82.4504C0.304467 82.0011 0.520085 81.5967 0.807586 81.2463C1.09509 80.8958 1.47243 80.6082 1.93064 80.3836C2.38884 80.1589 2.92792 80.0241 3.53886 79.9971L3.82635 79.9792C4.47323 79.9432 5.04823 80.0151 5.55136 80.1859C6.05449 80.3656 6.46778 80.6082 6.80919 80.9227C7.15059 81.2373 7.41114 81.6147 7.59083 82.046C7.77052 82.4774 7.87832 82.9357 7.90527 83.43H7.89629ZM6.74629 83.4929C6.72832 83.2053 6.66543 82.9177 6.54863 82.6392C6.43183 82.3606 6.26114 82.109 6.03653 81.9023C5.81192 81.6956 5.52441 81.5248 5.16504 81.408C4.80566 81.2912 4.39238 81.2373 3.88925 81.2642L3.60174 81.2822C3.14354 81.3092 2.7572 81.399 2.43376 81.5518C2.11931 81.7046 1.85876 81.9023 1.6611 82.1359C1.46345 82.3696 1.31969 82.6302 1.23883 82.9267C1.15798 83.2143 1.12204 83.5108 1.14001 83.7984C1.15797 84.086 1.22087 84.3735 1.32868 84.6521C1.4365 84.9307 1.6072 85.1823 1.83181 85.389C2.05642 85.5957 2.33494 85.7664 2.66737 85.8833C2.99979 86.0091 3.3951 86.054 3.8533 86.027L4.14081 86.0091C4.63496 85.9821 5.05722 85.8922 5.38965 85.7305C5.72207 85.5687 6.00058 85.38 6.19824 85.1464C6.3959 84.9127 6.54863 84.6521 6.62949 84.3556C6.71035 84.059 6.74629 83.7714 6.73731 83.4839L6.74629 83.4929Z"
          fill="#0B68FE"
        />
        <path
          d="M-0.00120354 77.7868L0.0706735 76.5197L3.26013 76.7084L3.32303 75.702L0.304264 72.6735L0.394114 71.1818L3.84413 74.6955L8.00391 71.4873L7.90508 73.0959L4.41014 75.7738L4.34725 76.7803L7.68047 76.978L7.6086 78.2451L0.00777626 77.7958L-0.00120354 77.7868Z"
          fill="#0B68FE"
        />
        <path
          d="M8.66973 68.2786C8.59785 68.692 8.48106 69.0515 8.31035 69.348C8.13965 69.6446 7.933 69.8872 7.68143 70.0669C7.43886 70.2467 7.16035 70.3725 6.86386 70.4354C6.56738 70.4983 6.24393 70.5073 5.90252 70.4444C5.15682 70.3186 4.65369 70.004 4.40213 69.5098C4.14158 69.0155 4.08767 68.3595 4.22244 67.5328L4.63572 65.0974L4.12361 65.0076C3.48572 64.8997 3.00955 64.9716 2.72204 65.2143C2.42556 65.4659 2.22789 65.8703 2.13805 66.4544C2.0482 66.9936 2.08415 67.398 2.24587 67.6855C2.40759 67.9641 2.61423 68.1438 2.8658 68.2157L2.65915 69.4649C2.36266 69.393 2.09313 69.2672 1.85953 69.0784C1.62594 68.8897 1.43727 68.6561 1.28453 68.3685C1.14078 68.0899 1.04195 67.7574 0.988044 67.398C0.943122 67.0295 0.952113 66.6341 1.02399 66.2028C1.17672 65.3131 1.50015 64.6391 2.01227 64.1898C2.52438 63.7405 3.25212 63.5877 4.20447 63.7495L7.25019 64.2617C7.6455 64.3246 7.9959 64.3875 8.29238 64.4324C8.58887 64.4774 8.88535 64.5043 9.17285 64.5133L8.98419 65.6007C8.75958 65.6007 8.55292 65.5737 8.35527 65.5558C8.15761 65.5288 7.94199 65.5018 7.69043 65.4569C8.05879 65.7265 8.33731 66.1039 8.53497 66.5892C8.73262 67.0834 8.77753 67.6406 8.67871 68.2786H8.66973ZM7.58262 67.8203C7.62754 67.5238 7.63652 67.2452 7.60058 66.9666C7.56464 66.697 7.48379 66.4454 7.35801 66.2207C7.23223 65.9961 7.0705 65.8074 6.86386 65.6546C6.65722 65.5018 6.40565 65.403 6.10018 65.3491L5.58808 65.2592L5.21072 67.4878C5.12986 67.9551 5.1658 68.3326 5.30057 68.6291C5.44432 68.9257 5.70486 69.1054 6.10916 69.1773C6.45955 69.2402 6.76504 69.1593 7.03457 68.9436C7.3041 68.728 7.48378 68.3595 7.57363 67.8203H7.58262Z"
          fill="#0B68FE"
        />
        <path
          d="M20.9608 38.1206C20.6822 38.516 20.3498 38.8485 19.9814 39.1001C19.6041 39.3518 19.1998 39.5135 18.7686 39.5764C18.3283 39.6393 17.8611 39.6034 17.358 39.4686C16.8549 39.3338 16.3428 39.0732 15.8037 38.6957L15.5701 38.534C15.067 38.1835 14.6806 37.7881 14.4111 37.3658C14.1416 36.9434 13.9619 36.5031 13.89 36.0717C13.8181 35.6314 13.8361 35.2 13.9439 34.7687C14.0517 34.3373 14.2404 33.9329 14.501 33.5735C14.7615 33.214 15.049 32.9085 15.3545 32.6838C15.6599 32.4591 15.9834 32.3064 16.3158 32.2165C16.6482 32.1267 16.9807 32.0997 17.3221 32.1356C17.6635 32.1716 17.9959 32.2525 18.3193 32.3962L17.5736 33.4566C17.385 33.4117 17.1873 33.3848 17.0076 33.3848C16.8189 33.3848 16.6392 33.4027 16.4596 33.4566C16.2799 33.5106 16.1002 33.6004 15.9385 33.7262C15.7678 33.852 15.606 34.0228 15.4533 34.2475C15.2916 34.4721 15.1838 34.7237 15.1119 34.9933C15.049 35.2629 15.04 35.5415 15.0939 35.8201C15.1478 36.1076 15.2736 36.3862 15.4713 36.6738C15.6689 36.9614 15.9564 37.2309 16.3338 37.5005L16.5674 37.6623C16.9627 37.9409 17.34 38.1386 17.6994 38.2374C18.0588 38.3453 18.3822 38.3722 18.6697 38.3363C18.9572 38.3003 19.2268 38.2015 19.4514 38.0487C19.685 37.8959 19.8736 37.7072 20.0354 37.4826C20.3049 37.0962 20.4397 36.7097 20.4397 36.3143C20.4397 35.9189 20.3139 35.5505 20.0623 35.209L20.808 34.1486C21.3201 34.7327 21.5986 35.3797 21.6256 36.0987C21.6526 36.8086 21.4459 37.4916 20.9877 38.1476L20.9608 38.1206Z"
          fill="#0B68FE"
        />
        <path
          d="M17.8874 29.1877L18.7949 28.1902L24.1316 29.3584L22.4425 24.1553L23.2961 23.2207L28.9473 28.334L28.0937 29.2686L23.9879 25.5572L25.3535 29.8347L24.6797 30.5806L20.2863 29.646L24.3922 33.3574L23.5386 34.292L17.8874 29.1787V29.1877Z"
          fill="#0B68FE"
        />
        <path
          d="M32.8927 25.0447C32.5243 25.3592 32.12 25.6018 31.6798 25.7726C31.2396 25.9433 30.7903 26.0062 30.3321 25.9883C29.865 25.9613 29.3978 25.8265 28.9306 25.5928C28.4634 25.3502 28.0142 24.9818 27.5919 24.4875L27.4032 24.2718C27.0079 23.8045 26.7294 23.3283 26.5767 22.843C26.4239 22.3577 26.37 21.8815 26.4239 21.4321C26.4778 20.9738 26.6216 20.5425 26.8462 20.1381C27.0798 19.7337 27.3763 19.3652 27.7536 19.0507C28.131 18.7362 28.5263 18.4936 28.9665 18.3228C29.4067 18.1521 29.856 18.0802 30.3052 18.0981C30.7634 18.1161 31.2216 18.2419 31.6798 18.4666C32.138 18.6913 32.5693 19.0417 32.9646 19.5L33.1533 19.7157C33.5755 20.21 33.872 20.7132 34.0337 21.2164C34.1954 21.7197 34.2583 22.205 34.2134 22.6633C34.1685 23.1216 34.0247 23.5619 33.8001 23.9663C33.5665 24.3707 33.2701 24.7391 32.8927 25.0537V25.0447ZM32.147 24.173C32.3626 23.9843 32.5513 23.7596 32.704 23.499C32.8568 23.2384 32.9466 22.9508 32.9736 22.6453C33.0005 22.3398 32.9556 22.0073 32.8388 21.6568C32.722 21.3063 32.4974 20.9379 32.174 20.5604L31.9853 20.3448C31.6888 19.9943 31.3743 19.7427 31.0599 19.5809C30.7454 19.4192 30.431 19.3383 30.1255 19.3203C29.82 19.3023 29.5325 19.3563 29.245 19.4641C28.9665 19.5809 28.715 19.7247 28.4993 19.9134C28.2837 20.1021 28.095 20.3268 27.9423 20.5874C27.7895 20.848 27.6907 21.1266 27.6638 21.4321C27.6368 21.7377 27.6638 22.0612 27.7806 22.3937C27.8884 22.7352 28.086 23.0766 28.3915 23.4271L28.5802 23.6428C28.9036 24.0202 29.2271 24.2988 29.5595 24.4695C29.8919 24.6403 30.2064 24.7391 30.5118 24.7571C30.8173 24.7751 31.1138 24.7302 31.4013 24.6223C31.6888 24.5145 31.9314 24.3617 32.156 24.173H32.147Z"
          fill="#0B68FE"
        />
        <path
          d="M34.482 15.4026L32.3437 16.8853L31.7058 15.9597L37.0246 12.2753L37.6625 13.2009L35.5242 14.6837L39.2168 20.0306L38.1746 20.7495L34.482 15.4026Z"
          fill="#0B68FE"
        />
        <path
          d="M38.7047 11.2416L39.7379 10.6755L40.4208 11.9426C40.4028 11.3764 40.5376 10.8552 40.8161 10.3969C41.0946 9.93859 41.4989 9.56115 42.038 9.2646C42.8825 8.80629 43.7001 8.71642 44.5087 8.995C45.3173 9.27358 46.0091 9.95656 46.602 11.0439L46.7368 11.2955C47.0513 11.8707 47.2489 12.4009 47.3478 12.9041C47.4466 13.3984 47.4466 13.8567 47.3478 14.2701C47.2489 14.6834 47.0782 15.0609 46.8177 15.3844C46.5571 15.7079 46.2157 15.9865 45.8024 16.2111C45.2274 16.5257 44.6794 16.6605 44.1673 16.6155C43.6462 16.5706 43.188 16.4088 42.7837 16.1392L44.8141 19.8686L43.7001 20.4707L38.6868 11.2506L38.7047 11.2416ZM45.0388 15.3574C45.3083 15.2136 45.5329 15.0159 45.7126 14.7913C45.8923 14.5576 46.0091 14.297 46.072 14.0005C46.1349 13.7039 46.1349 13.3804 46.072 13.0209C46.0001 12.6615 45.8653 12.293 45.6497 11.8976L45.5149 11.646C45.3173 11.2866 45.0927 10.99 44.8411 10.7564C44.5895 10.5227 44.32 10.352 44.0415 10.2531C43.763 10.1453 43.4755 10.1093 43.188 10.1363C42.9005 10.1632 42.622 10.2531 42.3524 10.3969C42.0469 10.5587 41.7954 10.7653 41.5977 10.999C41.4001 11.2326 41.2563 11.4843 41.1755 11.7538C41.0946 12.0234 41.0676 12.302 41.0946 12.5896C41.1215 12.8771 41.2114 13.1647 41.3551 13.4343L41.9032 14.4408C42.0649 14.7463 42.2716 14.998 42.5052 15.1867C42.7387 15.3754 42.9993 15.5282 43.2688 15.6C43.5384 15.6809 43.8348 15.7079 44.1403 15.663C44.4458 15.618 44.7513 15.5282 45.0567 15.3574H45.0388Z"
          fill="#0B68FE"
        />
        <path
          d="M52.9003 12.9218C52.4511 13.1015 51.9839 13.2093 51.5167 13.2273C51.0495 13.2453 50.6003 13.1734 50.169 13.0026C49.7378 12.8319 49.3335 12.5533 48.9651 12.1759C48.5968 11.7984 48.2913 11.3042 48.0397 10.6841L47.9319 10.4145C47.6983 9.83041 47.5815 9.28224 47.5815 8.779C47.5815 8.27576 47.6804 7.81745 47.8601 7.41306C48.0397 6.99968 48.3003 6.64921 48.6417 6.34367C48.9831 6.03813 49.3694 5.80448 49.8007 5.62475C50.8429 5.21137 51.7593 5.16645 52.5589 5.50794C53.3585 5.84942 53.9695 6.54138 54.3827 7.57482L54.6792 8.32069L49.3155 10.4774C49.4862 10.8908 49.6929 11.2233 49.9444 11.448C50.196 11.6816 50.4566 11.8434 50.7441 11.9332C51.0316 12.0231 51.3191 12.0591 51.6155 12.0321C51.912 12.0051 52.1905 11.9422 52.4601 11.8344C52.9902 11.6187 53.3675 11.3671 53.5831 11.0706C53.7988 10.774 53.9066 10.4505 53.9066 10.091L55.0566 9.63271C55.1195 10.0012 55.1105 10.3516 55.0386 10.6751C54.9577 11.0076 54.823 11.3132 54.6343 11.5918C54.4456 11.8703 54.1941 12.122 53.8976 12.3466C53.6011 12.5713 53.2597 12.76 52.8913 12.9038L52.9003 12.9218ZM50.3128 6.67618C50.0343 6.78401 49.7827 6.93678 49.5581 7.10752C49.3335 7.28725 49.1448 7.50292 49.019 7.74555C48.8843 7.98819 48.8034 8.26677 48.7854 8.56332C48.7585 8.85988 48.8214 9.18339 48.9562 9.53386L53.2148 7.81745C53.062 7.45799 52.8734 7.17042 52.6577 6.96374C52.4421 6.75705 52.2085 6.61327 51.9659 6.53239C51.7144 6.45151 51.4538 6.42455 51.1663 6.4605C50.8878 6.48746 50.6003 6.56834 50.3128 6.67618Z"
          fill="#0B68FE"
        />
        <path
          d="M57.2025 4.39407L54.7048 5.11299L54.3904 4.03462L60.6076 2.24631L60.9131 3.32468L58.4154 4.0436L60.2123 10.2892L58.9994 10.6396L57.2025 4.39407Z"
          fill="#0B68FE"
        />
        <path
          d="M62.5224 1.83324L63.7622 1.60858L64.2833 4.4393L66.0802 4.10681C67.0236 3.93606 67.7603 4.01693 68.2814 4.34943C68.8025 4.68193 69.1349 5.21213 69.2697 5.94902C69.4044 6.66793 69.2787 7.27004 68.9103 7.77328C68.5419 8.26754 67.8771 8.60902 66.9427 8.77976L63.906 9.33691L62.5314 1.84222L62.5224 1.83324ZM66.6642 7.68341C67.2482 7.57557 67.6435 7.39584 67.8322 7.14422C68.0298 6.8926 68.0927 6.56909 68.0208 6.15572C67.949 5.75133 67.7693 5.46376 67.4997 5.30201C67.2302 5.14025 66.799 5.11328 66.215 5.22112L64.49 5.53565L64.9392 7.99794L66.6642 7.68341Z"
          fill="#0B68FE"
        />
        <path
          d="M74.3193 0.152726L80.6443 -0.00902367L80.833 7.61148L79.5662 7.64742L79.4045 1.15022L75.6131 1.24907L75.7748 7.74627L74.508 7.78223L74.3193 0.161721V0.152726Z"
          fill="#0B68FE"
        />
        <path
          d="M83.5454 0.080658L84.7223 0.188505L84.5966 1.61735C84.902 1.13208 85.2973 0.781605 85.7825 0.547957C86.2677 0.31431 86.8157 0.233438 87.4266 0.287356C88.379 0.377221 89.1157 0.754651 89.6368 1.43762C90.1489 2.1116 90.3556 3.07316 90.2388 4.3043L90.2118 4.59186C90.1489 5.23888 90.0231 5.79604 89.8255 6.27232C89.6278 6.73962 89.3763 7.12604 89.0708 7.41361C88.7653 7.71016 88.4149 7.91684 88.0106 8.04265C87.6063 8.16846 87.1751 8.2134 86.7079 8.16846C86.061 8.10556 85.5309 7.91684 85.1177 7.59333C84.7044 7.26982 84.4169 6.88341 84.2372 6.43409L83.8508 10.6577L82.593 10.5409L83.5454 0.089653V0.080658ZM86.5282 7.0182C86.8337 7.04516 87.1302 7.0182 87.3997 6.91935C87.6782 6.8205 87.9208 6.66773 88.1454 6.46104C88.361 6.25436 88.5407 5.97577 88.6845 5.64327C88.8282 5.31077 88.9181 4.91537 88.954 4.47503L88.9809 4.18747C89.0169 3.7741 88.9989 3.40565 88.9181 3.07315C88.8372 2.74065 88.7114 2.45309 88.5317 2.21046C88.352 1.96782 88.1364 1.77911 87.8848 1.63532C87.6243 1.49154 87.3458 1.41066 87.0403 1.3837C86.6989 1.34776 86.3755 1.3837 86.079 1.46458C85.7825 1.54546 85.5219 1.68026 85.3063 1.85999C85.0907 2.03971 84.911 2.25539 84.7673 2.51599C84.6235 2.7766 84.5426 3.05518 84.5157 3.36072L84.4079 4.51099C84.3719 4.85247 84.4079 5.17598 84.4977 5.47253C84.5876 5.76908 84.7223 6.02969 84.902 6.24536C85.0817 6.46104 85.3153 6.64077 85.5938 6.78456C85.8723 6.92834 86.1778 7.00921 86.5192 7.04516L86.5282 7.0182Z"
          fill="#0B68FE"
        />
        <path
          d="M92.9257 1.1053L94.1656 1.36591L92.9707 6.99142L98.0199 2.18368L99.2598 2.44428L97.6785 9.89404L96.4387 9.63344L97.6336 4.00793L92.5843 8.81567L91.3445 8.55507L92.9257 1.09631V1.1053Z"
          fill="#0B68FE"
        />
        <path
          d="M102.144 3.20778L103.42 3.6571L104.408 9.031L108.55 5.45439L109.745 5.87676L107.212 13.0659L106.017 12.6436L107.858 7.42241L104.444 10.334L103.492 10.0015L102.656 5.58918L100.815 10.8103L99.6197 10.3879L102.153 3.19878L102.144 3.20778Z"
          fill="#0B68FE"
        />
        <path
          d="M111.757 15.4478C111.317 15.2321 110.94 14.9535 110.616 14.621C110.293 14.2885 110.05 13.9021 109.897 13.4618C109.745 13.0214 109.682 12.5451 109.718 12.0149C109.754 11.4847 109.924 10.9276 110.221 10.3345L110.347 10.0739C110.634 9.50773 110.958 9.0584 111.344 8.73489C111.721 8.40239 112.126 8.16875 112.557 8.03395C112.979 7.89915 113.419 7.85422 113.869 7.91712C114.318 7.97104 114.749 8.10584 115.171 8.31253C116.178 8.81577 116.815 9.48077 117.085 10.2985C117.354 11.1163 117.247 12.0329 116.744 13.0394L116.384 13.7583L111.218 11.1702C111.02 11.5746 110.913 11.9431 110.904 12.2845C110.895 12.626 110.949 12.9316 111.065 13.2012C111.182 13.4708 111.353 13.7134 111.568 13.9201C111.784 14.1268 112.018 14.2885 112.278 14.4233C112.79 14.6839 113.231 14.7918 113.599 14.7648C113.967 14.7289 114.282 14.5941 114.551 14.3604L115.656 14.9176C115.423 15.2051 115.162 15.4388 114.857 15.5916C114.56 15.7533 114.237 15.8522 113.904 15.8881C113.572 15.9241 113.222 15.9151 112.853 15.8342C112.485 15.7533 112.126 15.6275 111.766 15.4478H111.757ZM114.722 9.37294C114.461 9.23814 114.183 9.14826 113.895 9.09434C113.608 9.04043 113.329 9.04942 113.06 9.11232C112.79 9.17523 112.53 9.29206 112.287 9.47179C112.045 9.65152 111.847 9.91212 111.676 10.2446L115.773 12.3025C115.944 11.9431 116.034 11.6195 116.043 11.323C116.061 11.0264 116.016 10.7568 115.908 10.5142C115.8 10.2716 115.647 10.0559 115.432 9.86718C115.225 9.67846 114.983 9.50773 114.704 9.37294H114.722Z"
          fill="#0B68FE"
        />
        <path
          d="M120.319 11.2242L121.307 11.8712L120.517 13.0754C121.011 12.7968 121.532 12.662 122.071 12.689C122.61 12.7159 123.131 12.8956 123.652 13.2371C124.452 13.7583 124.919 14.4413 125.063 15.286C125.198 16.1308 124.928 17.0654 124.254 18.0988L124.093 18.3414C123.733 18.8896 123.365 19.321 122.97 19.6445C122.574 19.968 122.179 20.1837 121.766 20.3005C121.352 20.4173 120.948 20.4353 120.535 20.3634C120.122 20.2915 119.717 20.1208 119.322 19.8602C118.774 19.5007 118.397 19.0873 118.19 18.602C117.983 18.1258 117.902 17.6405 117.956 17.1642L115.629 20.7139L114.569 20.0219L120.319 11.2421V11.2242ZM119.681 18.7548C119.942 18.9256 120.211 19.0334 120.499 19.0783C120.786 19.1233 121.083 19.1053 121.37 19.0244C121.658 18.9435 121.945 18.7818 122.233 18.5571C122.511 18.3325 122.781 18.0269 123.024 17.6495L123.176 17.4068C123.401 17.0654 123.563 16.7239 123.643 16.3914C123.733 16.0589 123.751 15.7444 123.715 15.4478C123.679 15.1513 123.572 14.8817 123.41 14.639C123.248 14.3964 123.041 14.1897 122.781 14.019C122.493 13.8302 122.197 13.7044 121.9 13.6415C121.604 13.5786 121.307 13.5696 121.038 13.6236C120.759 13.6775 120.499 13.7853 120.256 13.9471C120.014 14.1088 119.807 14.3155 119.636 14.5761L119.007 15.5377C118.819 15.8252 118.693 16.1218 118.63 16.4273C118.567 16.7329 118.567 17.0204 118.63 17.299C118.684 17.5776 118.81 17.8472 118.981 18.0988C119.16 18.3504 119.394 18.5751 119.681 18.7638V18.7548Z"
          fill="#0B68FE"
        />
        <path
          d="M131.101 18.9793L133.535 21.2169C134.227 21.855 134.605 22.4571 134.667 23.0502C134.73 23.6343 134.551 24.1555 134.137 24.6048C134.03 24.7217 133.895 24.8295 133.742 24.9104C133.589 24.9912 133.419 25.0542 133.248 25.0991C133.077 25.135 132.906 25.144 132.727 25.135C132.556 25.1171 132.385 25.0721 132.215 25.0002C132.484 25.4046 132.601 25.8 132.565 26.2044C132.529 26.6088 132.367 26.9683 132.071 27.2828C131.622 27.7681 131.11 28.0107 130.517 28.0017C129.924 28.0017 129.286 27.6692 128.594 27.0402L125.944 24.6138L131.092 18.9973L131.101 18.9793ZM131.361 23.5894C131.765 23.9578 132.107 24.1465 132.394 24.1465C132.682 24.1465 132.942 24.0297 133.167 23.7871C133.383 23.5534 133.473 23.3018 133.437 23.0142C133.401 22.7356 133.185 22.4121 132.781 22.0437L131.28 20.6598L129.861 22.2055L131.37 23.5894H131.361ZM129.358 26.1954C129.753 26.5549 130.094 26.7436 130.391 26.7526C130.678 26.7526 130.948 26.6358 131.19 26.3752C131.433 26.1056 131.541 25.827 131.514 25.5304C131.487 25.2339 131.271 24.9104 130.876 24.5419L129.16 22.9693L127.642 24.6228L129.358 26.1954Z"
          fill="#0B68FE"
        />
        <path
          d="M137.398 24.946L138.225 25.8985L133.885 29.6728L140.812 28.873L141.639 29.8256L135.889 34.8311L135.062 33.8785L139.402 30.1042L132.475 30.904L131.648 29.9514L137.398 24.946Z"
          fill="#0B68FE"
        />
        <path
          d="M137.569 35.0913L138.099 35.8641C138.279 35.8641 138.513 35.8281 138.809 35.7293C139.106 35.6304 139.438 35.4956 139.806 35.3249C140.175 35.1542 140.57 34.9475 140.974 34.7048C141.379 34.4622 141.792 34.2016 142.205 33.923L143.867 32.7907L146.868 37.1851L141.504 40.8516L142.115 41.7412L139.708 43.3857L139.025 42.3882L140.507 41.3728L137.327 36.7178L135.844 37.7333L135.161 36.7358L137.569 35.0913ZM140.786 39.8091L145.224 36.7807L143.634 34.4532L142.663 35.1182C142.331 35.3429 141.99 35.5586 141.648 35.7563C141.307 35.954 140.965 36.1337 140.642 36.2865C140.31 36.4392 139.995 36.574 139.681 36.6908C139.366 36.8077 139.088 36.8885 138.827 36.9335L140.786 39.8091Z"
          fill="#0B68FE"
        />
        <path
          d="M143.122 46.3248C142.897 45.8934 142.745 45.4441 142.673 44.9858C142.601 44.5275 142.637 44.0692 142.763 43.6199C142.888 43.1706 143.122 42.7482 143.463 42.3438C143.805 41.9394 144.263 41.58 144.847 41.2744L145.099 41.1396C145.656 40.8431 146.186 40.6723 146.689 40.6274C147.192 40.5825 147.65 40.6274 148.072 40.7622C148.495 40.897 148.881 41.1216 149.213 41.4272C149.546 41.7327 149.824 42.0922 150.04 42.5056C150.561 43.503 150.696 44.4107 150.435 45.2374C150.175 46.0642 149.555 46.7382 148.558 47.2594L147.848 47.6368L145.161 42.5235C144.766 42.7302 144.461 42.9728 144.254 43.2424C144.047 43.512 143.913 43.7906 143.85 44.0872C143.787 44.3837 143.787 44.6713 143.841 44.9588C143.895 45.2464 143.994 45.525 144.128 45.7766C144.398 46.2888 144.685 46.6303 145 46.819C145.314 47.0078 145.656 47.0796 146.006 47.0437L146.581 48.1401C146.222 48.2389 145.871 48.2659 145.539 48.2299C145.197 48.185 144.883 48.0771 144.586 47.9154C144.29 47.7536 144.011 47.538 143.76 47.2594C143.508 46.9808 143.284 46.6753 143.104 46.3158L143.122 46.3248ZM149.07 43.1166C148.935 42.856 148.764 42.6224 148.558 42.4067C148.36 42.2 148.126 42.0383 147.866 41.9304C147.605 41.8226 147.327 41.7687 147.03 41.7777C146.734 41.7866 146.41 41.8765 146.087 42.0472L148.225 46.1091C148.567 45.9204 148.836 45.7047 149.016 45.4711C149.195 45.2374 149.321 44.9948 149.375 44.7342C149.429 44.4736 149.429 44.213 149.375 43.9344C149.312 43.6558 149.213 43.3772 149.07 43.1076V43.1166Z"
          fill="#0B68FE"
        />
        <path
          d="M146.437 53.6402C146.257 53.1819 146.168 52.7236 146.159 52.2563C146.15 51.789 146.23 51.3397 146.41 50.9083C146.59 50.477 146.868 50.0815 147.255 49.7221C147.641 49.3626 148.135 49.0571 148.737 48.8234L149.007 48.7156C149.582 48.4909 150.121 48.3921 150.633 48.4101C151.145 48.428 151.603 48.5359 152.016 48.7336C152.43 48.9313 152.789 49.2099 153.095 49.5603C153.4 49.9108 153.643 50.3152 153.822 50.7735C154.002 51.2318 154.092 51.6901 154.101 52.1574C154.11 52.6247 154.038 53.074 153.867 53.4964C153.697 53.9188 153.427 54.3142 153.068 54.6736C152.699 55.0331 152.232 55.3207 151.666 55.5453L151.397 55.6531C150.786 55.8868 150.22 56.0036 149.689 55.9946C149.159 55.9856 148.683 55.8778 148.27 55.6801C147.848 55.4824 147.488 55.2038 147.183 54.8534C146.877 54.5029 146.635 54.0985 146.455 53.6402H146.437ZM147.506 53.2268C147.614 53.4964 147.758 53.748 147.955 53.9727C148.153 54.2063 148.396 54.3861 148.674 54.5119C148.953 54.6377 149.285 54.7096 149.654 54.7096C150.022 54.7096 150.444 54.6287 150.911 54.449L151.181 54.3411C151.612 54.1794 151.954 53.9727 152.205 53.7211C152.457 53.4694 152.645 53.2088 152.762 52.9303C152.879 52.6517 152.933 52.3551 152.915 52.0496C152.897 51.744 152.843 51.4655 152.735 51.1959C152.627 50.9263 152.484 50.6747 152.286 50.441C152.088 50.2074 151.855 50.0276 151.585 49.8928C151.307 49.767 150.992 49.6951 150.642 49.6861C150.291 49.6772 149.896 49.7491 149.465 49.9198L149.195 50.0276C148.728 50.2074 148.36 50.423 148.09 50.6747C147.821 50.9263 147.623 51.1959 147.497 51.4834C147.371 51.771 147.318 52.0586 147.327 52.3641C147.336 52.6696 147.398 52.9572 147.497 53.2268H147.506Z"
          fill="#0B68FE"
        />
        <path
          d="M145.584 59.9482C145.548 59.8134 145.521 59.6696 145.512 59.5169L146.626 59.2203C146.626 59.3641 146.662 59.5079 146.698 59.6517C146.734 59.7865 146.788 59.9033 146.86 59.9931C146.932 60.083 147.048 60.1549 147.201 60.2178C147.354 60.2807 147.56 60.3256 147.83 60.3706C148.091 60.4155 148.432 60.4515 148.854 60.4874L155.386 55.4011L155.745 56.758L150.409 60.739L156.958 61.3142L157.309 62.6172L149.205 61.7994C148.639 61.7455 148.144 61.6736 147.722 61.6017C147.309 61.5208 146.95 61.422 146.662 61.2782C146.375 61.1434 146.141 60.9637 145.97 60.757C145.8 60.5503 145.674 60.2717 145.584 59.9392V59.9482Z"
          fill="#0B68FE"
        />
        <path
          d="M157.685 64.5948L157.856 65.763L156.436 65.9697C156.975 66.1494 157.416 66.4549 157.757 66.8683C158.099 67.2907 158.305 67.8029 158.395 68.405C158.539 69.3486 158.332 70.1574 157.802 70.8224C157.263 71.4874 156.382 71.9007 155.161 72.0895L154.873 72.1344C154.226 72.2332 153.651 72.2332 153.157 72.1524C152.654 72.0715 152.223 71.9187 151.863 71.6851C151.504 71.4604 151.216 71.1638 151.001 70.8044C150.785 70.4449 150.641 70.0316 150.57 69.5643C150.471 68.9172 150.534 68.3601 150.758 67.8838C150.974 67.4075 151.288 67.0301 151.684 66.7515L147.488 67.3716L147.299 66.1224L157.676 64.5858L157.685 64.5948ZM151.639 69.1239C151.684 69.4295 151.782 69.6991 151.944 69.9507C152.106 70.2023 152.313 70.4 152.564 70.5618C152.825 70.7235 153.13 70.8313 153.481 70.8943C153.831 70.9572 154.235 70.9482 154.684 70.8853L154.972 70.8403C155.376 70.7774 155.736 70.6696 156.041 70.5168C156.347 70.3641 156.598 70.1664 156.796 69.9417C156.984 69.717 157.128 69.4564 157.2 69.1778C157.272 68.8993 157.29 68.6027 157.245 68.2972C157.191 67.9557 157.092 67.6501 156.94 67.3806C156.787 67.111 156.598 66.8953 156.382 66.7245C156.158 66.5538 155.906 66.428 155.619 66.3561C155.331 66.2842 155.044 66.2662 154.738 66.3112L153.597 66.4819C153.256 66.5358 152.95 66.6347 152.681 66.7874C152.411 66.9402 152.196 67.1379 152.025 67.3626C151.854 67.5872 151.738 67.8568 151.666 68.1534C151.594 68.4499 151.585 68.7735 151.63 69.1149L151.639 69.1239Z"
          fill="#0B68FE"
        />
        <path
          d="M151.225 77.3646C151.207 76.8794 151.279 76.4121 151.423 75.9627C151.566 75.5134 151.791 75.118 152.105 74.7765C152.42 74.435 152.815 74.1475 153.291 73.9318C153.768 73.7161 154.343 73.5993 154.989 73.5813H155.277C155.888 73.5544 156.436 73.6353 156.912 73.824C157.388 74.0127 157.784 74.2643 158.107 74.5968C158.43 74.9203 158.673 75.3067 158.844 75.7381C159.014 76.1694 159.104 76.6367 159.122 77.122C159.14 77.6073 159.068 78.0746 158.925 78.5239C158.781 78.9732 158.556 79.3596 158.251 79.7101C157.954 80.0516 157.568 80.3392 157.101 80.5458C156.634 80.7525 156.095 80.8783 155.484 80.8963H155.196C154.549 80.9233 153.974 80.8424 153.48 80.6537C152.986 80.465 152.573 80.2133 152.24 79.8898C151.908 79.5663 151.656 79.1799 151.486 78.7486C151.315 78.3082 151.225 77.8499 151.207 77.3646H151.225ZM152.375 77.3287C152.375 77.6163 152.447 77.9038 152.555 78.1914C152.662 78.479 152.833 78.7216 153.049 78.9373C153.273 79.1529 153.552 79.3237 153.902 79.4495C154.253 79.5753 154.675 79.6382 155.169 79.6202H155.457C155.915 79.6023 156.31 79.5124 156.625 79.3686C156.948 79.2158 157.209 79.0361 157.415 78.8025C157.622 78.5778 157.766 78.3172 157.855 78.0296C157.945 77.7421 157.981 77.4545 157.972 77.1669C157.963 76.8794 157.909 76.5918 157.802 76.3042C157.694 76.0167 157.541 75.774 157.316 75.5584C157.092 75.3427 156.822 75.1719 156.499 75.0461C156.166 74.9203 155.771 74.8574 155.313 74.8754H155.025C154.531 74.9023 154.109 74.9832 153.768 75.127C153.426 75.2798 153.148 75.4685 152.941 75.6932C152.734 75.9178 152.582 76.1784 152.492 76.466C152.402 76.7536 152.357 77.0411 152.366 77.3287H152.375Z"
          fill="#0B68FE"
        />
        <path
          d="M158.998 83.1696L158.89 84.4277L155.71 84.1671L155.629 85.1735L158.576 88.2739L158.459 89.7656L155.09 86.171L150.858 89.2804L150.993 87.6718L154.551 85.0747L154.631 84.0682L151.307 83.7986L151.415 82.5405L159.007 83.1606L158.998 83.1696Z"
          fill="#0B68FE"
        />
        <path
          d="M150.103 92.4706C150.183 92.0572 150.309 91.7068 150.489 91.4102C150.669 91.1136 150.884 90.88 151.127 90.7093C151.378 90.5295 151.648 90.4127 151.953 90.3588C152.259 90.3049 152.582 90.3049 152.915 90.3678C153.66 90.5116 154.155 90.8351 154.397 91.3383C154.64 91.8415 154.685 92.4976 154.532 93.3153L154.065 95.7417L154.577 95.8405C155.215 95.9663 155.691 95.9034 155.987 95.6608C156.284 95.4182 156.49 95.0138 156.598 94.4386C156.706 93.8995 156.679 93.4951 156.517 93.2075C156.365 92.9199 156.158 92.7402 155.915 92.6683L156.158 91.4282C156.455 91.5091 156.715 91.6439 156.949 91.8416C157.182 92.0393 157.362 92.2819 157.506 92.5605C157.649 92.848 157.739 93.1715 157.775 93.54C157.82 93.9084 157.793 94.3038 157.712 94.7262C157.542 95.6069 157.2 96.2719 156.679 96.7122C156.158 97.1525 155.421 97.2873 154.469 97.0986L151.432 96.5145C151.037 96.4426 150.687 96.3707 150.39 96.3258C150.094 96.2719 149.797 96.2359 149.51 96.218L149.716 95.1306C149.941 95.1486 150.147 95.1665 150.345 95.1935C150.543 95.2205 150.758 95.2564 151.001 95.3013C150.642 95.0317 150.372 94.6453 150.183 94.1511C149.995 93.6568 149.959 93.0907 150.085 92.4616L150.103 92.4706ZM151.181 92.9559C151.127 93.2524 151.109 93.531 151.145 93.8096C151.172 94.0792 151.253 94.3308 151.369 94.5644C151.486 94.7891 151.648 94.9868 151.854 95.1396C152.061 95.2923 152.313 95.4002 152.609 95.4631L153.121 95.5619L153.544 93.3423C153.633 92.884 153.606 92.4976 153.481 92.201C153.346 91.9045 153.085 91.7157 152.69 91.6439C152.34 91.5809 152.034 91.6438 151.756 91.8505C151.486 92.0572 151.288 92.4257 151.19 92.9649L151.181 92.9559Z"
          fill="#0B68FE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.186 110.398C150.284 110.398 151.175 109.507 151.175 108.409C151.175 107.31 150.284 106.419 149.186 106.419C148.087 106.419 147.197 107.31 147.197 108.409C147.197 109.507 148.087 110.398 149.186 110.398ZM149.186 111.392C150.834 111.392 152.17 110.056 152.17 108.409C152.17 106.761 150.834 105.425 149.186 105.425C147.538 105.425 146.202 106.761 146.202 108.409C146.202 110.056 147.538 111.392 149.186 111.392Z"
          fill="#0B68FE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.94564 52.7121C11.0442 52.7121 11.9348 51.8216 11.9348 50.723C11.9348 49.6244 11.0442 48.7338 9.94564 48.7338C8.84706 48.7338 7.95649 49.6244 7.95649 50.723C7.95649 51.8216 8.84706 52.7121 9.94564 52.7121ZM9.94564 53.7067C11.5935 53.7067 12.9294 52.3709 12.9294 50.723C12.9294 49.0751 11.5935 47.7393 9.94564 47.7393C8.29777 47.7393 6.96191 49.0751 6.96191 50.723C6.96191 52.3709 8.29777 53.7067 9.94564 53.7067Z"
          fill="#0B68FE"
        />
      </g>
      <defs>
        <clipPath id="clip0_5087_645">
          <rect width="159.132" height="159.132" fill="white" transform="matrix(-1 0 0 -1 159.132 159.132)"/>
        </clipPath>
      </defs>
    </svg>
    <svg
      className="ed-procces-imgPlay-svg-playSvg"
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
    >
      <path
        d="M48.9641 23.9239C50.7322 24.9447 50.7322 27.4968 48.9641 28.5176L17.1377 46.8926C15.3695 47.9134 13.1594 46.6374 13.1594 44.5957L13.1594 7.84575C13.1594 5.80408 15.3695 4.52805 17.1377 5.54888L48.9641 23.9239Z"
        fill="#0B68FE"
      />
    </svg>
  </>
);
