import { useMemo, useState } from "react";
import { RadioBlock } from "./index";

export const useRadioBlock = (
  variants: string[],
  blockName: string,
) => {
  const [
    selectedVariant,
    setSelectedVariant
  ] = useState(variants[0]);

  const RelevantRadioBlock = useMemo(() => (
    <RadioBlock
      variants={variants}
      blockName={blockName}
      selectedVariant={selectedVariant}
      setSelectedVariant={setSelectedVariant}
    />
  ), [
    blockName,
    variants,
    selectedVariant,
    setSelectedVariant
  ]);

  return (
    {
      RelevantRadioBlock,
      selectedVariant
    }
  )
}
