import React, { useMemo, useState } from 'react';
import { useModal } from '../../hooks/use-modal';
import './index.css';
import { VideoModalContent, VideoModalState } from './index';

export const useVideoModal = (
  onClick?: () => void
) => {
  const [
    videoModalState,
    setVideoModalState
  ] = useState({} as VideoModalState);

  const {
    Modal,
    showModal,
  } = useModal('video-modal-frame-id', onClick);

  const showStatedModal = (videoModalState: VideoModalState) => {
    setVideoModalState(videoModalState);
    showModal();
  };

  const VideoModal = useMemo(() => (
    <Modal
      classList={['modalMain-wrapper--video']}
    >
      <VideoModalContent
        videoModalState={videoModalState}
      />
    </Modal>
  ), [
    Modal,
    videoModalState
  ]);

  return (
    {
      VideoModal,
      showModal,
      showStatedModal
    }
  );
};
