import React, { MutableRefObject, useContext } from 'react';
import './index.css';
import { CourseDto } from '../../../../api/interfaces/course/course-dto';
import { CourseCard } from '../../../../components/course-card';
import { SplashScreenContext } from "../../../../context/splash-screen-context";
import { CategoryDto } from "../../../../api/interfaces/category/category-dto";

interface CoursesEnumerationProps {
  category: CategoryDto;
  courses: CourseDto[];
  itemsRef: MutableRefObject<Array<HTMLDivElement | null>>;
  index: number;
}

export const CoursesEnumeration: React.FC<CoursesEnumerationProps> = (
  {
    category,
    courses,
    itemsRef,
    index
  }
) => {
  const {
    setLoading
  } = useContext(SplashScreenContext);

  return !!courses.length && (
    <div
      ref={ref => itemsRef.current[index] = ref}
      className='courses-enumeration-area'
    >
      <div className='ce-separation-area'>
        <div className='ce-separation-area-text'>
          {category.type === "nearest" ?
            <>
              Ближайшие
            </>
            : <>
              {category.name}
            </>}
        </div>

        <div className='ce-separation-arrow'>
          <div className='ce-separation-arrow-body'/>
          <svg className='ce-separation-arrow-arrow' xmlns='http://www.w3.org/2000/svg' width='20' height='32'
               viewBox='0 0 20 32' fill='none'>
            <path
              d='M19 16.0273H1M19 16.0273C13.0253 16.3614 1 16.6889 1 32M19 16.0273C13.0443 15.7036 1 15.3111 1 9.2775e-07'
              stroke='#0D0D0D'/>
          </svg>
        </div>
      </div>

      <div className='ce-courses-area'>
        {courses.map(course => {
          const relevantClassName = `${course?.type.toLowerCase()} ${course?.format.toLowerCase()}`

          return (
            <CourseCard
              course={course}
              relevantClassName={relevantClassName}
              setLoading={setLoading}
            />
          );
        })}
      </div>
    </div>
  );
};
