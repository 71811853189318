import React, { useLayoutEffect } from 'react';
import './index.css';
import { StyleProps } from '../../interfaces/style-props';
import gsap from 'gsap';
import { IdProps } from '../../interfaces/id-props';

interface FrameProps extends StyleProps, IdProps {
  customBg?: boolean | undefined;
  sectionClassName?: string | undefined;
  bgClassName?: string | undefined;
  coverClassName?: string | undefined;
  noCover?: boolean;
  children: React.ReactNode;
}

export const Frame: React.FC<FrameProps> = (
  {
    id,
    bgClassName = '',
    sectionClassName = '',
    coverClassName = '',
    noCover = false,
    style = {},
    children
  }
) => {
  useLayoutEffect(() => {
    setTimeout(() => {
      gsap.from(`#${id}`, {
        translateY: 100,
        opacity: 0,
        ease: 'bounce.in'
      });

      gsap.to(`#${id}`, {
        translateY: 0,
        opacity: 1
      });
    }, 800);
  }, [id]);

  return (
    <div
      className={bgClassName}
    >
      <section
        style={style}
        id={id}
        className={`frame ${sectionClassName}`}
      >
        <div className={`frame-cover ${coverClassName}`}>
          {children}
        </div>
      </section>
    </div>
  );
};
