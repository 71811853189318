import React from 'react';
import './index.css';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getFileUrl } from '../../api/url';
import { WorkDto } from '../../api/interfaces/frames/work/work-dto';
import ReactPlayer from "react-player";

export interface WorkModalState {
  index: number;
  works: WorkDto[] | undefined;
}

interface WorkModalProps {
  workModalState: WorkModalState;
}

const LeftArrow = (
  <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle
      cx='28'
      cy='28'
      r='27.25'
      transform='matrix(-1 0 0 1 56 0)'
      fill='white'
      stroke='#0D0D0D'
      strokeWidth='1.5'
    />
    <path
      d='M16.6619 27.9811H39.3378M16.6619 27.9811C20.835 27.7513 29.2342 27.5261 29.2342 16.9941M16.6619 27.9811C20.8217 28.2038 29.2342 28.4737 29.2342 39.0057'
      stroke='#0D0D0D'
      strokeWidth='1.5'
    />
  </svg>
);

const RightArrow = (
  <svg width='56' height='57' viewBox='0 0 56 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle
      cx='28'
      cy='28'
      r='27.25'
      transform='matrix(-1 0 0 1 56 0.333008)'
      fill='white'
      stroke='#0D0D0D'
      strokeWidth='1.5'
    />
    <path
      d='M39.3359 28.3141H16.66M39.3359 28.3141C35.1628 28.0843 26.7636 27.8591 26.7636 17.3271M39.3359 28.3141C35.1761 28.5368 26.7636 28.8068 26.7636 39.3387'
      stroke='#0D0D0D'
      strokeWidth='1.5'
    />
  </svg>
);

export const WorkModalContent: React.FC<WorkModalProps> = (
  {
    workModalState,
  }
) => {
  return (
    <Swiper
      className='swiper-WidthFull swiper-work swiper-comment--modal'
      slidesPerView={1}
      slidesPerGroup={1}
      spaceBetween={73}
      initialSlide={workModalState.index}
      loop
      pagination={{
        type: 'fraction',
        el: '.swiper-buttonCont-pagination',
        clickable: true,
        renderFraction: (currentClass2: string, totalClass2: string) => (
          '<span class="' + currentClass2 + '"></span>' +
          ' из ' +
          '<span class="' + totalClass2 + '"></span>'
        )
      }}
      navigation={{
        nextEl: '.swiper-buttonCont-next',
        prevEl: '.swiper-buttonCont-prev'
      }}
      modules={[
        Pagination,
        Navigation
      ]}
    >
      {workModalState.works?.map((work, index) => (
        <SwiperSlide key={index}>
          <div className='work-slide'>
            <div className='work-slide-imgBlock work-slide-imgBlock--modal'>
              {work.videoUrl ? (
                <ReactPlayer
                  controls={true}
                  className='react-player'
                  url={work.videoUrl}
                  width='100%'
                  height='100%'
                  playing={false}
                  style={{ overflow: 'hidden' }}
                />
              ) : (
                <img
                  src={getFileUrl(work.workId)}
                  alt=''
                  className='work-slider__img'
                />
              )}
            </div>
          </div>
        </SwiperSlide>
      ))}
      <div className='swiper-buttonCont'>
        <div className='swiper-buttonCont-button swiper-buttonCont-prev'>
          {LeftArrow}
        </div>

        <div className='swiper-buttonCont-pagination'/>

        <div className='swiper-buttonCont-button swiper-buttonCont-next'>
          {RightArrow}
        </div>
      </div>
    </Swiper>
  );
};
