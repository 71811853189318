import React, { useMemo } from 'react';
import './index.css';
import { ChildrenProps } from '../../interfaces/children-props';

interface BorderedTitleProps extends ChildrenProps {
  titleClassList?: Array<string>;
  descriptionClassList?: Array<string>;
  bordered?: boolean;
  description?: React.ReactNode;
  button?: React.ReactNode;
}

export const BorderedTitle: React.FC<BorderedTitleProps> = (
  {
    titleClassList = [],
    descriptionClassList = [],
    bordered = false,
    description,
    button,
    children
  }
) => {
  const BorderedTitle = useMemo(() => {
    const BorderedTitle = (
      <div className={`title-area-text ${titleClassList?.join(' ')} ${bordered ? 'title-withLine' : ''}`}>
        {children}
      </div>
    );

    if (button) {
      return (
        <div className='title-description-area-title-row'>
          {BorderedTitle}
          <div className='title-description-area-title-row-button'>
            {button}
          </div>
        </div>
      );
    }

    return BorderedTitle;
  }, [
    bordered,
    button,
    children,
    titleClassList
  ]);

  if (description) {
    return (
      <div className='title-description-area'>
        {BorderedTitle}
        <div className={`title-description-area-text ${descriptionClassList?.join(' ')}`}>
          {description}
        </div>
      </div>
    );
  }

  return BorderedTitle;
}
