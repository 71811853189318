import React from 'react';
import './index.css';
import { ClassListProps } from '../../interfaces/class-list-props';

interface LabelProps extends ClassListProps {
  courseType: string;
}

export const Label: React.FC<LabelProps> = (
  {
    classList = [],
    courseType
  }
) => {
  return (
    <div className={`label ${classList?.join(' ')}`}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='8' cy='8' r='4' fill='#0B68FE'/>
      </svg>

      <div className='label-text'>
        {courseType}
      </div>
    </div>
  );
};
