import React, { Dispatch, useMemo } from 'react';
import { getFileUrl } from '../../api/url';
import { courseFormatNameMap, courseTypeNameMap } from '../../api/mapper/map';
import { CourseDto } from '../../api/interfaces/course/course-dto';
import { CustomButton } from '../button';
import { CourseFormat } from "../../api/interfaces/course/course-format";
import parse from "html-react-parser";
import { colors } from "../../constants/colors";
import { CourseType } from "../../api/interfaces/course/course-type";
import { RelevantLink } from "../relevant-link";
import { dateToString } from "../../utils/dates";
import { getClassDates } from "../../hooks/get-class-dates";

interface CourseCardProps {
  course: CourseDto | undefined;
  relevantClassName: string | undefined;
  setLoading: Dispatch<boolean>;
}

export const CourseCard: React.FC<CourseCardProps> = (
  {
    course,
    relevantClassName,
    setLoading
  }
) => {
  const forDirection = relevantClassName?.includes('catalog');

  const {
    firstClassDate,
    lastClassDate
  } = getClassDates(course?.scheduleFrames?.at(0));

  const CourseDescriptionArea = useMemo(() => {
    if (forDirection) {
      return (
        <div>
          <CustomButton
            buttonType={"button"}
            type='button'
            title='Подробнее'
            classList={[
              'button--green',
              'border--offline-color'
            ]}
            arrowVisible
            arrowColor={'#0D0D0D'}
          />
        </div>
      );
    }
    
    if (course?.format === CourseFormat.online) {
      let textCourse = 'Старт в любое время';
      if(course.id == 'b13aff85-76bf-406d-ba9e-9553bd31a9b9'){
        textCourse = '03 июня 2024г.';
      }
      // if(course.id == '65D9DC54'){
      //   textCourse = '24 сентября 2024г.';
      // }
      return (
        <div className={`oc-dataStart ${relevantClassName}`}>
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11 18C11 16.1144 11 15.1716 11.5858 14.5858C12.1716 14 13.1144 14 15 14H25C26.8856 14 27.8284 14 28.4142 14.5858C29 15.1716 29 16.1144 29 18V19H11V18Z'
              stroke={`${colors['--brand-itmo-green-lizard']}`}
              strokeWidth='1.5'
            />
            <rect x='11' y='14' width='18' height='15' rx='2'
                  stroke={`${colors['--brand-itmo-green-lizard']}`}
                  strokeWidth='1.5'/>
            <path d='M15 11L15 16' stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth='1.5'
                  strokeLinecap='round'/>
            <path d='M25 11L25 16' stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth='1.5'
                  strokeLinecap='round'/>
          </svg>
          {textCourse}
        </div>
      );
    }

    if (!firstClassDate) {
      return (
        <div className={`oc-groupSetup ${relevantClassName}`}>
          <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
            <g className="layer">
              <path
                d="m31,20c0,6.08 -4.92,11 -11,11c-6.08,0 -11,-4.92 -11,-11c0,-6.08 4.92,-11 11,-11c6.08,0 11,4.92 11,11zm-19.99,0c0,4.97 4.02,8.99 8.99,8.99c4.97,0 8.99,-4.02 8.99,-8.99c0,-4.97 -4.02,-8.99 -8.99,-8.99c-4.97,0 -8.99,4.02 -8.99,8.99z"
                fill="" id="svg_1"/>
              <path
                d="m19.88,14.29c-0.49,0 -0.88,0.4 -0.88,0.88l0,5.72c0,0 0,0.23 0.11,0.4c0.07,0.15 0.19,0.27 0.34,0.36l4.08,2.36c0.42,0.25 0.96,0.1 1.2,-0.32c0.25,-0.42 0.11,-0.96 -0.32,-1.21l-3.66,-2.11l0,-5.2c0,-0.49 -0.4,-0.88 -0.88,-0.88z"
                fill="" id="svg_2"/>
            </g>
          </svg>

          Идёт набор в группу
        </div>
      );
    }

    return (
      firstClassDate && lastClassDate && <div className={`oc-dataStart ${relevantClassName}`}>
        <svg
          width='40'
          height='40'
          viewBox='0 0 40 40'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11 18C11 16.1144 11 15.1716 11.5858 14.5858C12.1716 14 13.1144 14 15 14H25C26.8856 14 27.8284 14 28.4142 14.5858C29 15.1716 29 16.1144 29 18V19H11V18Z'
            stroke={`${colors['--brand-itmo-green-lizard']}`}
            strokeWidth='1.5'
          />
          <rect x='11' y='14' width='18' height='15' rx='2' stroke={`${colors['--brand-itmo-green-lizard']}`}
                strokeWidth='1.5'/>
          <path d='M15 11L15 16' stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth='1.5'
                strokeLinecap='round'/>
          <path d='M25 11L25 16' stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth='1.5'
                strokeLinecap='round'/>
        </svg>

        {`${dateToString(firstClassDate)} – ${dateToString(lastClassDate)}`}
      </div>
    )
  }, [
    course?.format,
    forDirection,
    relevantClassName,
    firstClassDate,
    lastClassDate
  ]);

  const GraduationDocument = useMemo(() => {
    switch (course?.type) {
      case CourseType.plain:
        return <div className='oc-label'>Удостоверение ИТМО</div>;
      case CourseType.refresher:
        return <div className='oc-label'>Диплом ИТМО</div>;
    }
  }, [
    course
  ]);

  return course && (
    <RelevantLink
      to={`../${course?.fullPath}`}
      relative="route"
    >
      <div
        onClick={() => {
          setLoading(true);
        }}
        className={`oc-course ${relevantClassName}`}
      >
        <div className='oc-image-area'>
          <img
            alt={course.introductionFrame?.imageId}
            className='oc-image'
            src={getFileUrl(course.introductionFrame?.imageId!)}
          />
        </div>

        <div className='oc-blockMain'>
          <div className='oc-infoBlock'>
            <div className='oc-row'>
              <div className='oc-label oc-label--back'>{courseTypeNameMap(course.type)}</div>
              {course.type === CourseType.plain &&
                <div
                  className='oc-label'>{!!course.introductionFrame?.shortFacts && course.introductionFrame?.shortFacts[0]}</div>}
              {GraduationDocument}
            </div>
            <div className='oc-info'>
              <div className='oc-title'>{parse(course.name)}</div>
              <div className='oc-description'>{parse(course.introductionFrame?.description ?? "")}</div>
            </div>
          </div>

          {CourseDescriptionArea}
        </div>

        <div className={`oc-formatCourse ${relevantClassName}`}>
          {courseFormatNameMap(course.format)}
        </div>
      </div>
    </RelevantLink>
  );
};
