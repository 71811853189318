import React, { useContext, useMemo } from 'react';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import './index.css';
import "./list.css";
import { IdProps } from '../../interfaces/id-props';
import { CourseContext } from '../../context/course-context';
import parse from 'html-react-parser';
import { CourseType } from '../../api/interfaces/course/course-type';
import { Arrow } from "../../components/arrow";
import { colors } from "../../constants/colors";
import { TargetDto } from "../../api/interfaces/frames/targets/target-dto";
import Media from 'react-media';

interface TargetsFrameProps extends IdProps {
}

export const TargetsFrame: React.FC<TargetsFrameProps> = (
    {
      id
    }
  ) => {
    const {
      courseData
    } = useContext(CourseContext);

    const course = courseData?.course;

    const arrayChunks = (
      array: TargetDto[],
    ) => {
      if (array.length > 4) {
        return Array(Math.ceil(array.length / 3)).fill("").map((_, index) => index * 3).map(begin => array.slice(begin, begin + 3));
      }

      return [array];
    }

    const FrameContent = useMemo(() => (
      <div className='targets-frame-cover-content'>
        <BorderedTitle
          titleClassList={['text-design-bim-primary-black']}
        >
          {parse(course!.targetsFrame?.title ?? '')}
        </BorderedTitle>

        <Media query="(min-width: 1200px)" render={() => (
          <div className={`targets-list-area ${course!.type.toLowerCase()} ${course!.format.toLowerCase()}`}>
            {arrayChunks(course!.targetsFrame?.targets ?? [] as TargetDto[]).map(targetsSlice => (
              <>
                {targetsSlice.map((target, index) => (
                  <div className='list-title shift'>
                    {target.name}
                  </div>
                ))}

                {targetsSlice.map((target, index) => (
                  <div className='points-list-item' key={index}>
                    {target.points && target.points.length > 0 && target.points[0] !== "" && <Arrow
                      type='arrow-2'
                      color={colors['--brand-itmo-bluetiful']}
                    />}

                    <div className='points-list-item-text'>
                      {parse((target.points ?? [] as string[]).reduce((previousValue, currentValue) => (
                        `${previousValue}<br><br>${currentValue}`
                      )))}
                    </div>
                  </div>
                ))}
              </>
            ))}
          </div>
        )}/>

        <Media query="(max-width: 1200px)" render={() => (
          <div className={`targets-list-area ${course!.type.toLowerCase()} ${course!.format.toLowerCase()}`}>
            {(course!.targetsFrame?.targets ?? [] as TargetDto[]).map((target, index) => (
              <div className="targets-list-item">
                <div className='list-title shift'>
                  {target.name}
                </div>

                <div className='points-list-item' key={index}>
                  {target.points && target.points.length > 0 && target.points[0] !== "" &&
                    <Arrow
                    type='arrow-2'
                    color={colors['--brand-itmo-bluetiful']}
                  />}

                  <div className='points-list-item-text'>
                    {parse((target.points ?? [] as string[]).reduce((previousValue, currentValue) => (
                      `${previousValue}<br><br>${currentValue}`
                    )))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        />
      </div>
    ), [course]);

    return useMemo(() => {
      switch (course!.type) {
        case CourseType.plain:
          return (
            <Frame
              id={id}
              coverClassName={`border--design-bim-primary-black bg-design-bim-true-white`}
              bgClassName='outer-background-margin'
            >
              {FrameContent}
            </Frame>
          );
        case CourseType.refresher:
          return (
            <Frame
              id={id}
              sectionClassName={'with-cover'}
              bgClassName={`bg-brand-itmo-bluetiful`}
              coverClassName={`border--design-bim-primary-black bg-design-bim-true-white`}
            >
              {FrameContent}
            </Frame>
          );
      }
    }, [
      FrameContent,
      course,
      id
    ]);
  }
;
