import React, { useContext } from 'react';
import { Frame } from '../../components/frame';
import { IdProps } from '../../interfaces/id-props';
import './index.css';
import { ModalContext } from "../../context/modal-context";
import { CourseContext } from "../../context/course-context";

interface DiscountFrameProps extends IdProps {
  discount: number;
}

export const DiscountFrame: React.FC<DiscountFrameProps> = (
  {
    discount,
    id
  }
) => {
  const DiscountIcon = (
    <svg width="264" height="191" viewBox="0 0 264 191" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M263.22 86.133C263.22 123.849 232.471 154.431 194.532 154.431C156.592 154.431 125.843 123.849 125.843 86.133C125.843 48.4171 156.592 17.8348 194.532 17.8348C232.471 17.8348 263.22 48.4171 263.22 86.133Z"
        stroke="#0B68FE" strokeWidth="1.5611"/>
      <circle cx="247.609" cy="128.673" r="9.36662" fill="#0B68FE" stroke="#0B68FE" strokeWidth="1.5611"/>
      <circle cx="123.11" cy="95.5001" r="94.837" fill="#0B68FE"/>
      {/*<path d="M13.9483 120.746L47.0917 113.107L49.1535 122.051L16.01 129.691L13.9483 120.746ZM70.8556 77.9392L79.1614 76.0247L92.0107 131.77L81.3089 134.236L70.5583 87.5959L51.604 99.871L49.6711 91.4853L70.8556 77.9392ZM95.7821 101.631C91.1247 81.4256 96.7667 70.8733 114.417 66.805C132.066 62.7367 141.758 69.7545 146.416 89.96C151.092 110.245 145.468 120.878 127.818 124.946C110.168 129.014 100.458 121.916 95.7821 101.631ZM106.803 99.0907C110.503 115.143 115.792 118.382 125.775 116.081C135.678 113.798 138.996 108.492 135.315 92.5188C131.651 76.6259 126.345 73.3073 116.442 75.59C106.459 77.8911 103.122 83.1179 106.803 99.0907ZM171.135 90.7384C159.156 93.4997 152.633 89.2839 149.853 77.2245C147.074 65.165 151.173 58.5009 163.072 55.758C175.052 52.9967 181.655 57.1941 184.434 69.2535C187.214 81.313 183.115 87.9771 171.135 90.7384ZM182.753 110.854L195.54 49.1994L203.366 47.3954L190.579 109.049L182.753 110.854ZM169.589 84.0298C175.579 82.6492 177.287 79.4798 175.41 71.3337C173.532 63.1876 170.608 61.0859 164.619 62.4666C158.629 63.8472 156.982 66.9183 158.878 75.1443C160.774 83.3703 163.599 85.4105 169.589 84.0298ZM223.047 102.491C211.067 105.252 204.446 100.975 201.666 88.9155C198.887 76.8561 202.986 70.192 214.965 67.4307C226.865 64.6878 233.468 68.8852 236.248 80.9446C239.027 93.004 234.947 99.748 223.047 102.491ZM221.482 95.7025C227.392 94.3402 229.101 91.1709 227.223 83.0248C225.345 74.8787 222.422 72.777 216.512 74.1393C210.522 75.5199 208.795 78.6094 210.691 86.8354C212.587 95.0613 215.492 97.0831 221.482 95.7025Z" fill="#D0FF14"/>*/}
    </svg>
  );

  const {
    showProperAskQuestionModal
  } = useContext(ModalContext);

  const {
    courseData
  } = useContext(CourseContext);

  return courseData?.course?.priceFrame?.discount && (
    <Frame
      id={id}
      bgClassName='outer-background-margin'
      coverClassName={`discount-frame`}
    >
      <div className='discount-frame'>
        <div className='discount-frame-wrapper'>
          <div className='discount-frame-left'>
            <div className='discount-frame-description-title'>
              Уже проходили у нас онлайн-обучение? Получите <span
              className='text-brand-itmo-green-lizard'>скидку {discount}%</span> на этот курс
            </div>
            <div className='discount-frame-description-text'>
              {<>
                <span
                  onClick={() => showProperAskQuestionModal()}
                >
                  Сообщите нам
                </span> даты предыдущего обучения — мы подарим скидку {discount}% от стоимости курса и оформим ваше поступление по ускоренной схеме.
              </>}
            </div>
          </div>
          <div className='discount-frame-right'>
            <div className='discount-frame-discount-value'>
              -{discount}%
            </div>
            {DiscountIcon}
          </div>
        </div>
      </div>
    </Frame>
  );
};
