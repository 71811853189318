import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { ifClassExists } from '../../utils/if-class-exists';
import { PageType, usePageType } from '../../hooks/use-page-type';

export const ScrollUpButton: React.FC = () => {
  const pageType = usePageType();

  const scrollUp = (pageY: number) => {
    window.scrollBy({
      top: -pageY,
      left: 0,
      behavior: 'smooth'
    });
  };

  const initialScrollY = useRef(0);
  const scrollUpButtonRef = useRef({} as HTMLDivElement);

  const [
    timerActive,
    setTimerActive
  ] = useState(false);

  useEffect(() => {
    const scrollListener = () => {
      if (pageType === PageType.COURSE) {
        if (window.userManualScroll) {
          const currentScroll = window.scrollY;

          if (currentScroll >= 0 && currentScroll <= document.documentElement.scrollHeight) {
            if (currentScroll - initialScrollY.current > 20) {
              initialScrollY.current = currentScroll;
              if (scrollUpButtonRef.current) {
                scrollUpButtonRef.current.classList.add('move');
              }
            }

            if (initialScrollY.current - currentScroll > 20) {
              initialScrollY.current = currentScroll;

              if (scrollUpButtonRef.current) {
                scrollUpButtonRef.current.classList.remove('move');
              }
            }
          }
        }
      }

      setTimerActive(true);
    };

    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, [pageType]);

  useEffect(() => {
    if (timerActive) {
      scrollUpButtonRef.current.classList.add('shown');
      setTimeout(() => setTimerActive(false), 3000);
    } else {
      scrollUpButtonRef.current.classList.remove('shown');
    }
  }, [timerActive]);

  return (
    <div
      ref={scrollUpButtonRef}
      onClick={e => scrollUp(e.pageY)}
      className={`scroll-up-button ${ifClassExists('catalog', pageType === PageType.DIRECTION)}`}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.98382 0.281738L9.98382 19.7183M9.98382 0.281738C9.78686 3.8587 9.59378 11.058 0.566407 11.058M9.98382 0.281738C10.1747 3.84729 10.4061 11.058 19.4334 11.058"
          stroke="#0D0D0D" stroke-width="1.5"/>
      </svg>
    </div>
  );
};
