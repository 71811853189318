import { useRef } from 'react';
import { MergedWrapperProps, ModalWrapperProps, StatedModalWrapperProps } from './wrappers/interface/modal-wrapper-props';
import { ReviewModalState } from './feedback-modal';
import { addElementClass } from '../imperative-dom-control/toggle-element-class';
import { SignupModalState } from './signup-modal';
import { WorkModalState } from './work-modal';
import { IModal } from '../interfaces/i-modal';
import { VideoModalState } from './video-modal';
import { FilterModalState } from './filter-modal';
import { DocumentModalState } from "./document-modal";

export const useModalRefs: () => IModal = () => {
  const signupModalWrapperRef = useRef({} as MergedWrapperProps<SignupModalState>);
  const filterModalWrapperRef = useRef({} as MergedWrapperProps<FilterModalState>);
  const askQuestionModalWrapperRef = useRef({} as ModalWrapperProps);
  const videoModalWrapperRef = useRef({} as StatedModalWrapperProps<VideoModalState>);
  const documentModalWrapperRef = useRef({} as MergedWrapperProps<DocumentModalState>);
  const feedbackModalWrapperRef = useRef({} as StatedModalWrapperProps<ReviewModalState>);
  const coursesModalWrapperRef = useRef({} as ModalWrapperProps);
  const workModalWrapperRef = useRef({} as StatedModalWrapperProps<WorkModalState>);
  const locationModalWrapperRef = useRef({} as ModalWrapperProps);
  // const moreInfoModalWrapperRef = useRef({} as ModalWrapperProps);

  const showSignupModal = () => {
    signupModalWrapperRef.current.showModal();
  };

  const showFilterModal = () => {
    filterModalWrapperRef.current.showModal();
  };

  const showAskQuestionModal = () => {
    askQuestionModalWrapperRef.current.showModal();
  };

  const showDocumentModal = () => {
    documentModalWrapperRef.current.showModal();
  };

  const showLocationModal = () => {
    locationModalWrapperRef.current.showModal();
  };

  const showCoursesModal = () => {
    coursesModalWrapperRef.current.showModal();
  };

  const showFeedbackModal = (feedbackModalState: ReviewModalState) => {
    feedbackModalWrapperRef.current.showStatedModal(feedbackModalState);
  };

  const showVideoStateModal = (videoModalState: VideoModalState) => {
    videoModalWrapperRef.current.showStatedModal(videoModalState);
  };

  const showFilterStateModal = (filterModalState: FilterModalState) => {
    filterModalWrapperRef.current.showStatedModal(filterModalState);
  };

  const changeFilterStateModal = (filterModalState: FilterModalState) => {
    if (filterModalWrapperRef.current.setFilterModalState) {
      filterModalWrapperRef.current.setFilterModalState(filterModalState);
    }
  };

  const showSignupStateModal = (signupModalState: SignupModalState) => {
    signupModalWrapperRef.current.showStatedModal(signupModalState);
  };

  const showDocumentStateModal = (documentModalState: DocumentModalState) => {
    documentModalWrapperRef.current.showStatedModal(documentModalState);
  };

  const showWorkStateModal = (workModalState: WorkModalState) => {
    workModalWrapperRef.current.showStatedModal(workModalState);
  };

  // const showMoreInfoModal = () => {
  //   moreInfoModalWrapperRef.current.showModal();
  // };

  const showProperSignupModal = () => {
    showSignupModal();
    addElementClass(document.body, 'modal-open');
  }

  const showProperLocationModal = () => {
    showLocationModal();
    addElementClass(document.body, 'modal-open');
  }

  const showProperSignupStateModal = (signupModalState: SignupModalState) => {
    showSignupStateModal(signupModalState);
    addElementClass(document.body, 'modal-open');
  }

  const showProperAskQuestionModal = () => {
    showAskQuestionModal();
    addElementClass(document.body, 'modal-open');
  }

  const showProperVideoStateModal = (videoModalState: VideoModalState) => {
    showVideoStateModal(videoModalState);
    addElementClass(document.body, 'modal-open');
  }

  const showProperFilterStateModal = (filterModalState: FilterModalState) => {
    showFilterStateModal(filterModalState);
    addElementClass(document.body, 'modal-open');
  }

  const showProperDocumentModal = (documentModalState: DocumentModalState) => {
    showDocumentStateModal(documentModalState);
    addElementClass(document.body, 'modal-open');
  }

  const showProperFeedbackModal = (feedbackModalState: ReviewModalState) => {
    showFeedbackModal(feedbackModalState);
    addElementClass(document.body, 'modal-open');
  }

  const showProperWorkStateModal = (workModalState: WorkModalState) => {
    showWorkStateModal(workModalState);
    addElementClass(document.body, 'modal-open');
  }

  // const showProperMoreInfoModal = () => {
  //   showMoreInfoModal();
  //   addElementClass(document.body, 'modal-open');
  // }


  return ({
    signupModalWrapperRef,
    filterModalWrapperRef,
    askQuestionModalWrapperRef,
    videoModalWrapperRef,
    documentModalWrapperRef,
    feedbackModalWrapperRef,
    coursesModalWrapperRef,
    workModalWrapperRef,
    locationModalWrapperRef,
    // moreInfoModalWrapperRef,

    showProperVideoStateModal,
    showProperSignupStateModal,
    showProperAskQuestionModal,
    showProperDocumentModal,
    showProperFeedbackModal,
    showProperWorkStateModal,
    showProperLocationModal,
    // showProperMoreInfoModal,
    showProperFilterStateModal,
    changeFilterStateModal
  } as IModal);
}
