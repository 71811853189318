import React, { useEffect, useRef } from 'react';
import { useTrigger } from './use-trigger';
import { ModalFrame } from '../components/modal';
import gsap from 'gsap';

export const useModal = (
  id: string,
  onClick?: () => void,
) => {
  const modalContainerRef = useRef({} as HTMLDivElement);
  const modalMainWrapperRef = useRef({} as HTMLDivElement);

  const refs = {
    modalContainerRef,
    modalMainWrapperRef
  };

  const [
    modalVisible,
    {
      offHandler: hideModal,
      onHandler: showModal,
      toggleHandler: toggleModal
    }
  ] = useTrigger(false);

  const Modal = ModalFrame({
    id,
    modalVisible,
    onClick: () => {
      onClick && onClick();
      hideModal();
    },
    refs,
  });

  useEffect(() => {
    const element = document.getElementById(`${id}`);

    if (element && !element.classList.contains('constant-visible')) {
      if (modalVisible) {
        gsap.to(`#${id}`, {
          display: 'unset',
          opacity: 1,
          pointerEvents: 'all',
          ease: 'circ.in',
          duration: 0.2,
        });
      }
    }
  }, [modalVisible, Modal, id]);

  return ({
    Modal,
    showModal,
    hideModal,
  });
};
