import React, { useContext, useMemo } from 'react';
import './index.css';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';

import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { IdProps } from '../../interfaces/id-props';

import { ArrowRight } from './svg/arrowRight';
import { ArrowLeft } from './svg/arrowLeft';
import { ModalContext } from '../../context/modal-context';
import { CustomButton } from '../../components/button';
import { CourseContext } from "../../context/course-context";
import { CourseType } from "../../api/interfaces/course/course-type";
import { DirectionContext } from "../../context/direction-context";
import parse from "html-react-parser";
import { getFileUrl } from "../../api/url";

interface EducationProcessProps extends IdProps {
}

export const EducationProcessFrame: React.FC<EducationProcessProps> = ({ id }) => {
  const { showProperSignupStateModal } = useContext(ModalContext);

  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    direction
  } = useContext(DirectionContext);

  const EducationProcessContent = useMemo(() => (
    <div className="education-process-refresh">
      <Swiper
        className="swiper-WidthFull"
        pagination={{
          type: "fraction",
          el: ".swiper-buttonCont-pagination",
          clickable: true,
          renderFraction: (currentClass: string, totalClass: string) =>
            `<span class='${currentClass}'></span> из <span class='${totalClass}'></span>`,
        }}
        navigation={{
          nextEl: ".swiper-buttonCont-next",
          prevEl: ".swiper-buttonCont-prev",
        }}
        modules={[Pagination, Navigation]}
      >
        {direction?.educationProcessFrame?.slides.map((item, index) => (

          <SwiperSlide key={index}>
            <div className={`educ-process-sliderRefresher `}>
              <div className="educ-process-sliderRefresher-block">
                <BorderedTitle titleClassList={["text-design-bim-true-white"]}>
                  {parse(direction?.educationProcessFrame?.title ?? "")}
                </BorderedTitle>
                <div className="educ-process-sliderRefresher__text">
                  {parse(item.description)}
                </div>
                <div className="swiper-buttonCont-refresher-block">
                  <CustomButton
                    buttonType={"button"}
                    type="button"
                    title="Записаться сейчас"
                    classList={[
                      "button--green",
                      'border--offline-color',
                      'color--online-color-text'
                    ]}
                    onClick={() => {
                      window.ym(direction?.meta?.counter, 'reachGoal', `${direction?.meta?.signUpButtonYandexTargetName}`);

                      showProperSignupStateModal({ tabIndex: 0 });
                    }}
                    arrowVisible
                    arrowClassName='color--offline-color'
                  />
                  <CustomButton
                    buttonType={"button"}
                    type="button"
                    title="Посмотреть программу"
                    classList={[
                      "button--transparent",
                      'color--online-color-text'
                    ]}
                    onClick={() => document.getElementById('composed-program-frame')?.scrollIntoView({ behavior: 'smooth' })}
                  />
                </div>
              </div>
              <div className="educ-process-sliderRefresher-block-img">
                <img
                  alt={item.imageId}
                  src={getFileUrl(item.imageId)}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}


        <div className="swiper-buttonCont-refresher">
          <div className="swiper-buttonCont-refresher-block">
            <CustomButton
              buttonType={"button"}
              type="button"
              title="Записаться сейчас"
              classList={[
                "button--green",
                'border--offline-color',
                'color--online-color-text'
              ]}
              onClick={() => {
                window.ym(direction?.meta?.counter, 'reachGoal', `${direction?.meta?.signUpButtonYandexTargetName}`);

                showProperSignupStateModal({ tabIndex: 0 });
              }}
              arrowVisible
              arrowClassName='color--offline-color'
            />
            <CustomButton
              buttonType={"button"}
              type="button"
              title="Посмотреть программу"
              classList={[
                "button--transparent",
                'color--online-color-text'
              ]}
              onClick={() => document.getElementById('composed-program-frame')?.scrollIntoView({ behavior: 'smooth' })}
            />
          </div>
          <div className="swiper-buttonCont">
            <div className="swiper-buttonCont-button swiper-buttonCont-prev">
              <ArrowLeft />
            </div>
            <div className="swiper-buttonCont-pagination"></div>
            <div className="swiper-buttonCont-button swiper-buttonCont-next">
              <ArrowRight />
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  ), [direction?.educationProcessFrame?.title, direction?.educationProcessFrame?.slides, direction?.meta?.counter, direction?.meta?.signUpButtonYandexTargetName, showProperSignupStateModal]);


  return useMemo(() => {
    switch (course?.type) {
      case CourseType.refresher:
        if (course?.fullPath == "courses/refresher/online/software-tester") {
          return null;
        } else {
          return (
            <Frame
              id={id}
              bgClassName="bg-brand-itmo-bluetiful"
              coverClassName={`bg-brand-itmo-bluetiful no-border no-padding-xRight bor-rad-0`}
              sectionClassName={`frameForSlider`}
            >
              {EducationProcessContent}
            </Frame>
          );
        }

      case CourseType.plain:
        return (
          <Frame
            id={id}
            bgClassName="outer-background-margin bg-brand-itmo-bluetiful"
            coverClassName={`bg-brand-itmo-bluetiful no-border no-padding-xRight bor-rad-0`}
            sectionClassName={`frameForSlider`}
          >
            {EducationProcessContent}
          </Frame>
        )
    }
  }, [
    EducationProcessContent,
    course?.type,
    id
  ]);
};
