import { forwardRef, useImperativeHandle } from 'react';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';
import { useLocationModal } from '../location-modal/use-location-modal';

export const LocationModalWrapper = forwardRef((
  props,
  ref
) => {
  const {
    LocationModal,
    showModal
  } = useLocationModal(() => {
    removeElementClass(document.body, 'modal-open');
  });

  useImperativeHandle(ref, () => ({
    showModal: () => showModal()
  }));

  return LocationModal;
});
