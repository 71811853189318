import { forwardRef, useImperativeHandle } from 'react';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';

import { useVideoModal } from '../video-modal/use-video-modal';
import { VideoModalState } from '../video-modal';

export const VideoModalWrapper = forwardRef((
  props,
  ref
) => {
  const {
    VideoModal,
    showModal,
    showStatedModal
  } = useVideoModal(() => {
    removeElementClass(document.body, 'modal-open');
  });

  useImperativeHandle(ref, () => ({
    showModal: () => showModal(),
    showStatedModal: (videoModalState: VideoModalState) => showStatedModal(videoModalState)
  }));

  return VideoModal;
});
