import { useContext, useEffect } from "react";
import { DirectionContext } from "../../../context/direction-context";
import useAxios from "axios-hooks";
import { DirectionDto } from "../../../api/interfaces/direction/direction-dto";
import { getDirectionUrl } from "../../../api/url";
import { useParams } from "react-router-dom";
import { SplashScreenContext } from "../../../context/splash-screen-context";
import { CourseContext, CourseData } from "../../../context/course-context";

export const useFetchDirectionData = (
  // shouldFetch: boolean
) => {
  const {
    loading,
    setLoading,
  } = useContext(SplashScreenContext);

  const {
    direction,
    setDirection,
    directionType
  } = useContext(DirectionContext);

  const {
    setCourseData
  } = useContext(CourseContext);

  const [{
    data: directionData
  }, fetchDirectionData] = useAxios<DirectionDto>({
    url: getDirectionUrl(),
    params: {
      type: directionType
    }
  }, {
    manual: true
  });

  useEffect(() => {
    if (!direction) {
      fetchDirectionData();
    } else if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [
    loading,
    direction,
    fetchDirectionData,
    setLoading
  ]);

  useEffect(() => {
    if (!direction && directionData) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);

      setTimeout(() => {
        setDirection(directionData);
      }, 700);
    }
  }, [
    direction,
    directionData,
    setDirection,
    setLoading
  ]);

  const {
    courseFormat,
    coursePath
  } = useParams();

  useEffect(() => {
    if (direction) {
      const course = direction.courses.find(course => course.path === coursePath && course.format === courseFormat);

      setCourseData({
        course,
        allCourses: direction.courses
      } as CourseData);
    }
  }, [
    direction,
    coursePath,
    courseFormat,
    setCourseData
  ]);

  return {
    direction: direction
  };
}
