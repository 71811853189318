import React from 'react';
import './index.css';
import { useDispatch } from 'react-redux';
import { allCoursesVisibleOff, modalBurgerVisibleOff } from '../store/slices/navbar-slice';
import { removeElementClass } from '../../../imperative-dom-control/toggle-element-class';
import { NavBarDto } from '../../../api/interfaces/elements/nav-bar/nav-bar-dto';

interface NavListProps {
  className: string | undefined;
  navBar: NavBarDto | undefined;
}

export const NavList: React.FC<NavListProps> = (
  {
    className,
    navBar
  }
) => {
  const dispatch = useDispatch();

  return (
    <ul className="nav-list">
      {navBar?.tabs.map((tab, index) => (
        tab.active && (
          <li
            key={index}
            className={`nav-list-item ${className}`}
          >
            <div
              className={`nav-list-item--link ${className}`}
              onClick={() => {
                dispatch(allCoursesVisibleOff());
                dispatch(modalBurgerVisibleOff());

                removeElementClass(document.body, 'modal-open');

                window.userManualScroll = false;
                document.getElementById(tab.frameId)?.scrollIntoView({ behavior: 'smooth' });

                setTimeout(() => {
                  window.userManualScroll = true;
                }, 1000);
              }}
            >
              {tab.name}
            </div>
          </li>
        )
      ))}
    </ul>
  );
}
