import React, { useContext, useMemo } from 'react';
import './index.css';
import { BorderedTitle } from '../../components/bordered-title';
import { Frame } from '../../components/frame';
import { IdProps } from '../../interfaces/id-props';
import { DirectionContext } from "../../context/direction-context";
import { CourseContext } from "../../context/course-context";
import { SalaryFrameDto } from "../../api/interfaces/frames/salary/salary-frame-dto";
import { getFileUrl } from "../../api/url";
import parse from "html-react-parser";

export const SalaryFrame: React.FC<IdProps> = (
  {
    id
  }
) => {
  const {
    direction
  } = useContext(DirectionContext);

  const {
    courseData
  } = useContext(CourseContext);

  const salaryFrame: SalaryFrameDto | null | undefined = useMemo(() => {
    if (courseData && courseData.course && courseData.course.salaryFrame) {
      return courseData.course.salaryFrame;
    }

    return direction?.salaryFrame;
  }, [
    courseData,
    direction
  ]);

  return salaryFrame && (
    <Frame
      id={id}
      bgClassName='outer-background-margin'
      coverClassName={`border--design-bim-primary-black bg-design-bim-true-white`}
    >
      <div className='salary-frame-cover-content'>
        <div className='salary-frame-cover-content-leftSide'>
          <BorderedTitle
            titleClassList={['text-design-bim-primary-black']}
            bordered
          >
            {parse(salaryFrame.title)}
          </BorderedTitle>

          <div className="salary-frame-description">
            {parse(salaryFrame.description)}
          </div>
        </div>

        <div className='salary-frame-cover-content-rightSide'>
          <img
            src={getFileUrl(salaryFrame.imageId)}
            alt={salaryFrame.imageId}
          />
        </div>
      </div>
    </Frame>
  );
};
