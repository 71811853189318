import React, { useContext, useMemo } from 'react';
import './index.css';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import { PersonalizedPrice } from '../../components/personalized-price';
import { CardList } from '../../components/card-list';
import { CustomButton } from '../../components/button';
import { IdProps } from '../../interfaces/id-props';
import { CourseContext } from '../../context/course-context';
import parse from 'html-react-parser';
import { ModalContext } from '../../context/modal-context';
import { CourseType } from '../../api/interfaces/course/course-type';
import { DirectionContext } from "../../context/direction-context";
import { CourseFormat } from "../../api/interfaces/course/course-format";

interface PriceFrameProps extends IdProps {
}

const BackgroundCurve = (
  <svg width='714' height='413' viewBox='0 0 714 413' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d_3329_8846)'>
      <path fillRule='evenodd' clipRule='evenodd'
        d='M-15.4849 441.096L-56.9995 441.096C-56.9995 441.096 -56.9995 421.5 -56.4121 403.292C-55.2092 366.002 -38.8504 322.003 -15.4849 291.811C-3.05778 275.754 16.5084 259.448 44.0394 258.737C82.8233 257.735 109.407 277.66 129.021 292.361C139.414 300.151 150.142 308.875 162.879 312.299C170.56 314.364 179.824 314.453 192.952 308.756C201.087 305.226 211.53 294.241 222.064 259.629C230.677 231.329 249.039 207.483 273.236 195.192C298.2 182.51 327.977 182.992 354.997 201.535C383.29 220.952 390.832 249.708 385.134 276.48C379.733 301.861 362.749 325.322 342.318 340.521C332.425 347.88 321.11 354.065 311.157 359.608C300.748 365.405 291.336 370.754 282.93 377.111C267.155 389.043 255.184 404.229 253.032 431.837C251.305 453.986 259.064 478.135 271.028 500.433C282.883 522.527 297.787 540.787 307.447 550.447L279.838 578.056C267.692 565.911 250.422 544.611 236.623 518.894C222.933 493.379 211.558 461.465 214.105 428.802C217.295 387.894 236.583 363.211 259.376 345.971C270.255 337.743 281.953 331.18 292.158 325.497C301.293 320.41 310.606 315.447 319.014 309.193C333.163 298.668 343.84 282.94 346.944 268.352C349.753 255.155 346.647 243.16 332.904 233.728C317.887 223.423 303.387 223.67 290.919 230.003C277.684 236.726 265.459 251.146 259.417 270.998C248.382 307.257 233.606 333.677 208.495 344.574C188.014 353.462 169.704 354.566 152.741 350.006C135.193 345.288 120.458 334.617 106.055 323.987C85.536 308.843 67.1194 292.868 41.6799 297.896C-15.4849 309.193 -21.0746 397.525 -15.4849 441.096Z'
        fill='white' />
    </g>
    <g filter='url(#filter1_d_3329_8846)'>
      <path fillRule='evenodd' clipRule='evenodd'
        d='M98.6327 612.55L49.4448 610.27C49.4448 610.27 47.3638 588.283 50.0322 572.466C56.2391 535.676 67.5939 491.176 90.9594 460.985C103.387 444.928 122.953 428.622 150.484 427.91C189.268 426.908 215.851 446.834 235.466 461.535C245.859 469.325 256.586 478.049 269.323 481.473C277.004 483.538 286.268 483.627 299.396 477.93C307.531 474.4 317.974 463.415 328.508 428.803C337.121 400.503 355.483 376.657 379.68 364.365C404.645 351.684 434.421 352.166 461.441 370.709C489.735 390.126 497.276 418.882 491.579 445.654C486.177 471.035 469.194 494.496 448.763 509.694C438.869 517.054 427.554 523.239 417.601 528.782C407.193 534.579 397.78 539.927 389.374 546.285C373.599 558.217 361.629 573.403 359.476 601.011C357.749 623.16 365.508 647.309 377.472 669.607C389.328 691.701 404.232 709.961 413.891 719.621L386.282 747.23C374.137 735.084 356.866 713.785 343.067 688.067C329.377 662.553 318.002 630.639 320.549 597.975C323.739 557.068 343.028 532.384 365.821 515.145C376.699 506.916 388.398 500.354 398.603 494.67C407.737 489.584 417.05 484.621 425.458 478.367C439.607 467.842 450.284 452.114 453.389 437.526C456.197 424.329 453.091 412.334 439.348 402.902C424.331 392.597 409.831 392.843 397.364 399.177C384.129 405.9 371.904 420.319 365.862 440.172C354.826 476.431 340.051 502.851 314.94 513.748C294.458 522.636 276.149 523.74 259.186 519.18C241.637 514.462 226.902 503.791 212.499 493.161C191.98 478.016 173.564 462.042 148.124 467.069C90.9594 478.367 93.0431 568.98 98.6327 612.55Z'
        fill='white' />
    </g>
    <defs>
      <filter id='filter0_d_3329_8846' x='-76.0071' y='167.506' width='481.92' height='429.559'
        filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='9.50405' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix'
          values='0 0 0 0 0.892865 0 0 0 0 0.929083 0 0 0 0 0.9875 0 0 0 0.8 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3329_8846' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3329_8846' result='shape' />
      </filter>
      <filter id='filter1_d_3329_8846' x='29.6638' y='336.679' width='482.693' height='429.559'
        filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='9.50405' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix'
          values='0 0 0 0 0.892865 0 0 0 0 0.929083 0 0 0 0 0.9875 0 0 0 0.8 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3329_8846' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3329_8846' result='shape' />
      </filter>
    </defs>
  </svg>
);

export const PriceFrame: React.FC<PriceFrameProps> = (
  {
    id
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    direction
  } = useContext(DirectionContext);

  const {
    showProperSignupStateModal
  } = useContext(ModalContext);

  const discountUnavailable = useMemo(() => {
    switch (course?.type) {
      case CourseType.plain:
        return false;
      case CourseType.refresher:
        return true;
    }
  }, [
    course
  ]);

  const priceButtonText = useMemo(() => {
    switch (course?.type) {
      case CourseType.plain:
        return 'Купить курс';
      case CourseType.refresher:
        return 'Купить программу';
    }
  }, [
    course
  ]);

  const PricePoints = useMemo(() => {
    switch (course?.format) {
      case CourseFormat.online:
        return (
          <CardList
            arrowColor='rgba(255,255,255,0.6)'
            classList={[
              'pf-point'
            ]}
            points={direction?.onlinePriceFrame?.points ?? []}
          />
        );
      case CourseFormat.offline:
        return (
          <CardList
            arrowColor='rgba(255,255,255,0.6)'
            classList={[
              'pf-point'
            ]}
            points={direction?.offlinePriceFrame?.points ?? []}
          />
        );
    }
  }, [
    course?.format,
    direction?.offlinePriceFrame?.points,
    direction?.onlinePriceFrame?.points
  ]);

  const PriceFrameContent = useMemo(() => {
    return (
      <>
        <div className='pf-bg-svg'>
          {BackgroundCurve}
        </div>

        <div className='price-frame-content'>
          <div className='pf-leftSide'>
            <BorderedTitle
              titleClassList={[
                'text-design-bim-true-white'
              ]}
              description={course?.priceFrame?.titleDescription ?? ""}
              descriptionClassList={[
                'text-design-bim-true-white'
              ]}
            >
              {parse((course?.priceFrame?.title ?? "").replace("-", "&#8209;"))}
            </BorderedTitle>
          </div>

          <div className='pf-rightSide'>
            <div className='pf-prices'>
              <PersonalizedPrice
                personTitle={course?.priceFrame?.usualPriceTitle}
                price={course?.priceFrame?.usualPriceValue}
    
              />

              {course?.priceFrame?.installmentPriceTitle && course?.priceFrame?.installmentPriceValue &&
                <PersonalizedPrice
        
                  personTitle={course?.priceFrame?.installmentPriceTitle}
                  price={course?.priceFrame?.installmentPriceValue}
                />}

              {course?.priceFrame?.discountPriceTitle && course?.priceFrame?.discountPriceTitle &&
                <PersonalizedPrice
             
                  personTitle={course?.priceFrame?.discountPriceTitle}
                  discount={course?.priceFrame?.discount}
                  discountDescription={course?.priceFrame?.discountDescription ?? ""}
                  price={course?.priceFrame?.usualPriceValue}
                  newPrice={course?.priceFrame?.discountPriceValue}
                  classList={['price-list-noborder']}
                  discountUnavailable={discountUnavailable}
                />}

              {PricePoints}
            </div>

            <div>
              <CustomButton
                buttonType={"button"}
                type='button'
                title={priceButtonText}
                classList={[
                  'button--green',
                  'border--offline-color',
                  'color--online-color-text'
                ]}
                arrowVisible
                arrowClassName='color--offline-color'
                onClick={() => {
                  window.ym(direction?.meta?.counter, 'reachGoal', `${direction?.meta?.signUpButtonYandexTargetName}`);

                  showProperSignupStateModal({ tabIndex: 0 });
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }, [
    direction?.meta?.counter,
    direction?.meta?.signUpButtonYandexTargetName,
    priceButtonText,
    course,
    discountUnavailable,
    showProperSignupStateModal,
    PricePoints
  ]);

  return useMemo(() => {
    switch (course?.type) {
      case CourseType.plain:
        return (
          <Frame
            id={id}
            bgClassName='outer-background-margin'
            coverClassName={`bg-design-bim-gradient-black `}
            sectionClassName={'padding-top-80 padding-bottom-80'}
          >
            {PriceFrameContent}
          </Frame>
        );
      case CourseType.refresher:
        return (
          <Frame
            id={id}
            bgClassName='outer-background-margin'
            coverClassName={`bg-design-bim-gradient-black `}
          >
            {PriceFrameContent}
          </Frame>
        );
    }
  }, [
    PriceFrameContent,
    id,
    course
  ]);
};
