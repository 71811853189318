import React, { useContext } from 'react';
import { DirectionContext } from '../../../../context/direction-context';
import './index.css';
import moment from "moment";

interface InfoTagProps {

}

export const InfoTag: React.FC<InfoTagProps> = () => {
  const {
    direction
  } = useContext(DirectionContext);

  return direction && (
    <div className='info-frame-title-tag'>
      <div className='info-frame-title-tag-wrapper'>
        <div className='info-frame-title-tag-text'>
          {direction.type}
        </div>

        <div className='info-frame-title-tag-text'>
          {moment().year()}
        </div>
      </div>
    </div>
  );
};
