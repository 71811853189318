import React from 'react';

export const ArrowLeft = () => (
  <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle
      cx='28'
      cy='28'
      r='27.25'
      transform='matrix(-1 0 0 1 56 0)'
      fill='white'
      stroke='#0D0D0D'
      strokeWidth='1.5'
    />
    <path
      d='M16.6619 27.9811H39.3378M16.6619 27.9811C20.835 27.7513 29.2342 27.5261 29.2342 16.9941M16.6619 27.9811C20.8217 28.2038 29.2342 28.4737 29.2342 39.0057'
      stroke='#0D0D0D'
      strokeWidth='1.5'
    />
  </svg>
);
