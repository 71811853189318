import React, { SetStateAction, useCallback, useMemo, useState } from 'react';
import { Filter } from './index';
import { CourseAmountInfo } from '../index';
import { CourseFormat } from '../../../../api/interfaces/course/course-format';
import { CourseFilterContext } from '../../../../context/course-filter-context';
import { CourseDto } from "../../../../api/interfaces/course/course-dto";
import { CategoryDto } from "../../../../api/interfaces/category/category-dto";
import { getClassDates } from "../../../../hooks/get-class-dates";

export interface CoursesFilter {
  selectedFormat: CourseFormat | undefined;
  selectedCategories: CategoryDto[];
  nearestOfflineCourses: CourseDto[];

  setSelectedFormat: React.Dispatch<SetStateAction<CourseFormat | undefined>>;
  setSelectedCategories: React.Dispatch<SetStateAction<CategoryDto[]>>;

  CoursesFilter: React.ReactNode;
  resetData: Function;

  filtersAmount: number;
}

export const useCoursesFilter = (
  courseAmountInfo: CourseAmountInfo,
  lastSelectedFormat: CourseFormat | undefined,
  lastSelectedCategories: CategoryDto[],
  immediate: boolean,
): CoursesFilter => {
  const [
    selectedFormat,
    setSelectedFormat
  ] = useState<CourseFormat | undefined>(lastSelectedFormat);

  const [
    selectedCategories,
    setSelectedCategories
  ] = useState<CategoryDto[]>(lastSelectedCategories);

  const nearestOfflineCourses = useMemo(() => {
    const relevantCourses = [] as CourseDto[];

    Array.from(courseAmountInfo.categoriesAmountsMap).forEach(([category, courses], index) => {
      courses.forEach(course => {
        if (course.format === CourseFormat.offline && !!course.scheduleFrames?.at(0)?.classDates?.length) {
          relevantCourses.push(course);
        }
      })
    });

    return relevantCourses.sort((a, b) => {
      const {
        firstClassDate: firstClassDateA
      } = getClassDates(a.scheduleFrames?.at(0));

      const {
        firstClassDate: firstClassDateB
      } = getClassDates(b.scheduleFrames?.at(0));

      return (Number(firstClassDateA?.getTime() ?? 0)) - (Number(firstClassDateB?.getTime() ?? 0));
    });
  }, [courseAmountInfo]);

  const CoursesFilter = useMemo(() => (
    <Filter
      nearestOfflineCourses={nearestOfflineCourses}
      courseAmountInfo={courseAmountInfo}
      immediate={immediate}
    />
  ), [
    immediate,
    nearestOfflineCourses,
    courseAmountInfo
  ]);

  const resetData = useCallback(() => {
    setSelectedFormat(undefined);
    setSelectedCategories([]);
  }, []);

  const filtersAmount = useMemo(() => {
    const formatFiltersAmount = selectedFormat ? 1 : 0;
    let categoriesFiltersAmount = 0;

    Array.from(courseAmountInfo.categoriesAmountsMap).forEach(([category, courses], index) => {
      if (courses.find(course => (!selectedFormat || course.format === selectedFormat) && selectedCategories.includes(category))) {
        categoriesFiltersAmount++;
      }
    });

    if (selectedCategories.map(selectedCategory => selectedCategory.type).includes("nearest")) {
      categoriesFiltersAmount++;
    }

    return formatFiltersAmount + categoriesFiltersAmount;
  }, [courseAmountInfo.categoriesAmountsMap, selectedCategories, selectedFormat]);

  return {
    nearestOfflineCourses,
    selectedFormat,
    selectedCategories: selectedCategories,
    setSelectedFormat,
    setSelectedCategories: setSelectedCategories,
    CoursesFilter:
      <CourseFilterContext.Provider value={{
        selectedFormat,
        selectedCategories: selectedCategories,
        setSelectedFormat,
        setSelectedCategories: setSelectedCategories,
      }}>
        {CoursesFilter}
      </CourseFilterContext.Provider>,
    resetData,
    filtersAmount
  };
};
