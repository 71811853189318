import { useModal } from '../../hooks/use-modal';
import React, { useMemo } from 'react';
import { LocationModalContent } from './index';

export const useLocationModal = (
  onClick?: () => void
) => {
  const {
    Modal,
    showModal,
  } = useModal('location-modal-frame-id', onClick);

  const LocationModal = useMemo(() => (
    <Modal
      classList={['modalMain-wrapper--location']}
    >
      <LocationModalContent/>
    </Modal>
  ), [
    Modal,
  ]);

  return (
    {
      LocationModal,
      showModal
    }
  );
};
