import React, { useEffect } from "react";
import { Pages } from "../pages";
import { useDirectionType } from "../hooks/use-direction-type";
import useAxios from "axios-hooks";
import { getStylesUrl } from "../api/url";
import { StylesDto } from "../api/interfaces/styles/styles-dto";
import { colors } from "../constants/colors";

export const Styles: React.FC = () => {
  const directionType = useDirectionType();

  const [{
    data: styles
  }] = useAxios<StylesDto>({
    url: getStylesUrl(),
    params: {
      type: directionType
    }
  });

  useEffect(() => {
    if (styles) {
      document.querySelector("meta[name='theme-color']")?.setAttribute("content", styles.mainColor);

      document.documentElement.style.setProperty('--main-color', styles.mainColor);
      document.documentElement.style.setProperty('--online-color-text', styles.onlineColorText);
      document.documentElement.style.setProperty('--offline-color', styles.offlineColor);
      document.documentElement.style.setProperty('--offline-color-text', styles.offlineColorText);
      document.documentElement.style.setProperty('--main-offline-color', styles.mainOfflineColor);
      document.documentElement.style.setProperty('--tag-color', styles.tagColor);
      document.documentElement.style.setProperty('--darken-main-color', styles.darkenMainColor);
      document.documentElement.style.setProperty('--lighten-main-color', styles.lightenMainColor);
      document.documentElement.style.setProperty('--background-color', styles.backgroundColor);
      document.documentElement.style.setProperty('--background-gradient-color', styles.backgroundGradientColor);
      document.documentElement.style.setProperty('--main-text-color', styles.mainTextColor);
      document.documentElement.style.setProperty('--highlight-color', styles.highlightColor);
      document.documentElement.style.setProperty('--second-highlight-color', styles.secondHighlightColor);
      document.documentElement.style.setProperty('--light-color-high', styles.lightColorHigh);

      colors[`--brand-itmo-bluetiful`] = styles.mainColor;
      colors[`--brand-itmo-green-lizard`] = styles.highlightColor;
    }
  }, [styles]);

  return styles && (
    <Pages/>
  );
}
