import React, { useContext, useEffect, useMemo } from 'react';
import './index.css';
import { ifClassExists } from '../../../../utils/if-class-exists';
import { CourseAmountInfo } from '../index';
import { CourseFilterContext } from '../../../../context/course-filter-context';
import { CourseFormat, getCourseFormat, getCourseFormatName } from '../../../../api/interfaces/course/course-format';
import { CourseDto } from "../../../../api/interfaces/course/course-dto";
import { useSearchParams } from "react-router-dom";
import { DirectionContext } from "../../../../context/direction-context";
import { CategoryDto, nearestCategory } from "../../../../api/interfaces/category/category-dto";

interface FilterProps {
  courseAmountInfo: CourseAmountInfo;
  nearestOfflineCourses: CourseDto[];
  immediate: boolean;
}

export const Filter: React.FC<FilterProps> = (
  {
    courseAmountInfo,
    nearestOfflineCourses,
    immediate
  }
) => {
  const {
    selectedFormat,
    selectedCategories,

    setSelectedFormat,
    setSelectedCategories,
  } = useContext(CourseFilterContext);

  const [
    searchParams,
    setSearchParams
  ] = useSearchParams();

  const requestCourseFormat = useMemo(() => getCourseFormat(searchParams.get('format')), [
    searchParams
  ]);

  const {
    direction
  } = useContext(DirectionContext);

  const requestCourseCategories = useMemo(() => {
    return (searchParams.getAll('categories') as Array<string>)
      .map(category => [...(direction?.availableCategories ?? []), nearestCategory].find(availableCategory => availableCategory.type === category))
      .filter(category => !!category) as CategoryDto[];
  }, [
    direction?.availableCategories,
    searchParams
  ]);

  useEffect(() => {
    setSelectedFormat(requestCourseFormat);
  }, [
    setSelectedFormat,
    requestCourseFormat
  ]);

  useEffect(() => {
    setSelectedCategories(requestCourseCategories);
  }, [
    setSelectedCategories,
    requestCourseCategories
  ]);

  return (
    <div className='filter-area'>
      <div className='filter-format-area'>
        <div
          className={`filter-item ${ifClassExists('selected', !selectedFormat)}`}
          onClick={() => {
            if (immediate) {
              setSearchParams({});
            } else {
              setSelectedFormat(undefined);
              setSelectedCategories([]);
            }
          }}
        >
          {`Весь каталог (${Array.from(courseAmountInfo.formatAmountsMap).reduce((prev, [_, courses]) => prev + courses.length, 0)})`}
        </div>

        {Array.from(courseAmountInfo.formatAmountsMap).map(([format, courses], index) => (
          !!courses.length && <div
            key={index}
            className={`filter-item ${ifClassExists('selected', format === selectedFormat)}`}
            onClick={() => {
              if (immediate) {
                setSearchParams({
                  format,
                  categories: []
                });
              } else {
                setSelectedFormat(format);
                setSelectedCategories([]);
              }
            }}
          >
            {`${getCourseFormatName(format)} (${courses.length})`}
          </div>
        ))}
      </div>

      <div className='filter-category-divider'/>

      <div className='filter-category-area'>
        <>
          {selectedFormat === CourseFormat.offline && !!nearestOfflineCourses.length && (
            <>
              <div
                className={`filter-item ${ifClassExists('selected', !!selectedCategories.find(selectedCategory => selectedCategory.type === "nearest"))} nearest-offline-courses`}
                onClick={() => {
                  if (immediate) {
                    const format = requestCourseFormat?.toString() ?? "";
                    const categoriesObjects = selectedCategories.map(selectedCategory => selectedCategory.type).includes("nearest") ? [] : [nearestCategory];
                    const categories = categoriesObjects.map(categoriesObject => categoriesObject.type);

                    setSearchParams({
                      format,
                      categories
                    });
                  } else {
                    setSelectedCategories(selectedCategories => selectedCategories.map(selectedCategory => selectedCategory.type).includes("nearest") ? [] : [nearestCategory]);
                  }
                }}
              >
                {`Ближайшие (${nearestOfflineCourses.length})`}
              </div>

              <div className='filter-item-gap'>
                <div className='filter-item-gap-wrapper'></div>
              </div>
            </>
          )}

          {Array.from(courseAmountInfo.categoriesAmountsMap).map(([category, courses], index) => {
              const relevantCourses = courses.filter(course => selectedFormat ? course.format === selectedFormat : true);

              return !!relevantCourses.length && <div
                className={`filter-item ${ifClassExists('selected', !!selectedCategories.find(selectedCategory => selectedCategory.type === category.type))}`}
                onClick={() => {
                  if (immediate) {
                    const format = requestCourseFormat?.toString() ?? "";
                    const categoriesObjects = selectedCategories.map(selectedCategory => selectedCategory.type).includes(category.type) ? selectedCategories.filter(selectedCategory => selectedCategory.type !== category.type && selectedCategory.type !== "nearest") : [category, ...selectedCategories.filter(selectedCategory => selectedCategory.type !== "nearest")];
                    const categories = categoriesObjects.map(categoriesObject => categoriesObject.type);

                    if (format) {
                      setSearchParams({
                        format,
                        categories
                      });
                    } else {
                      setSearchParams({
                        categories
                      });
                    }
                  } else {
                    setSelectedCategories(selectedCategories => selectedCategories.map(selectedCategory => selectedCategory.type).includes(category.type) ? selectedCategories.filter(selectedCategory => selectedCategory.type !== category.type && selectedCategory.type !== "nearest") : [category, ...selectedCategories.filter(selectedCategory => selectedCategory.type !== "nearest")]);
                  }
                }}
              >
                {`${category.name} (${relevantCourses.length})`}
              </div>;
            }
          )}
        </>
      </div>
    </div>
  );
};
