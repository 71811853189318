import React, { useContext, useMemo } from 'react';
import './index.css';
import { ChildrenProps } from '../../../interfaces/children-props';
import { CourseContext } from '../../../context/course-context';
import { colors } from "../../../constants/colors";
import { useParams } from "react-router-dom";

export const Annotation: React.FC<ChildrenProps> = ({
  children
}) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const InfoIcon = (
    <svg className='accordion-annotation-left-icon' width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9" stroke="#00CCFF" strokeWidth="2" />
      <path
        d="M12.5 7.5C12.5 7.77614 12.2761 8 12 8C11.7239 8 11.5 7.77614 11.5 7.5C11.5 7.22386 11.7239 7 12 7C12.2761 7 12.5 7.22386 12.5 7.5Z"
        fill="#00CCFF" stroke="#00CCFF" />
      <path d="M12 17V10" stroke="#00CCFF" strokeWidth="2" />
    </svg>
  );

  const ArrowIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4782_31297)">
        <path d="M22.5 11.9993L16.8829 7.09021V11.1811H2.25V12.8175H16.8829V16.9084L22.5 11.9993Z"
          fill={`${colors['--brand-itmo-green-lizard']}`} />
      </g>
      <defs>
        <clipPath id="clip0_4782_31297">
          <rect width="20.25" height="9.81818" fill="white" transform="translate(2.25 7.091)" />
        </clipPath>
      </defs>
    </svg>
  );

  const {
    courseType,
    coursePath,
  } = useParams();

  const onlineCourseUrl = useMemo(() => `/courses/${courseType}/online/${coursePath}`, [
    courseType,
    coursePath,
  ]);
   
  let elvisLives = '';
  if(onlineCourseUrl == '/courses/plain/online/civil-3d-base'){
    elvisLives = "/courses/plain/online/civil-3d-infrastructure-model-development";
  }
  else if(onlineCourseUrl == '/courses/plain/online/projects-and-editing-in-adobe-premier-pro'){
    elvisLives = "/courses/plain/online/projects-and-editing-in-adobe-premiere-pro";
  } 
  else if(onlineCourseUrl == '/courses/plain/online/interiors-and-furniture-design-in-sketch-up'){
    elvisLives = "/courses/plain/online/modeling-furniture-and-interiors-in-sketch-up";
  } 
  else{
    elvisLives = onlineCourseUrl;
  } 

  

  return (
    <div className='accordion-annotation-area'>
      <div
        className='accordion-annotation'
        onClick={() => window.open(elvisLives, "_blank")}
      >
        <div className='accordion-annotation-left'>
          {InfoIcon}
          <div className='accordion-annotation-left-text'>Данный курс доступен в онлайн-формате</div>
        </div>

        <div className='accordion-annotation-right'>
          <div
            className='accordion-annotation-right-text'
          >
            Подробнее
          </div>
          {ArrowIcon}
        </div>
      </div>

      {children}
    </div>
  );
};
