export const toggleElementClass = (
  element: HTMLElement,
  token: string
) => {
  element.classList.toggle(token);
}

export const removeElementClass = (
  element: HTMLElement,
  token: string
) => {
  element.classList.remove(token);
}

export const addElementClass = (
  element: HTMLElement,
  token: string
) => {
  if (!document.body.classList.contains(token)) {
    element.classList.add(token);
  }
}
