import React from 'react';
import { useFadeAnimation } from "../../../hooks/animation/use-fade-animation";
import "./index.css";

interface SuccessStatusProps {
  type: "request" | "question"
}

export const SuccessStatus: React.FC<SuccessStatusProps> = (
  {
    type
  }
) => {
  useFadeAnimation(".success-status-wrapper");

  return (
    <div className='success-status-wrapper'>
      <div className='success-status'>
        <svg width="100px" height="100px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 13.689l.637-.636 2.863 2.674 7.022-6.87.637.637L10.5 17zM22.8 12.5A10.3 10.3 0 1 1 12.5 2.2a10.297 10.297 0 0 1 10.3 10.3zm-1 0a9.3 9.3 0 1 0-9.3 9.3 9.31 9.31 0 0 0 9.3-9.3z"/>
          <path fill="none" d="M0 0h24v24H0z"/>
        </svg>

        <div className="success-status-text-area">
          <div className='success-status-text'>
            {type === "request" && "Ваша заявка зарегистрирована!"}
            {type === "question" && "Ваш вопрос зарегистрирован!"}
          </div>
          <div className='success-status-text secondary'>
            Ответ займёт до 2-х рабочих дней
          </div>
        </div>
      </div>
    </div>
  );
};
