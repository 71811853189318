import React, { useContext, useMemo, useState } from 'react';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import './index.css';
import { AccordionList } from '../../components/accordion';
import { IdProps } from '../../interfaces/id-props';
import { ProgramState } from '../../interfaces/program-state';
import { CourseContext } from '../../context/course-context';
import { CourseType } from '../../api/interfaces/course/course-type';
import { DirectionContext } from "../../context/direction-context";
import { CourseFormat } from "../../api/interfaces/course/course-format";

export const FAQFrame: React.FC<IdProps> = (
  {
    id
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    direction
  } = useContext(DirectionContext);

  const relevantFaqFrame = useMemo(() => {
    switch (course?.type) {
      case CourseType.plain:
        switch (course.format) {
          case CourseFormat.online:
            return direction?.plainOnlineFaqFrame;
          case CourseFormat.offline:
            return direction?.plainOfflineFaqFrame;
        }
        break;
      case CourseType.refresher:
        switch (course.format) {
          case CourseFormat.online:
            return direction?.refresherOnlineFaqFrame;
          case CourseFormat.offline:
            return direction?.refresherOfflineFaqFrame;
        }
        break;
    }
  }, [
    course,
    direction
  ]);

  const [programState, setProgramState] = useState<ProgramState>({
    anyActive: false,
    programs: new Array(relevantFaqFrame?.faqItems.length ?? 0).fill(false)
  });

  const onProgramClick = (index: number) => {
    setProgramState((prevState: ProgramState) => {
      const newState = structuredClone(prevState);

      newState.programs[index - 1] = !newState.programs[index - 1];
      newState.anyActive = newState.programs.find((programState: boolean) => programState) ?? false;

      return newState;
    });
  }
  return useMemo(() => {
    switch (course!.type) {
      case CourseType.plain:
        return (
          <Frame
            id={id}
            bgClassName='outer-background-margin'
            coverClassName={`bg----main-offline-color program-frame faq-frame`}
          >
            <div className='faq-frame-wrapper'>
              <div className='faq-frame-row'>
                <BorderedTitle
                  titleClassList={['text-design-bim-true-white']}
                >
                  Часто задаваемые <span>вопросы</span>
                </BorderedTitle>
              </div>
              {
                <AccordionList
                  typeItem='FAQ'
                  numberBool={false}
                  programState={programState}
                  onProgramClick={onProgramClick}
                />
              }
            </div>
          </Frame>
        );
      case CourseType.refresher:
        if(course!.fullPath == 'courses/refresher/online/software-tester'){
          return false;
        }
        return (
          <Frame
            id={id}
            bgClassName='outer-background-margin bg----main-offline-color'
            sectionClassName={'padding-top-60 padding-bottom-60'}
            coverClassName={`program-frame faq-frame `}
          >
            <div className='faq-frame-wrapper'>
              <div className='faq-frame-row'>
                <BorderedTitle
                  titleClassList={['text-design-bim-true-white']}
                >
                  Часто задаваемые <span>вопросы</span>
                </BorderedTitle>
              </div>
              {
                <AccordionList
                  typeItem='FAQ'
                  numberBool={false}
                  programState={programState}
                  onProgramClick={onProgramClick}
                />
              }
            </div>
          </Frame>
        );
    }
  }, [course, id, programState]);
};
