import { createSlice } from '@reduxjs/toolkit';

export interface INavBarState {
  animated: boolean;
  modalBurgerVisible: boolean;
  allCoursesVisible: boolean;
}

const initialState: INavBarState = {
  animated: true,
  modalBurgerVisible: false,
  allCoursesVisible: false
};

export const navBarSlice = createSlice({
  name: 'navBar',
  initialState,
  reducers: {
    animatedOn: (state = initialState) => {
      state.animated = true;
    },
    animatedOff: (state = initialState) => {
      state.animated = false;
    },
    modalBurgerVisibleOn: (state = initialState) => {
      state.modalBurgerVisible = true;
    },
    modalBurgerVisibleOff: (state = initialState) => {
      state.modalBurgerVisible = false;
    },
    allCoursesVisibleOn: (state = initialState) => {
      state.allCoursesVisible = true;
    },
    allCoursesVisibleOff: (state = initialState) => {
      state.allCoursesVisible = false;
    },
  }
});

export const {
  animatedOn,
  animatedOff,
  modalBurgerVisibleOn,
  modalBurgerVisibleOff,
  allCoursesVisibleOn,
  allCoursesVisibleOff
} = navBarSlice.actions;

export const navBarReducer = navBarSlice.reducer;
