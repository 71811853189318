import React, { Dispatch } from 'react';
import './index.css';
import { Frame } from '../../../../components/frame';
import { QuoteArea } from '../../../introduction-frame/quote-area';
import { CommentArea } from '../../../introduction-frame/comment-area';
import { CourseDto } from '../../../../api/interfaces/course/course-dto';
import { CourseCard } from '../../../../components/course-card';
import parse from "html-react-parser";

interface InfoFavouriteCourseProps {
  id: string;
  favouriteCourse: CourseDto | undefined;
  setLoading: Dispatch<boolean>;
}

export const InfoFavouriteCourse: React.FC<InfoFavouriteCourseProps> = (
  {
    id,
    favouriteCourse,
    setLoading
  }
) => {
  const relevantClassName = `${favouriteCourse?.type.toLowerCase()} ${favouriteCourse?.format.toLowerCase()} catalog`;

  return (
    <div id="courses-frame-favourite-item-area">
      <div className='info-favourite-course-area'>
        <Frame
          id={id}
          sectionClassName={'no-padding-x'}
        >
          <div className='info-favourite-course-area-wrapper'>
            <div className='info-favourite-course-area-wrapper--section-1'>
              <div className='info-favourite-course-area-wrapper--section-1--title'>
                <div className='info-favourite-course-area-wrapper--section-1--title-main'>
                  {parse(favouriteCourse?.introductionFrame?.favouriteReview?.favouriteTitle ?? "")}
                </div>

                <QuoteArea
                  quoteFacts={favouriteCourse?.introductionFrame?.favouriteReview?.favouriteFacts}>
                </QuoteArea>
              </div>

              <div className='info-favourite-course-area-wrapper--section-1--comment'>
                <CommentArea
                  course={favouriteCourse}
                  forCatalog={true}
                />
              </div>
            </div>

            <div className='info-favourite-course-area-wrapper--section-2'>
              <CourseCard
                course={favouriteCourse}
                relevantClassName={relevantClassName}
                setLoading={setLoading}
              />
            </div>
          </div>
        </Frame>
      </div>
    </div>
  );
};
