import React from 'react';
import './index.css';

interface TagProps {
  text: string;
}

export const Tag: React.FC<TagProps> = ({
                                          text
                                        }) => {
  return (
    <div className='tag'>
      {text}
    </div>
  );
};
