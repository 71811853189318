import React, { HTMLAttributeAnchorTarget } from 'react';
import { ChildrenProps } from "../../interfaces/children-props";
import "./index.css";
import { Link, RelativeRoutingType } from "react-router-dom";

interface RelevantLinkProps extends ChildrenProps {
  to: string | undefined;
  relative: RelativeRoutingType;
  target?: HTMLAttributeAnchorTarget | undefined;
}

export const RelevantLink: React.FC<RelevantLinkProps> = (
  {
    to,
    relative,
    target,
    children,
  }
) => {
  return (
    <Link
      to={to ?? ""}
      target={target}
      relative={relative}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Link>
  );
};
