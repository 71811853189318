import React from 'react';
import './index.css';
import { ClassListProps } from '../../interfaces/class-list-props';
import { IdProps } from '../../interfaces/id-props';
import { ifClassExists } from '../../utils/if-class-exists';

interface ModalProps extends ClassListProps, IdProps {
  classList?: Array<string>;
  modalVisible: boolean;
  onClick: () => void;
  children: React.ReactNode;
  refs: {
    modalContainerRef: React.RefObject<HTMLDivElement>;
    modalMainWrapperRef: React.RefObject<HTMLDivElement>;
  };
}

export const ModalFrame: (
  props: Pick<ModalProps, 'modalVisible' | 'onClick' | 'refs' | 'id'>
) => React.FC<Pick<ModalProps, 'classList' | 'children'>> = (
  {
    id,
    modalVisible,
    onClick,
    refs: {
      modalContainerRef,
      modalMainWrapperRef
    },
  }
) =>
  ({
     classList = [],
     children
   }
  ) => {
    return (
      <div
        id={id}
        className={`modalMain ${ifClassExists('modalMain--active', modalVisible)}`}
        onClick={onClick}
      >
        <div
          ref={modalContainerRef}
          className='modal-container'
        >
          <div
            ref={modalMainWrapperRef}
            className={`modalMain-wrapper ${classList?.join(' ')}`}
            onClick={e => e.stopPropagation()}
          >
            <svg className='modal-close' onClick={onClick} width='24' height='24' viewBox='0 0 24 24'
                 fill='none'
                 xmlns='http://www.w3.org/2000/svg'>
              <path d='M21 3L3 21' stroke='#0D0D0D' strokeWidth='2' strokeLinecap='square'
                    strokeLinejoin='round'/>
              <path d='M3 3L21 21' stroke='#0D0D0D' strokeWidth='2' strokeLinecap='square'
                    strokeLinejoin='round'/>
            </svg>

            {children}
          </div>
        </div>
      </div>
    );
  };
