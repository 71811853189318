import React from 'react';
import './index.css';

import { CustomBodyAccordion } from './item-custom-body';
import { ItemAccordType } from './index';
import { ifClassExists } from '../../utils/if-class-exists';
import { ProgramItemDto } from "../../api/interfaces/frames/program/program-item-dto";
import { FaqItemDto } from "../../api/interfaces/frames/faq/faq-item-dto";

interface AccordionProps {
  index: number;
  typeItem: ItemAccordType;
  title: string;
  content: ProgramItemDto | FaqItemDto;
  numberBool: boolean;
  classList?: Array<string>;
  onProgramClick?: ((index: number) => void) | undefined;
  active?: boolean;
}

export const Accordion: React.FC<AccordionProps> = (
  {
    index,
    typeItem,
    title,
    content,
    numberBool,
    classList = [],
    onProgramClick,
    active
  }
) => {
  return (
    <div
      id={`accordion-item-${typeItem}-${index}`}
      className={`accordion-item ${classList?.join(' ')} ${classList?.join(' ')}`}
    >
      <div
        className={`accordion-title ${ifClassExists('accordion-title--active', active ?? false)} ${classList?.join(' ')}`}
        onClick={() => {
          if (!active) {
            document.getElementById(`accordion-item-${typeItem}-${index}`)?.scrollIntoView({ behavior: 'smooth' })
          }
          onProgramClick && onProgramClick(index);
        }}
      >
        <div className='accordion-title-block'>
          {numberBool && <span className='accordion-title-block__count'>{index}</span>}

          <div className='accordion-title-block__title'>{title}</div>
        </div>

        <div className='accordion-title-symbol'>
          {active ?
            <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle cx='28' cy='28' r='27.25' transform='matrix(-4.37114e-08 -1 -1 4.37114e-08 56 56)'
                      stroke='white' strokeWidth='1.5'/>
              <path d='M38.5 28L17.5 28' stroke='white' strokeWidth='1.5' strokeLinecap='square'/>
            </svg>
            : <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle cx='28' cy='28' r='27.25' transform='matrix(-4.37114e-08 -1 -1 4.37114e-08 56 56)'
                      stroke='#0D0D0D' strokeWidth='1.5' fill="#fff"/>
              <path d='M28 17.5L28 38.5' stroke='#0D0D0D' strokeWidth='1.5' strokeLinecap='square'/>
              <path d='M38.5 28L17.5 28' stroke='#0D0D0D' strokeWidth='1.5' strokeLinecap='square'/>
            </svg>
          }
        </div>
      </div>

      {active &&
        <CustomBodyAccordion
          typeItem={typeItem}
          content={content}
          index={index}
        />
      }
    </div>
  );
}
