import React, { useContext } from "react";
import "./index.css";
import { NavBar } from "../../../components/nav-bar";
import { IntroductionFrame } from "../../../frames/introduction-frame";
import { NavStatus } from "../../../components/nav-bar/nav-status";
import { navBarStore } from "../../../components/nav-bar/store/store";
import { Provider } from "react-redux";
import { ModalContext } from "../../../context/modal-context";
import { TargetsFrame } from "../../../frames/targets-frame";
import { GuaranteesFrame } from "../../../frames/guarantees-frame";
import { PriceFrame } from "../../../frames/price-frame";
import { ReviewFrame } from "../../../frames/review-frame";
import { FAQFrame } from "../../../frames/faq-frame";
import { FooterFrame } from "../../../frames/footer-frame";
import { EducationStagesFrame } from '../../../frames/education-stages-frame';
import { OtherCoursesFrame } from '../../../frames/other-courses-frame';
import { ContactsFrame } from '../../../frames/contacts-frame';
import { ScrollUpButton } from '../../../components/scroll-up-button';
import { EducationProcessFrame } from "../../../frames/education-process-frame";
import { BottomInfoZone } from '../../../components/bottom-info-zone';
import { TeachersFrame } from '../../../frames/teachers-frame';
import { ComposedProgramFrame } from '../../../frames/composed-program-frame';
import { getFileUrl } from '../../../api/url';
import { CourseContext } from '../../../context/course-context';
import { SignupModalWrapper } from '../../../modals/wrappers/signup-modal-wrapper';
import { AskQuestionModalWrapper } from '../../../modals/wrappers/ask-question-modal-wrapper';
import { DocumentModalWrapper } from '../../../modals/wrappers/document-modal-wrapper';
import { VideoModalWrapper } from '../../../modals/wrappers/video-modal-wrapper';
import { WorkModalWrapper } from '../../../modals/wrappers/work-modal-wrapper';
import { FeedbackModalWrapper } from '../../../modals/wrappers/feedback-modal-wrapper';
import { useScrollToSection } from "../../../hooks/use-scroll-to-section";
import { usePageTitle } from "../../../hooks/use-page-title";
import { AuthorFrame } from "../../../frames/author-frame";
import Shedile_block from "../../../components/Shedule_block/Shedule";
import Block_action_april from "../../../components/Block_action_april/Block_action_april";
import Block_freeze_price from "../../../components/Block_freeze_price/Block_freeze_price";

export const RefresherCourse: React.FC = () => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  usePageTitle(course, undefined);

  const modalContextValue = useContext(ModalContext);

  useScrollToSection();

  // console.log('test refresher - ');
  // console.log(course?.directionType);
  // console.log(course);

  let arrFrize = { 
    "bim": ['8 июля 2024', '#191760', '#D0FF14', 'https://bim.itmo.ru/catalog'],
    "cad": ['05 августа 2024', '#221802', '#FFCC33', 'https://cad.itmo.ru/catalog'],
    "code": ['4 июля 2024', '#191760', '#D0FF14', 'https://code.itmo.ru/catalog'],
    "3d": ['4 июля 2024', '#270A35', '#FFFF33', 'https://3d.itmo.ru/catalog'],
    "md": ['4 июля 2024', '#191760', '#D0FF14', 'https://md.itmo.ru/catalog'],
  };
  const dir = course?.directionType;
  const typePage = 'page';

  return course && (
    <div className="refresher-course">
      <NavStatus />

      <Provider store={navBarStore}>
        <NavBar
          className={`${course.type.toLowerCase()} ${course.format.toLowerCase()}`}
          navBar={course.navBar}
        />
      </Provider>

      <div
        className="refresher-course-bg--first"
        style={{ backgroundImage: `url(${getFileUrl(course.introductionFrame?.backgroundId ?? "")})` }}
      >
        <IntroductionFrame id="introduction-frame" />
      </div>

      {course.id == 'b13aff85-76bf-406d-ba9e-9553bd31a9b9' ? <Shedile_block /> : null}
      
      {/* {dir === 'cad' ? <Block_freeze_price type={typePage} dir={dir} styleType={'refresher'} dateEnd={arrFrize[dir][0]} backColor={arrFrize[dir][1]} mainColor={arrFrize[dir][2]} linkPage={arrFrize[dir][3]} /> : null} */}
      
      {/* {course.id == 'b13aff85-76bf-406d-ba9e-9553bd31a9b9' ? null : <Block_action_april type='refresher' />} */}

      <GuaranteesFrame id="guarantees-frame" />

      <TargetsFrame id="targets-frame" />

      <EducationProcessFrame id="education-process-frame" />

      <TeachersFrame id="teachers-frame" />

      <AuthorFrame id="teachers-frame" />

      <ComposedProgramFrame id="composed-program-frame" />

      <PriceFrame id="price-frame" />

      <ReviewFrame id="review-frame" />

      <EducationStagesFrame id='education-stages-frame' />

      <FAQFrame id="faq-frame" />

      <OtherCoursesFrame id='other-courses-frame' />

      <ContactsFrame id='contacts-frame' />

      <FooterFrame />

      <ScrollUpButton />

      <BottomInfoZone />

      <SignupModalWrapper ref={modalContextValue.signupModalWrapperRef} />
      <AskQuestionModalWrapper ref={modalContextValue.askQuestionModalWrapperRef} />
      <VideoModalWrapper ref={modalContextValue.videoModalWrapperRef} />
      <DocumentModalWrapper ref={modalContextValue.documentModalWrapperRef} />
      <FeedbackModalWrapper ref={modalContextValue.feedbackModalWrapperRef} />
      <WorkModalWrapper ref={modalContextValue.workModalWrapperRef} />
    </div>
  );
};
