import React, { useContext } from 'react';
import './index.css';
import { VkLink } from './svg/vk-link';
import { getFileUrl } from "../../api/url";
import { DirectionContext } from "../../context/direction-context";

export const FooterFrame: React.FC = () => {
  const {
    direction
  } = useContext(DirectionContext);

  return (
    <footer className="outer-background-margin footer">
      <div className='footer-wrapper-1'>
        <div className='fw-area-1'>
          <div className='fw-credits'>
            <img
              className="fw-credits-logo"
              src={getFileUrl(direction?.styles.navLogoSecondaryImageId)}
              alt=""
            />
            <div className='fw-credits-description'>
              © 2024
            </div>
          </div>

          <VkLink/>
        </div>
      </div>

      <div className='footer-wrapper-2'>
        <div className='fw-area-2'>
          <a
            href="https://itmo.ru/ru/page/217/licenziya_i_akkreditaciya.htm"
            className='fw-license-info'
            target="_blank"
            rel="noreferrer"
          >
            Лицензия на осуществление образовательной деятельности регистрационный №2923 от 09 сентября 2020
            года Серия 90Л01 №0010051
          </a>

          <div className='fw-rules-info'>
            <a
              href="https://itmo.ru/images/pages/79/Pravila_ispolzovanija_informacii.pdf"
              className='fw-ri-1'
              target="_blank"
              rel="noreferrer"
            >
              <span>Правила использования информации в доменной зоне itmo.ru</span>
            </a>

            <a
              href="https://itmo.ru/file/pages/79/personal_data_policy.pdf"
              className='fw-ri-2'
              target="_blank"
              rel="noreferrer"
            >
              <span>Политика по обработке Персональных данных</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
