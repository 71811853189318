import { useEffect } from "react";
import { DirectionDto } from "../api/interfaces/direction/direction-dto";
import { CourseDto } from "../api/interfaces/course/course-dto";

export const usePageTitle = (
  course: CourseDto | undefined,
  direction: DirectionDto | undefined
) => {
  useEffect(() => {
    if (course) {
      if (course.meta) {
        document.title = course.meta.title;
        document.querySelector('meta[name="description"]')?.setAttribute("content", course.meta.description);
        document.querySelector('meta[property="og:title"]')?.setAttribute("content", course.meta.title);
        document.querySelector('meta[property="og:description"]')?.setAttribute("content", course.meta.description);
      }
    } else if (direction) {
      if (direction.meta) {
        document.title = direction.meta.title;
        document.querySelector('meta[name="description"]')?.setAttribute("content", direction.meta.description);
        document.querySelector('meta[property="og:title"]')?.setAttribute("content", direction.meta.title);
        document.querySelector('meta[property="og:description"]')?.setAttribute("content", direction.meta.description);
      }
    }
  }, [
    course,
    direction
  ]);
}
