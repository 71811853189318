import React from 'react';
import { Rating } from '../rating';
import { Arrow } from '../../../components/arrow';
import { colors } from '../../../constants/colors';
import { CourseDto } from '../../../api/interfaces/course/course-dto';
import { GenderType } from "../../../api/interfaces/frames/review/gender-type";
import male from "../../review-frame/avatar/male.png";
import female from "../../review-frame/avatar/female.png";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

interface CommentAreaProps {
  course: CourseDto | undefined;
  forCatalog: boolean;
}

export const CommentArea: React.FC<CommentAreaProps> = (
  {
    course,
    forCatalog
  }
) => {
  return course && (
    <div className="refresher-secondScreen-comment-area">
      <svg className="refresher-secondScreen-comment--quote" xmlns="http://www.w3.org/2000/svg" width="101"
           height="71" viewBox="0 0 101 71" fill="none">
        <path
          d="M47.5398 0.919922C36.2598 13.6399 26.8998 25.8799 21.8598 35.7199C20.8998 37.6399 21.1398 38.5999 22.3398 38.5999C26.1798 38.5999 29.7798 39.3199 32.4198 40.5199C37.2198 42.9199 39.8598 47.2399 39.8598 54.1999C39.8598 64.0399 32.6598 70.2799 20.6598 70.2799C6.73977 70.2799 0.259766 62.5999 0.259766 49.3999C0.259766 30.6799 14.1798 14.1199 28.0998 0.919922H47.5398ZM100.1 0.919922C88.8198 13.6399 79.4598 25.8799 74.4198 35.7199C73.4598 37.6399 73.6998 38.5999 74.8998 38.5999C78.7398 38.5999 82.3398 39.3199 84.9798 40.5199C89.7798 42.9199 92.4198 47.2399 92.4198 54.1999C92.4198 64.0399 85.4598 70.2799 73.4598 70.2799C59.2998 70.2799 52.8198 62.5999 52.8198 49.3999C52.8198 30.6799 66.7398 14.1199 80.6598 0.919922H100.1Z"
          fill="#00CCFF"/>
      </svg>
      <div className='refresher-secondScreen-comment'>
        <div className='refresher-commentBlock'>
          <Tippy
            className='teacher-tooltip-wrapper'
            content={
              <div className='teacher-tooltip'>
                <div className='teacher-tooltip-info'>
                  {course!.introductionFrame?.favouriteReview?.text}
                </div>
              </div>
            }
          >
            <p className='refresher-commentBlock__text'>
              {course!.introductionFrame?.favouriteReview?.text}
            </p>
          </Tippy>
          <div className='refresher-commentBlock-author'>
            <img
              src={course.introductionFrame?.favouriteReview?.gender === GenderType.male ? male : female}
              className='refresher-commentBlock-author__img'
              alt={course!.introductionFrame?.favouriteReview?.imageId}
            />

            <div className='refresher-commentBlock-author-block'>
              {course!.introductionFrame?.favouriteReview?.author}
            </div>
          </div>
        </div>

        <div className='refresher-commentInfo'>
          <div className='refresher-commentInfo-row'>
            <div className='refresher-commentInfo-row-item'>
              <div className='refresher-comment-Yandex'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='23'
                  viewBox='0 0 22 23'
                  fill='none'
                >
                  <path
                    d='M10.7987 1.59692C9.19727 1.59777 7.632 2.07319 6.30062 2.96315C4.96923 3.85311 3.93146 5.11767 3.31838 6.59711C2.7053 8.07655 2.54442 9.7045 2.85606 11.2753C3.1677 12.8461 3.93787 14.2894 5.06931 15.4227C6.53631 16.8897 9.98871 19.0119 10.193 21.2412C10.2236 21.5742 10.4657 21.8469 10.7987 21.8469C11.1317 21.8469 11.3747 21.5742 11.4044 21.2412C11.6087 19.0119 15.0584 16.8933 16.5245 15.4272C17.6573 14.2943 18.4287 12.851 18.7414 11.2798C19.054 9.70853 18.8938 8.07985 18.281 6.59964C17.6682 5.11942 16.6303 3.85411 15.2985 2.96366C13.9667 2.07321 12.4008 1.59759 10.7987 1.59692ZM10.7987 12.468C10.4348 12.468 10.0745 12.3963 9.73825 12.2571C9.40205 12.1178 9.09656 11.9137 8.83924 11.6564C8.58192 11.3991 8.3778 11.0936 8.23854 10.7574C8.09928 10.4212 8.02761 10.0608 8.02761 9.69692C8.02761 9.33302 8.09928 8.97267 8.23854 8.63647C8.3778 8.30026 8.58192 7.99478 8.83924 7.73746C9.09656 7.48014 9.40205 7.27602 9.73825 7.13676C10.0745 6.9975 10.4348 6.92582 10.7987 6.92582C11.5336 6.92582 12.2385 7.21778 12.7582 7.73746C13.2779 8.25714 13.5698 8.96198 13.5698 9.69692C13.5698 10.4319 13.2779 11.1367 12.7582 11.6564C12.2385 12.1761 11.5336 12.468 10.7987 12.468Z'
                    fill='#FF3333'
                  />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='53'
                  height='17'
                  viewBox='0 0 53 17'
                  fill='none'
                >
                  <g clipPath='url(#clip0_3956_24691)'>
                    <path
                      d='M36.4756 13.5876V11.8416C35.8096 12.2916 34.6936 12.6876 33.6496 12.6876C32.0836 12.6876 31.4896 11.9496 31.3996 10.4376H36.5656V9.30358C36.5656 6.15358 35.1796 4.96558 33.0376 4.96558C30.4276 4.96558 29.1856 6.96358 29.1856 9.69958C29.1856 12.8496 30.7336 14.3796 33.4696 14.3796C34.8376 14.3796 35.8456 14.0196 36.4756 13.5876ZM49.8676 14.3796C50.8576 14.3796 51.5596 14.1996 52.0816 13.8216V12.0756C51.5416 12.4536 50.8936 12.6876 49.9936 12.6876C48.4636 12.6876 47.8336 11.4996 47.8336 9.62758C47.8336 7.66558 48.6076 6.65758 50.0116 6.65758C50.8396 6.65758 51.6496 6.94558 52.0816 7.21558V5.39758C51.6316 5.14558 50.8396 4.96558 49.7776 4.96558C47.0416 4.96558 45.6196 6.92758 45.6196 9.68158C45.6196 12.7056 47.0056 14.3796 49.8676 14.3796ZM16.8556 5.14558V8.72758H13.9936V5.14558H11.8516V14.1996H13.9936V10.4196H16.8556V14.1996H18.9976V5.14558H16.8556ZM28.6636 12.5076H27.7096V5.14558H21.4636V5.91958C21.4636 8.13358 21.3196 10.9956 20.5636 12.5076H19.8976V16.2696H21.8776V14.1996H26.6836V16.2696H28.6636V12.5076ZM43.3156 14.1996H45.7456L42.3076 9.32158L45.3316 5.14558H43.1716L40.1476 9.32158V5.14558H38.0056V14.1996H40.1476V9.75358L43.3156 14.1996ZM33.0016 6.65758C34.0636 6.65758 34.3876 7.53958 34.3876 8.67358V8.85358H31.3996C31.4536 7.41358 31.9756 6.65758 33.0016 6.65758ZM25.5676 12.5076H22.5436C23.1376 11.1396 23.2996 8.67358 23.2996 7.10758V6.83758H25.5676V12.5076Z'
                      fill='black'
                    />
                    <path
                      d='M10.0523 14.2007H7.87433V3.38267H6.90233C5.12033 3.38267 4.18433 4.28267 4.18433 5.61467C4.18433 7.12667 4.83233 7.82867 6.16433 8.72867L7.26233 9.46667L4.09433 14.2007H1.73633L4.58033 9.97067C2.94233 8.80067 2.02433 7.66667 2.02433 5.74067C2.02433 3.32867 3.69833 1.69067 6.88433 1.69067H10.0523V14.2007Z'
                      fill='black'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_3956_24691'>
                      <rect
                        width='51.6'
                        height='15.6'
                        fill='white'
                        transform='translate(0.799805 0.699951)'
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <Rating value={5.0}/>
            </div>
            <div className='refresher-commentInfo-row-item'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='66'
                height='23'
                viewBox='0 0 66 23'
                fill='none'
              >
                <g clipPath='url(#clip0_3956_24700)'>
                  <path
                    d='M8.2559 8.46051V10.7266H13.5868C13.4453 11.9802 13.0207 12.9444 12.3603 13.5712C11.6054 14.3427 10.3788 15.2105 8.2559 15.2105C5.00072 15.2105 2.45318 12.5105 2.45318 9.18373C2.45318 5.85694 4.95354 3.15694 8.2559 3.15694C10.0014 3.15694 11.3224 3.88016 12.2659 4.79623L13.8227 3.20516C12.5018 1.90337 10.7091 0.890869 8.2559 0.890869C3.77412 0.890869 0 4.60337 0 9.18373C0 13.7641 3.77412 17.4766 8.2559 17.4766C10.6619 17.4766 12.5018 16.6569 13.9171 15.1623C15.3796 13.6677 15.8513 11.5462 15.8513 9.85873C15.8513 9.32837 15.8041 8.84623 15.757 8.46051H8.2559Z'
                    fill='#4285F4'
                  />
                  <path
                    d='M22.4556 6.58179C19.5779 6.58179 17.1719 8.84786 17.1719 11.9336C17.1719 15.0193 19.5307 17.2854 22.4556 17.2854C25.3806 17.2854 27.7394 15.0675 27.7394 11.9818C27.7394 8.84786 25.3806 6.58179 22.4556 6.58179ZM22.4556 15.2121C20.8516 15.2121 19.4835 13.8621 19.4835 11.9818C19.4835 10.0532 20.8516 8.75143 22.4556 8.75143C24.0597 8.75143 25.4278 10.0532 25.4278 11.9818C25.4278 13.8621 24.0597 15.2121 22.4556 15.2121Z'
                    fill='#EA4335'
                  />
                  <path
                    d='M48.2617 7.78714H48.1673C47.6484 7.16036 46.6577 6.58179 45.3839 6.58179C42.742 6.58179 40.4775 8.89607 40.4775 11.9336C40.4775 14.9229 42.742 17.2854 45.3839 17.2854C46.6577 17.2854 47.6484 16.7068 48.1673 16.08H48.2617V16.8514C48.2617 18.8764 47.1766 19.9854 45.4783 19.9854C44.063 19.9854 43.2138 18.9729 42.8364 18.0568L40.8549 18.9246C41.4211 20.3229 42.9779 22.1068 45.5254 22.1068C48.2145 22.1068 50.5261 20.4675 50.5261 16.5139V6.87107H48.356V7.78714H48.2617ZM45.6198 15.2121C44.0158 15.2121 42.8364 13.8139 42.8364 11.9818C42.8364 10.1014 44.063 8.75143 45.6198 8.75143C47.1766 8.75143 48.4032 10.1496 48.4032 12.03C48.4504 13.8621 47.2238 15.2121 45.6198 15.2121Z'
                    fill='#4285F4'
                  />
                  <path
                    d='M34.2037 6.58179C31.3259 6.58179 28.9199 8.84786 28.9199 11.9336C28.9199 15.0193 31.2788 17.2854 34.2037 17.2854C37.1286 17.2854 39.4875 15.0675 39.4875 11.9818C39.4875 8.84786 37.1286 6.58179 34.2037 6.58179ZM34.2037 15.2121C32.5997 15.2121 31.2316 13.8621 31.2316 11.9818C31.2316 10.0532 32.5997 8.75143 34.2037 8.75143C35.8077 8.75143 37.1758 10.0532 37.1758 11.9818C37.1758 13.8621 35.8077 15.2121 34.2037 15.2121Z'
                    fill='#FBBC05'
                  />
                  <path d='M52.2715 1.13354H54.536V17.3335H52.2715V1.13354Z' fill='#34A853'/>
                  <path
                    d='M61.5185 15.2121C60.3391 15.2121 59.5371 14.6818 58.971 13.5729L66.0003 10.6318L65.7644 10.005C65.3398 8.79964 63.9717 6.58179 61.2826 6.58179C58.5936 6.58179 56.3291 8.75143 56.3291 11.9336C56.3291 14.9229 58.5464 17.2854 61.5185 17.2854C63.9245 17.2854 65.2926 15.7907 65.9059 14.9229L64.1132 13.7175C63.4999 14.6336 62.6979 15.2121 61.5185 15.2121ZM61.377 8.60679C62.3205 8.60679 63.1225 9.08893 63.3584 9.76393L58.6408 11.7889C58.5936 9.57107 60.1976 8.60679 61.377 8.60679Z'
                    fill='#EA4335'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_3956_24700'>
                    <rect
                      width='66'
                      height='21.2143'
                      fill='white'
                      transform='translate(0 0.892822)'
                    />
                  </clipPath>
                </defs>
              </svg>
              <Rating value={5.0}/>
            </div>
          </div>

          {!forCatalog && <div className='refresher-commentInfo__moreCom'>
          <span
            onClick={() => document.getElementById('review-frame')?.scrollIntoView({ behavior: 'smooth' })}
          >
            Больше отзывов
          </span>
            <Arrow type='arrow-1' color={colors['--brand-itmo-bluetiful']}/>
          </div>}
        </div>
      </div>
    </div>
  );
};
