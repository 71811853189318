import { createContext, Dispatch, SetStateAction } from 'react';
import { CourseFormat } from '../api/interfaces/course/course-format';
import { CategoryDto } from "../api/interfaces/category/category-dto";

export interface CourseFilterContextProps {
  selectedFormat: CourseFormat | undefined;
  selectedCategories: CategoryDto[];

  setSelectedFormat: Dispatch<SetStateAction<CourseFormat | undefined>>;
  setSelectedCategories: Dispatch<SetStateAction<CategoryDto[]>>;
}

export const CourseFilterContext = createContext({} as CourseFilterContextProps);
