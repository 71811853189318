import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import { PageType, usePageType } from '../../hooks/use-page-type';
import { ifClassExists } from '../../utils/if-class-exists';
import { ModalContext } from '../../context/modal-context';
import { CoursesFilter } from '../../frames/direction/courses-frame/filter/use-courses-filter';
import { CourseAmountInfo } from "../../frames/direction/courses-frame";

interface FilterButtonProps {
  coursesFilter: CoursesFilter;
  courseAmountInfo: CourseAmountInfo;
}

export const FilterButton: React.FC<FilterButtonProps> = (
  {
    coursesFilter,
    courseAmountInfo
  }
) => {
  const pageType = usePageType();

  const filterButtonRef = useRef({} as HTMLDivElement);
  const initialScrollY = useRef(0);

  const {
    showProperFilterStateModal
  } = useContext(ModalContext);

  const [
    timerActive,
    setTimerActive
  ] = useState(true);

  useEffect(() => {
    const scrollListener = () => {
      if (pageType === PageType.COURSE) {
        if (window.userManualScroll) {
          const currentScroll = window.scrollY;

          if (currentScroll >= 0 && currentScroll <= document.documentElement.scrollHeight) {
            if (currentScroll - initialScrollY.current > 20) {
              initialScrollY.current = currentScroll;
              if (filterButtonRef.current) {
                filterButtonRef.current.classList.add('move');
              }
            }

            if (initialScrollY.current - currentScroll > 20) {
              initialScrollY.current = currentScroll;

              if (filterButtonRef.current) {
                filterButtonRef.current.classList.remove('move');
              }
            }
          }
        }
      }

      setTimerActive(true);
    };

    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, [pageType]);

  useEffect(() => {
    if (timerActive) {
      setTimeout(() => {
        if (filterButtonRef.current && filterButtonRef.current.classList) {
          filterButtonRef.current.classList.add('shown');
        }
      }, 1000);

      // setTimeout(() => setTimerActive(false), 3000);
    }
    // else {
    //   filterButtonRef.current.classList.remove('shown');
    // }
  }, [timerActive]);

  // console.log(coursesFilter)

  return (
    <div
      ref={filterButtonRef}
      className={`filter-button ${ifClassExists('catalog', pageType === PageType.DIRECTION)}`}
      onClick={() => {
        showProperFilterStateModal({
          coursesFilter
        })
      }}
    >
      <svg className={ifClassExists('filter-button-image', !!coursesFilter.filtersAmount)}
           width="24"
           height="24" viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M11 8L20 8" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M4 16L14 16" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round"/>
        <ellipse cx="7" cy="8" rx="3" ry="3" transform="rotate(90 7 8)" stroke="#0D0D0D" strokeWidth="1.5"
                 strokeLinecap="round"/>
        <ellipse cx="17" cy="16" rx="3" ry="3" transform="rotate(90 17 16)" stroke="#0D0D0D" strokeWidth="1.5"
                 strokeLinecap="round"/>
      </svg>

      {!!coursesFilter.filtersAmount &&
        <div className='filter-button-text'>
          Фильтры
          <span>({coursesFilter.filtersAmount})</span>
        </div>}
    </div>
  );
};
