import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigationType } from 'react-router-dom';
import { CoursesCatalog } from './catalog';
import { Course } from './course';
import { DirectionContext } from '../context/direction-context';
import { ModalContext } from '../context/modal-context';
import { CourseContext, CourseData } from '../context/course-context';
import { DirectionDto } from '../api/interfaces/direction/direction-dto';
import { useModalRefs } from '../modals/use-modal-refs';
import { SplashScreen } from "../components/splash-screen";
import { PageType } from "../hooks/use-page-type";
import { SplashScreenContext } from "../context/splash-screen-context";
import { Action } from "@remix-run/router";
import { useDirectionType } from "../hooks/use-direction-type";
import { NotFoundPage } from "./not-found";

export const Pages: React.FC = () => {
  const [direction, setDirection] = useState<DirectionDto | undefined>(undefined);
  const [courseData, setCourseData] = useState<CourseData | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const modalRefs = useModalRefs();

  const splashScreenRef = useRef({} as HTMLDivElement);

  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === Action.Pop) {
      setLoading(true);
    }
  }, [navigationType]);

  const directionType = useDirectionType();

  return (
    <>
      <ModalContext.Provider value={
        modalRefs
      }>
        <DirectionContext.Provider value={{
          direction,
          setDirection,
          directionType,
        }}>
          <CourseContext.Provider value={{
            courseData,
            setCourseData
          }}>
            <SplashScreenContext.Provider value={{
              loading,
              setLoading,
              splashScreenRef
            }}>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/catalog" replace />}
                />

                <Route
                  path='/catalog'
                  element={
                    <>
                      <SplashScreen
                        splashScreenRef={splashScreenRef}
                        splashType={PageType.DIRECTION}
                      />

                      {!loading && <CoursesCatalog />}
                    </>
                  }
                />

                <Route
                  path='/courses/:courseType/:courseFormat/:coursePath'
                  element={
                    <>
                      <SplashScreen
                        splashScreenRef={splashScreenRef}
                        splashType={PageType.COURSE}
                      />

                      {!loading && <Course />}
                    </>
                  }
                />

                <Route
                  path='*'
                  element={
                    <>
                      <SplashScreen
                        splashScreenRef={splashScreenRef}
                        splashType={PageType.DIRECTION}
                      />

                      {!loading && <NotFoundPage />}
                    </>
                  }
                />
              </Routes>
            </SplashScreenContext.Provider>
          </CourseContext.Provider>
        </DirectionContext.Provider>
      </ModalContext.Provider>
    </>
  );
};
