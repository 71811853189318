import React, { useEffect, useRef } from 'react';
import './index.css';

export const NavStatus: React.FC = () => {
  const navStatusRef = useRef({} as HTMLDivElement);

  useEffect(() => {
    window.addEventListener('scroll', _ => {
      const screenHeight = document.body.scrollHeight - 900;
      const scrollY = window.scrollY;

      if (navStatusRef.current) {
        navStatusRef.current.style.width = `${Math.trunc(scrollY / screenHeight * 100)}%`;
      }
    })
  }, []);
 
  return (
    <div
      ref={navStatusRef}
      className='nav-status'
    />

  );
};
