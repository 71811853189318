import React, { useCallback, useContext, useMemo, useState } from 'react';
import parse from 'html-react-parser';
import { ifClassExists } from '../../utils/if-class-exists';
import { SwipingWorks } from './swiping-works';
import { ModuleExtra } from './module-extra';
import { ModalContext } from '../../context/modal-context';
import { colors } from "../../constants/colors";
import { RelevantLink } from "../../components/relevant-link";
import { useDirectionType } from "../../hooks/use-direction-type";
import { ComposedProgramModuleDto } from "../../api/interfaces/frames/composedProgram/composed-program-module-dto";

const Info = (
  <svg className='no-shrink' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'
    fill='none'>
    <circle cx='12' cy='12' r='9' stroke='white' strokeOpacity='0.25' strokeWidth='2' />
    <path
      d='M12.5 7.5C12.5 7.77614 12.2761 8 12 8C11.7239 8 11.5 7.77614 11.5 7.5C11.5 7.22386 11.7239 7 12 7C12.2761 7 12.5 7.22386 12.5 7.5Z'
      fill='white' fillOpacity='0.25' stroke='white' strokeOpacity='0.25' />
    <path d='M12 17V10' stroke='white' strokeOpacity='0.25' strokeWidth='2' />
  </svg>
);

const Plus = (
  <svg className='no-shrink' width='18' height='24' viewBox='0 0 24 24' fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M12 1.5L12 22.5' stroke='white' strokeWidth='1.5' strokeLinecap='square' />
    <path d='M22.5 12L1.5 12' stroke='white' strokeWidth='1.5' strokeLinecap='square' />
  </svg>
);

const Minus = (
  <svg className='no-shrink' width='24' height='24' viewBox='0 0 24 2' fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M22.5 1L1.5 0.999999' stroke='white' strokeWidth='1.5' strokeLinecap='square' />
  </svg>
);

const Arrow = (
  <svg className='no-shrink' xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16'
    fill='none'>
    <path fillRule='evenodd' clipRule='evenodd'
      d='M19.1357 7.19334L19.0728 7.19363C18.0193 7.1852 16.554 6.92163 15.3687 6.01375C14.2183 5.13265 13.2261 3.56472 13.2261 0.714844H11.7261C11.7261 3.95317 12.8774 5.98948 14.4451 7.1958H0.863281V8.68896H14.5521C14.5258 8.70884 14.4996 8.72897 14.4735 8.74934C12.8966 9.97934 11.7148 12.0385 11.7148 15.2844H13.2148C13.2148 12.4457 14.2248 10.8457 15.3961 9.93207C16.6006 8.99251 18.0728 8.70468 19.0762 8.69367L19.1223 8.69383L19.1223 8.69341L19.1357 8.69334V8.68896V7.1958V7.19334Z'
      fill='#00CCFF' />
  </svg>
);

interface ModuleProps {
  composedProgramModule: ComposedProgramModuleDto | undefined;
  index: number;
  id: string | undefined;
  className: string | undefined;
  isIntroductionModule: boolean;
  hasIntroductionModule: boolean;
}

export const Module: React.FC<ModuleProps> = (
  {
    id,
    composedProgramModule,
    index,
    className,
    isIntroductionModule,
    hasIntroductionModule
  }
) => {
  const [
    modulesActive,
    setModulesActive
  ] = useState(new Array(composedProgramModule?.course?.programFrame?.program.programItems.length ?? 0).fill(false) as boolean[]);

  const toggleModule = (index: number) => {
    setModulesActive(prev => prev.map((prevItem, prevItemIndex) =>
      (index !== prevItemIndex)
        ? prevItem
        : !prevItem
    ));
  };

  const anyActive = useMemo(() => modulesActive.find(module => module), [modulesActive]);

  const expandAll = useCallback(() => {
    setModulesActive(prev => prev.map(() => true));
  }, []);

  const collapseAll = useCallback(() => {
    setModulesActive(prev => prev.map(() => false));
  }, []);

  const {
    showProperVideoStateModal
  } = useContext(ModalContext);

  const ArrowDown = useMemo(() => (
    <svg className='no-shrink' width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M18 9L12 15L6 9" stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth="1.5" />
    </svg>
  ), []);

  const ArrowUp = useMemo(() => (
    <svg className='no-shrink' width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M18 15L12 9L6 15" stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth="1.5" />
    </svg>
  ), []);

  const directionType = useDirectionType();


  let style_infoblock = {}
  let text_infoblock = 'Видеолекции:'
  if (false) {
    style_infoblock = { 'display': 'none' }
    text_infoblock = '9 занятий'
  }



  return (
    composedProgramModule && (
      <div
        id={id}
        className={`${className} cpf-left-module`}
      >
        <div className='cpf-left-module-info'>
          <div className='cpf-left-module-info-title'>
            {!isIntroductionModule && <span>Модуль {hasIntroductionModule ? index : index + 1}.</span>} {composedProgramModule.moduleName ? composedProgramModule.moduleName : parse(composedProgramModule.course?.name ?? "")}
          </div>

          {composedProgramModule.course && composedProgramModule.course.directionType === directionType &&
            <div className='cpf-left-module-info-link-area'>
              <div className='cpf-left-module-info-link'>
                {Info}

                <div className='cpf-left-module-info-link-text'>
                  Данный модуль можно пройти отдельным курсом <RelevantLink
                    to={`../${composedProgramModule.course.fullPath}`}
                    relative="route"
                    target="_blank"
                  >
                    "{parse(composedProgramModule.course.name)}"
                  </RelevantLink>
                </div>
              </div>
            </div>}

          {composedProgramModule.course &&
            <div className='cpf-left-module-info-metadata'>


              {composedProgramModule.course.lecturesAmount ?
                <div className='cpf-left-module-info-metadata-item'>
                  {Arrow}

                  <div className='cpf-left-module-info-metadata-content'>
                    Видеолекции
                    <div className='cpf-left-module-info-metadata-inner-content-value'>
                      {composedProgramModule.course.lecturesAmount}
                    </div>
                  </div>
                </div>
                :
                <div className='cpf-left-module-info-metadata-item'>
                  {Arrow}

                  <div className='cpf-left-module-info-metadata-content'>
                    <div className='cpf-left-module-info-metadata-inner-content-value'>
                      {composedProgramModule.course.lecturesAmount || '9 занятий'}
                    </div>
                  </div>
                </div>
              }


              {/* <div className='cpf-left-module-info-metadata-item'>
                {Arrow}

                <div className='cpf-left-module-info-metadata-content'>
                  {text_infoblock}
                  <div className='cpf-left-module-info-metadata-inner-content-value'>
                    {composedProgramModule.course.lecturesAmount || '9 занятий'}
                  </div>
                </div>
              </div> */}
              {composedProgramModule.course.tasksAmount ?
                <div className='cpf-left-module-info-metadata-item' style={style_infoblock}>
                  {Arrow}

                  <div className='cpf-left-module-info-metadata-content'>
                    Практические задания:
                    <div className='cpf-left-module-info-metadata-inner-content-value'>
                      {composedProgramModule.course.tasksAmount}
                    </div>
                  </div>
                </div>
                :
                null
              }

              {composedProgramModule?.course?.videoUrl == 'none' ?
                null
                :
                <div className='cpf-left-module-info-metadata-item'>
                  {Arrow}

                  <div
                    className='cpf-left-module-info-metadata-content video'
                    onClick={() => showProperVideoStateModal({
                      url: composedProgramModule?.course?.videoUrl
                    })}
                    style={style_infoblock}
                  >
                    Пример видеозанятия
                  </div>
                </div>
              }


            </div>}

          <div className='cpf-left-module-info-description'>
            {parse(composedProgramModule.description ?? "")}
          </div>

          {composedProgramModule.course &&
            <div className='cpf-left-module-info-accordion'>
              <div className='cpf-left-module-info-accordion-wrapper'>
                {composedProgramModule.course.programFrame?.program.programItems.map((item, index) => (
                  <div
                    id={`${composedProgramModule?.id}-cpf-left-module-info-accordion-item-zone-${index}`}
                    className='cpf-left-module-info-accordion-item-zone'
                  >
                    <div
                      className={`cpf-left-module-info-accordion-item ${ifClassExists('active', modulesActive[index])}`}
                      onClick={() => {
                        if (!modulesActive[index]) {
                          document.getElementById(`${composedProgramModule?.id}-cpf-left-module-info-accordion-item-zone-${index}`)?.scrollIntoView({ behavior: "smooth" })
                        }
                        toggleModule(index);
                      }}
                    >
                      <div className='cpf-left-module-info-accordion-item-text'>
                        {item.title}
                      </div>

                      {modulesActive[index] ? Minus : Plus}
                    </div>

                    {modulesActive[index] && (
                      <ModuleExtra
                        item={item}
                        index={index}
                      />
                    )}
                  </div>
                ))}
              </div>

              <div
                className='cpf-left-module-button-area'
                onClick={() => {
                  if (anyActive) {
                    collapseAll();
                  } else {
                    expandAll();
                  }
                }}
              >
                <div
                  className='cpf-left-module-button'
                  onClick={() => {
                    document.getElementById(`${composedProgramModule?.id}-cpf-left-module-info-accordion-item-zone-0`)?.scrollIntoView({ behavior: 'smooth' })
                  }}
                >
                  {anyActive ? "Скрыть этапы" : "Раскрыть этапы"}

                  {anyActive ? ArrowUp : ArrowDown}
                </div>
              </div>
            </div>}
        </div>

        {composedProgramModule.course && composedProgramModule.course.workFrame?.works.length &&
          <SwipingWorks
            works={composedProgramModule.course.workFrame?.works}
          />
        }
      </div>
    )
  );
};
