import React, { useCallback, useContext, useMemo } from 'react';
import './index.css';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';

import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { IdProps } from '../../interfaces/id-props';
import { PlayLogo } from './svg/playSvg';
import { ArrowRight } from './svg/arrowRight';
import { ArrowLeft } from './svg/arrowLeft';
import { ModalContext } from '../../context/modal-context';
import { CourseContext } from '../../context/course-context';
import { CourseType } from '../../api/interfaces/course/course-type';
import { CourseFormat } from "../../api/interfaces/course/course-format";
import { educationFrameStaticDataOffline, educationFrameStaticDataOnline, EEducationFeatureType } from "./static-data/education-frame-static-data";
import { DirectionContext } from "../../context/direction-context";

interface EducationFrameProps extends IdProps {
}

export const EducationFrame: React.FC<EducationFrameProps> = (
  {
    id
  }
) => {
  const {
    direction
  } = useContext(DirectionContext);

  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    showProperVideoStateModal,
    showProperAskQuestionModal,
    showProperSignupStateModal
  } = useContext(ModalContext);

  const proceedToFaqFrame = useCallback(() => {
    document.getElementById('faq-frame')?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const proceedToSignupModal = useCallback(() => {
    showProperSignupStateModal({ tabIndex: 0 });
  }, [
    showProperSignupStateModal
  ]);

  const relevantEducationFrameStaticData = useMemo(() => {
    switch (course?.format) {
      case CourseFormat.online:
        return educationFrameStaticDataOnline(direction?.educationFrame);
      case CourseFormat.offline:
        return educationFrameStaticDataOffline(direction?.educationFrame, showProperAskQuestionModal, proceedToFaqFrame, proceedToSignupModal);
    }
  }, [
    course?.format, showProperAskQuestionModal,
    proceedToFaqFrame,
    proceedToSignupModal,
    direction?.educationFrame
  ]);

  const EducationFrameContent = useMemo(() => (
    <div className='education-process-frame-content'>
      <BorderedTitle titleClassList={['text-design-bim-true-white']}>
        {relevantEducationFrameStaticData?.title}
      </BorderedTitle>

      <Swiper
        className='swiper-WidthFull'
        pagination={{
          type: 'fraction',
          el: '.swiper-buttonCont-pagination',
          clickable: true,
          renderFraction: (currentClass: string, totalClass: string) => `<span class='${currentClass}'></span> из <span class='${totalClass}'></span>`
        }}
        navigation={{
          nextEl: '.swiper-buttonCont-next',
          prevEl: '.swiper-buttonCont-prev'
        }}
        modules={[Pagination, Navigation]}
      >
        {relevantEducationFrameStaticData?.features.map((educationFeature, index) => (
          <SwiperSlide key={index}>
            <div className={`educ-process-slider ${course!.type.toLowerCase()}`}>
              <div className='educ-process-slider-column'>
                <div className='educ-process-slider__title'>{educationFeature.title}</div>
                <p className='educ-process-slider__text'>
                  {educationFeature.description}
                </p>
              </div>
              <div className={`educ-process-slider-column-img`}>
                {educationFeature.type === EEducationFeatureType.VIDEO ? (
                  <>
                    <div className='ed-proccess-imgPlay'>
                      {educationFeature.image}

                      <div
                        className='ed-procces-imgPlay-svg'
                        onClick={() => showProperVideoStateModal({
                          url: course!.videoUrl
                        })}
                      >
                        <PlayLogo/>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {educationFeature.type === EEducationFeatureType.SUMMARY ?
                      <>
                        <div className='ed-proccess-imgLink'>
                          {educationFeature.image}

                          <a
                            className='ed-proccess-imgLink__link'
                            href='/'
                          >
                            Фрагмент конспекта
                          </a>
                        </div>
                      </> : (
                        educationFeature.image
                      )
                    }
                  </>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className='swiper-buttonCont'>
          <div className='swiper-buttonCont-button swiper-buttonCont-prev'>
            <ArrowLeft/>
          </div>
          <div className='swiper-buttonCont-pagination'></div>
          <div className='swiper-buttonCont-button swiper-buttonCont-next'>
            <ArrowRight/>
          </div>
        </div>
      </Swiper>
    </div> 
  ), [relevantEducationFrameStaticData, course, showProperVideoStateModal]);

  return useMemo(() => {
    switch (course!.type) {
      case CourseType.plain:
        return (
          <Frame
            id={id}
            bgClassName='outer-background-margin'
            coverClassName={`bg-brand-itmo-bluetiful no-border border--design-bim-primary-black`}
          >
            {EducationFrameContent}
          </Frame>
        );
      case CourseType.refresher:
        return (
          <Frame
            id={id}
            coverClassName={`bg-brand-itmo-bluetiful no-border border--design-bim-primary-black`}
            sectionClassName={'with-cover'}
          >
            {EducationFrameContent}
          </Frame>
        );
    }
  }, [EducationFrameContent, course, id]);
};
