import { forwardRef, useImperativeHandle } from 'react';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';

import { useDocumentModal } from '../document-modal/use-document-modal';
import { DocumentModalState } from "../document-modal";

export const DocumentModalWrapper = forwardRef((
  props,
  ref
) => {
  const {
    documentModal,
    showModal,
    showStatedModal
  } = useDocumentModal(() => {
    removeElementClass(document.body, 'modal-open');
  });

  useImperativeHandle(ref, () => ({
    showModal: () => showModal(),
    showStatedModal: (documentModalState: DocumentModalState) => showStatedModal(documentModalState)
  }));

  return documentModal;
});
