import React, { SetStateAction, useMemo, useState } from 'react';
import { useModal } from '../../hooks/use-modal';
import { FilterModalContent, FilterModalState } from './index';
import { CourseAmountInfo } from '../../frames/direction/courses-frame';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { CategoryDto } from "../../api/interfaces/category/category-dto";

export const useFilterModal = (
  onClick: () => void,
  applyClick: Function,
  courseAmountInfo: CourseAmountInfo,
  lastSelectedFormat: CourseFormat | undefined,
  lastSelectedCategories: CategoryDto[],
  setSelectedFormat: React.Dispatch<SetStateAction<CourseFormat | undefined>>,
  setSelectedCategories: React.Dispatch<SetStateAction<CategoryDto[]>>,
) => {
  const [
    filterModalState,
    setFilterModalState
  ] = useState({} as FilterModalState);

  const {
    Modal,
    showModal,
    hideModal,
  } = useModal('filter-modal-frame-id', onClick);

  const showStatedModal = (currentFilterModalState: FilterModalState) => {
    setFilterModalState(currentFilterModalState);
    showModal();
  }

  const FilterModal = useMemo(() => (
    <Modal
      classList={['modalMain-wrapper--main']}
    >
      <FilterModalContent
        applyClick={() => {
          hideModal();
          applyClick();
        }}
        filterModalState={filterModalState}
        courseAmountInfo={courseAmountInfo}
        selectedFormat={lastSelectedFormat}
        selectedCategories={lastSelectedCategories}
        setSelectedFormat={setSelectedFormat}
        setSelectedCategories={setSelectedCategories}
      />
    </Modal>
  ), [
    Modal,
    applyClick,
    courseAmountInfo,
    filterModalState,
    hideModal,
    lastSelectedCategories,
    lastSelectedFormat,
    setSelectedCategories,
    setSelectedFormat
  ]);

  return (
    {
      FilterModal,
      showModal,
      showStatedModal,
      setFilterModalState
    }
  );
};
