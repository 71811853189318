import React, { useContext } from 'react';
import './index.css';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { RatingComment } from '../../components/rating-comment';
import { CourseContext } from '../../context/course-context';
import parse from 'html-react-parser';
import { GenderType } from "../../api/interfaces/frames/review/gender-type";
import male from "../../frames/review-frame/avatar/male.png";
import female from "../../frames/review-frame/avatar/female.png";

export interface ReviewModalState {
  index: number;
}

interface FeedbackModalProps {
  reviewModalState: ReviewModalState;
}

export const ReviewModalContent: React.FC<FeedbackModalProps> = (
  {
    reviewModalState,
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  return (
    <Swiper
      className="swiper-WidthFull swiper-comment swiper-comment--modal"
      slidesPerView={1}
      slidesPerGroup={1}
      spaceBetween={73}
      initialSlide={reviewModalState.index}
      loop
      followFinger={false}
      noSwiping
      noSwipingClass="comment-sldier-info__text"
      //allowTouchMove
      navigation={{
        nextEl: ".swiper-buttonCont-next",
        prevEl: ".swiper-buttonCont-prev",
      }}
      pagination={{
        type: 'fraction',
        el: '.swiper-buttonCont-pagination',
        clickable: true,
        renderFraction: (currentClass2: string, totalClass2: string) => (
          '<span class="' + currentClass2 + '"></span>' +
          ' из ' +
          '<span class="' + totalClass2 + '"></span>'
        )
      }}
      modules={[Pagination,
        Navigation]}
    >
      {course!.reviewFrame?.reviews.map((review, index) => (
        <SwiperSlide key={index}>
          <div className="comment-slider">
            <div className="comment-slider-imgRow">
              <div className="comment-slider-img">
                <img
                  className='comment-image'
                  src={review.gender === GenderType.male ? male : female}
                  alt=''
                />
              </div>

              <div className="comment-slider-info">
                <div className="comment-slider-info__authorName">
                  {review.author}
                </div>
                <RatingComment rating={review.rating}/>
              </div>
            </div>

            <div className="comment-sldier-info__text">
              {parse(review.text)}
            </div>
          </div>
        </SwiperSlide>
      ))}

      <div className="swiper-buttonCont">
        <div className="swiper-buttonCont-button swiper-buttonCont-prev">
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="28"
              cy="28"
              r="27.25"
              transform="matrix(-1 0 0 1 56 0)"
              fill="white"
              stroke="#0D0D0D"
              strokeWidth="1.5"
            />
            <path
              d="M16.6619 27.9811H39.3378M16.6619 27.9811C20.835 27.7513 29.2342 27.5261 29.2342 16.9941M16.6619 27.9811C20.8217 28.2038 29.2342 28.4737 29.2342 39.0057"
              stroke="#0D0D0D"
              strokeWidth="1.5"
            />
          </svg>
        </div>
        <div className="swiper-buttonCont-pagination"></div>

        <div className="swiper-buttonCont-button swiper-buttonCont-next">
          <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="28"
              cy="28"
              r="27.25"
              transform="matrix(-1 0 0 1 56 0.333008)"
              fill="white"
              stroke="#0D0D0D"
              strokeWidth="1.5"
            />
            <path
              d="M39.3359 28.3141H16.66M39.3359 28.3141C35.1628 28.0843 26.7636 27.8591 26.7636 17.3271M39.3359 28.3141C35.1761 28.5368 26.7636 28.8068 26.7636 39.3387"
              stroke="#0D0D0D"
              strokeWidth="1.5"
            />
          </svg>
        </div>
      </div>
    </Swiper>

  );
};
