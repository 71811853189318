import React, { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useFadeAnimation } from "../../hooks/animation/use-fade-animation";
import { ifClassExists } from "../../utils/if-class-exists";
import { errorMessage } from "../form-commons";
import { CustomButton } from "../../components/button";
import { QuestionDto } from "../../api/interfaces/question/question-dto";
import useAxios from "axios-hooks";
import { QUESTIONS_URL } from "../../api/url";

type QuestionInputs = {
  name: string;
  mail: string;
  phone: string;
  message: string;

  communicationMethod: string;

  videoUrl: string;
  path: string;
  backgroundId: string;
}

export const useQuestionForm = (
  courseId: string | undefined,
  directionId: string | undefined,
) => {
  const [{
    data: savedQuestionDto,
    response,
    loading
  }, postRequest] = useAxios<QuestionDto>({
    url: QUESTIONS_URL,
    method: "POST"
  }, {
    manual: true
  });

  const trigger = useFadeAnimation(".error-text");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<QuestionInputs>();

  useEffect(() => trigger(), [
    trigger,
    errors
  ]);

  // const [{
  //   data: postCategoryDirectionResponseData,
  //   response: postCategoryDirectionResponse,
  //   loading: postCategoryDirectionResponseLoading,
  //   error: postCategoryDirectionResponseError
  // }, fetchPostCategoryDirection] = useAxios<void, PostCategoryDirectionDto>(
  //   {
  //     url: POST_CATEGORY_URL,
  //     method: 'POST',
  //   },
  //   {
  //     manual: true
  //   });


  const onSubmit: SubmitHandler<QuestionInputs> = useCallback(() => {
    if (!loading) {

      postRequest({
        data: {
          name: watch("name"),
          email: watch("mail"),
          phone: watch("phone"),
          message: watch("message"),
          communicationMethod: watch("communicationMethod"),
          course: {
            id: courseId
          },
          direction: {
            id: directionId
          }
        } as QuestionDto
      });
    }
  }, [
    loading,
    courseId,
    postRequest,
    directionId,
    watch
  ]);

  return {
    QuestionForm: (
      <form
        id="question-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="title">Контактные данные</div>

        <input
          className={`${ifClassExists('error', !!errors.name)}`}
          placeholder="Фамилия Имя Отчество *"
          {...register("name", { required: true, maxLength: 255, pattern: /[^0-9]+/ })}
        />

        {errorMessage(errors.name)}

        <input
          className={`${ifClassExists('error', !!errors.mail)}`}
          placeholder="Электронная почта *"
          {...register("mail", {
            required: true,
            pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
          })}
        />

        {errorMessage(errors.mail)}

        <input
          className={`${ifClassExists('error', !!errors.phone)}`}
          placeholder="Телефон *"
          {...register("phone", { required: true, pattern: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/ })}
        />

        {errorMessage(errors.phone)}

        <div className="title">Сообщение</div>

        <textarea
          rows={5}
          className={`${ifClassExists('error', !!errors.message)} text-message`}
          placeholder="Текст сообщения *"
          {...register("message", { required: true, maxLength: 1024 })}
        />

        {errorMessage(errors.message)}

        <div className="title">Способ связи</div>

        <select
          className={`${ifClassExists('error', !!errors.communicationMethod)} ${ifClassExists('default', !watch("communicationMethod"))}`}
          {...register("communicationMethod", { required: true })}
        >
          {/* <option disabled value="" selected>Способ связи *</option> */}
          <option value={"По почте"} selected>По почте</option>
          <option value={"По телефону"}>По телефону</option>
          <option value={"Любой"}>Любой</option>
        </select>

        {errorMessage(errors.communicationMethod)}
      </form>
    ),
    Buttons: (
      <div className='request-form-buttons'>
        <CustomButton
          form="question-form"
          loading={loading}
          buttonType={"submit"}
          type={'button'} title={'Отправить'}
          classList={[
            'button--blue',
            'fsz16-lh20',
            'pd-bl-but2',
            'border--offline-color'
          ]}
        />

        <p className='request-form__text'>Нажимая "Отправить", вы принимаете <a
          href="https://itmo.ru/file/pages/79/personal_data_policy.pdf" target="_blank"
          rel="noreferrer">условия обработки персональных данных</a></p>
      </div>
    ),
    responseStatus: response?.status
  }
}
