import React, { MutableRefObject, useContext, useEffect, useLayoutEffect } from 'react';
import './index.css';
import { ifClassExists } from "../../utils/if-class-exists";
import { PageType } from "../../hooks/use-page-type";
import { useFetchDirectionData } from "./use-fetch-direction-data";
import { SplashScreenContext } from "../../context/splash-screen-context";
import gsap from 'gsap';
import { useLocation } from "react-router-dom";
import { Loader } from "../loader";

interface SplashScreenProps {
  splashScreenRef: MutableRefObject<HTMLDivElement>;
  splashType: PageType;
}

export const SplashScreen: React.FC<SplashScreenProps> = (
  {
    splashScreenRef,
    splashType
  }
) => {
  const {
    loading
  } = useContext(SplashScreenContext);

  const location = useLocation();

  useEffect(() => {
    document.querySelector("link[rel='canonical']")?.setAttribute("href", window.location.href.split('?')[0]);
    document.querySelector('meta[property="og:url"]')?.setAttribute("content", window.location.href.split('?')[0]);
  }, [location]);

  useFetchDirectionData();
  // useFetchCatalogData(splashType === PageType.CATALOG);
  // useFetchCourseData(splashType === PageType.COURSE);

  useLayoutEffect(() => {
    gsap.from(".splash-screen-logo", {
      duration: 0.1,
      opacity: 0,
      scale: 0.1,
      stagger: {
        from: "center",
      },
      ease: "elastic.out(1, 0.5)",
    });
  }, [location]);

  return (
    <div
      id='splash-screen'
      ref={splashScreenRef}
      className={`splash-screen ${ifClassExists('animated-exit', !loading)}`}
    >
      <div className='splash-screen-logo-area'>
        <svg
          id='splash-screen-logo'
          className={`splash-screen-logo`}
          width="509" height="140" viewBox="0 0 509 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M508.624 87.7068C508.56 67.6133 500.273 52.6264 483.939 43.1482C475.196 38.0792 465.283 35.8264 455.29 35.8961C445.297 35.8264 435.384 38.0739 426.641 43.1482C410.308 52.6264 402.026 67.6133 401.956 87.7068C402.02 107.806 410.308 122.793 426.641 132.271C435.122 137.19 444.755 139.496 454.523 139.55C454.775 139.55 455.033 139.539 455.29 139.539C455.548 139.544 455.805 139.55 456.057 139.55C465.825 139.496 475.459 137.195 483.939 132.271C500.278 122.793 508.565 107.806 508.624 87.7068ZM474.456 107.559C469.73 112.365 463.17 114.913 455.483 114.924C455.419 114.924 455.36 114.918 455.296 114.918C455.231 114.918 455.172 114.924 455.108 114.924C447.421 114.913 440.861 112.37 436.135 107.559C431.367 102.699 428.781 95.6724 428.808 87.7122C428.776 79.752 431.361 72.7199 436.135 67.8601C440.856 63.054 447.416 60.5061 455.108 60.4953H455.151C455.199 60.4953 455.242 60.4953 455.29 60.5007C455.338 60.5007 455.381 60.4953 455.43 60.4953H455.473C463.164 60.5007 469.725 63.0486 474.445 67.8601C479.219 72.7199 481.804 79.752 481.772 87.7122C481.81 95.6777 479.224 102.699 474.456 107.559Z"
            fill="black"/>
          <path d="M133.306 37.0063V61.6539H170.816V138.648H198.269V61.6539H236.455V37.0063H133.306Z"
                fill="black"/>
          <path d="M124.445 36.8022H96.7402V138.648H124.445V36.8022Z" fill="black"/>
          <path d="M27.9735 36.8022H0.268555V138.648H27.9735V36.8022Z" fill="black"/>
          <path d="M69.7483 36.8022L27.9736 138.648H56.5368L96.7131 36.8022H69.7483Z" fill="black"/>
          <path
            d="M347.646 36.8022L320.15 103.831L292.654 36.8022H245.317V138.648H273.022V55.3831L305.871 138.648H334.429L367.278 55.3831V138.648H394.983V36.8022H347.646Z"
            fill="black"/>
          <path
            d="M110.579 0.477539C102.919 0.477539 96.7129 6.55493 96.7129 14.0431C96.7129 21.5365 102.919 27.6086 110.579 27.6086C118.239 27.6086 124.445 21.5365 124.445 14.0431C124.445 6.54957 118.239 0.477539 110.579 0.477539Z"
            fill="black"/>
        </svg>

        <Loader className='splash-screen--loader'/>

        <div className='message-area'>
          <svg className='message-icon' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'
               stroke='#ffffff'>
            <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
            <g id='SVGRepo_iconCarrier'>
              <circle cx='12' cy='17' r='1' fill='#ffffff'></circle>
              <path d='M12 10L12 14' stroke='#ffffff' strokeWidth='2' strokeLinecap='round'
                    strokeLinejoin='round'></path>
              <path
                d='M3.44722 18.1056L10.2111 4.57771C10.9482 3.10361 13.0518 3.10362 13.7889 4.57771L20.5528 18.1056C21.2177 19.4354 20.2507 21 18.7639 21H5.23607C3.7493 21 2.78231 19.4354 3.44722 18.1056Z'
                stroke='#ffffff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'></path>
            </g>
          </svg>

          <div className='message'>
            Сервис недоступен
          </div>
        </div>
      </div>
    </div>
  );
};
