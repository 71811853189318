import React, { useContext, useMemo } from 'react';
import { IdProps } from '../../interfaces/id-props';
import { Frame } from '../../components/frame';
import './index.css';
import { CourseContext } from "../../context/course-context";
import { useNavigate, useParams } from "react-router-dom";
import { SplashScreenContext } from "../../context/splash-screen-context";
import { getFileUrl } from "../../api/url";
import { DirectionContext } from "../../context/direction-context";

interface AgitateFrameProps extends IdProps {

}

export const AgitateFrame: React.FC<AgitateFrameProps> = (
  {
    id
  }
) => {
  const {
    direction
  } = useContext(DirectionContext);

  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    courseType,
    coursePath,
  } = useParams();

  const onlineCourseUrl = useMemo(() => `/courses/${courseType}/online/${coursePath}#education-stages-frame`, [
    courseType,
    coursePath
  ]);

  const navigate = useNavigate();

  const {
    setLoading
  } = useContext(SplashScreenContext);

  return course?.onlineAvailable && (
    <Frame
      id={id}
      bgClassName='outer-background-margin'
      coverClassName={`agitate-frame`}
    >
      <div className='agitate-frame'>
        <div className='agitate-frame-left'>
          <div className='agitate-description-title'>
            Понравился курс, но пока <span>не готовы учиться очно</span>?
          </div>
          <div className='agitate-description-description'>
            Данный курс доступен в дистанционном формате. <span onClick={() => window.open(onlineCourseUrl, "_blank")}>Узнайте о преимуществах</span> онлайн-обучения в нашем Центре и сделайте свой выбор!
          </div>
        </div>

        <div className='agitate-frame-right'>
          <img
            src={getFileUrl(direction?.agitateFrame?.imageId)}
            alt=""
          />
        </div>
      </div>
    </Frame>
  );
};
