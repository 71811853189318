import React from 'react';

interface StarProps {
  colorFill?: string;
  colorStroke?: string;
}

export const StarRating: React.FC<StarProps> = (
  {
    colorFill = '#f88433',
    colorStroke = '#FFCC33'
  }
) => {
  return (
    <svg id='start-rating-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3075 7.17397C10.9496 5.57328 11.2706 4.77294 11.7922 4.66201C11.9293 4.63285 12.0711 4.63285 12.2082 4.66201C12.7298 4.77294 13.0508 5.57328 13.6929 7.17396C14.058 8.08425 14.2406 8.53939 14.5822 8.84895C14.678 8.93578 14.782 9.01311 14.8928 9.07986C15.2876 9.31784 15.7805 9.36198 16.7663 9.45027C18.4351 9.59972 19.2695 9.67444 19.5243 10.1502C19.577 10.2487 19.6129 10.3554 19.6304 10.4658C19.7149 10.9988 19.1015 11.5569 17.8747 12.673L17.534 12.9829C16.9605 13.5047 16.6737 13.7657 16.5078 14.0913C16.4083 14.2866 16.3416 14.4969 16.3104 14.7139C16.2582 15.0756 16.3422 15.4541 16.5102 16.211L16.5702 16.4815C16.8714 17.839 17.022 18.5178 16.834 18.8514C16.6651 19.1511 16.3541 19.343 16.0105 19.3594C15.6279 19.3777 15.089 18.9385 14.011 18.0601C13.3008 17.4814 12.9457 17.192 12.5515 17.079C12.1912 16.9757 11.8092 16.9757 11.4489 17.079C11.0547 17.192 10.6996 17.4814 9.98941 18.0601C8.91144 18.9385 8.37245 19.3777 7.98993 19.3594C7.64633 19.343 7.33528 19.1511 7.16642 18.8514C6.97842 18.5178 7.12902 17.839 7.43022 16.4815L7.49023 16.211C7.65818 15.4541 7.74216 15.0756 7.69004 14.7139C7.65878 14.4969 7.59207 14.2866 7.49257 14.0913C7.32669 13.7657 7.03992 13.5047 6.46637 12.9829L6.1257 12.673C4.89891 11.5569 4.28552 10.9988 4.36999 10.4658C4.38749 10.3554 4.42337 10.2487 4.47614 10.1502C4.73094 9.67444 5.56532 9.59972 7.23408 9.45027C8.21986 9.36198 8.71276 9.31784 9.1076 9.07986C9.21834 9.01311 9.32236 8.93578 9.41818 8.84895C9.75979 8.53939 9.94236 8.08425 10.3075 7.17397Z"
            fill={colorFill} stroke="black"/>
    </svg>
  );
}
