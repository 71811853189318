import React, { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import './index.css';
import Masonry from 'react-responsive-masonry';
import gsap from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { NavBarRootState } from '../store/store';
import { allCoursesVisibleOff, modalBurgerVisibleOff, modalBurgerVisibleOn } from '../store/slices/navbar-slice';
import { CourseContext } from "../../../context/course-context";
import { CourseDto } from "../../../api/interfaces/course/course-dto";
import { SplashScreenContext } from "../../../context/splash-screen-context";
import { DirectionContext } from "../../../context/direction-context";
import { CategoryDto } from "../../../api/interfaces/category/category-dto";
import { CourseFormat } from "../../../api/interfaces/course/course-format";
import { useFormatSelector } from "./format-selector/use-format-selector";
import { useFadeAnimation } from "../../../hooks/animation/use-fade-animation";
import { RelevantLink } from "../../relevant-link";
import parse from "html-react-parser";

interface IDirectionCourses {
  category: CategoryDto;
  onlineCourses: CourseDto[];
  offlineCourses: CourseDto[];
}

export const AllCourses: React.FC = () => {
  const animated = useSelector((state: NavBarRootState) => state.navBar.animated);
  const dispatch = useDispatch();

  const {
    direction
  } = useContext(DirectionContext);

  const {
    courseData
  } = useContext(CourseContext);

  const directionCourses: IDirectionCourses[] = useMemo(() => {
    const directionCourses_ = [] as IDirectionCourses[];

    (direction?.availableCategories ?? []).forEach(direction => {
      directionCourses_.push(({
        category: direction,
        onlineCourses: (courseData?.allCourses ?? [])
          .filter(course => course.categories.map(category => category.type).includes(direction.type))
          .filter(course => course.format === CourseFormat.online),
        offlineCourses: (courseData?.allCourses ?? [])
          .filter(course => course.categories.map(category => category.type).includes(direction.type))
          .filter(course => course.format === CourseFormat.offline),
      }));
    });

    return directionCourses_;
  }, [
    direction?.availableCategories,
    courseData
  ]);

  const anyOnline: boolean = useMemo(() => {
    for (const titledCourse of directionCourses) {
      if (titledCourse.onlineCourses.length > 0) {
        return true;
      }
    }
    return false;
  }, [
    directionCourses
  ]);

  const anyOffline: boolean = useMemo(() => {
    for (const titledCourse of directionCourses) {
      if (titledCourse.offlineCourses.length > 0) {
        return true;
      }
    }
    return false;
  }, [
    directionCourses
  ]);

  const {
    formatSelector,
    courseFormat
  } = useFormatSelector(
    anyOffline,
    anyOnline
  );

  const {
    setLoading
  } = useContext(SplashScreenContext);

  const trigger = useFadeAnimation(".all-course-item");

  useEffect(() => {
    trigger();
  }, [courseFormat, trigger]);

  const items = useMemo(() =>
    directionCourses.map(titledCourse => (
      <>
        {!((courseFormat === CourseFormat.online && !titledCourse.onlineCourses.length) ||
          (courseFormat === CourseFormat.offline && !titledCourse.offlineCourses.length)) && (
          <div className='all-course-item'>
            <div className='all-course-item-title-area'>
              <div className='all-course-item-title'>
                {titledCourse.category.name}
              </div>
            </div>

            <div className='all-course-points'>
              <div className='all-course-points-courses-type'>
                {(courseFormat === undefined || courseFormat === CourseFormat.offline) && !!titledCourse.offlineCourses.length && (
                  <div className='all-course-points-courses-type-item'>
                    {courseFormat === undefined && <div className='all-course-points-title'>
                      В классе
                    </div>}

                    {titledCourse.offlineCourses.map(course => (
                      <RelevantLink to={`../${course?.fullPath}`} relative={"route"}>
                        <a
                          onClick={() => {
                            dispatch(allCoursesVisibleOff());
                            dispatch(modalBurgerVisibleOff());

                            setLoading(true);
                          }}
                          className={`all-course-points-item`}
                        >
                          <span>{parse(course.name)}</span>
                          {/*{point.label && <div className='all-course-points-item__label'>{point.label}</div>}*/}
                        </a>
                      </RelevantLink>
                    ))}
                  </div>
                )}

                {(courseFormat === undefined || courseFormat === CourseFormat.online) && !!titledCourse.onlineCourses.length && (
                  <div className='all-course-points-courses-type-item'>
                    {courseFormat === undefined && <div className='all-course-points-title'>
                      Онлайн
                    </div>}

                    {titledCourse.onlineCourses.map(course => (
                      <RelevantLink to={`../${course?.fullPath}`} relative={"route"}>
                        <a
                          onClick={() => {
                            dispatch(allCoursesVisibleOff());
                            dispatch(modalBurgerVisibleOff());

                            setLoading(true);
                          }}
                          className={`all-course-points-item`}
                        >
                          <span>{parse(course.name)}</span>
                          {/*{point.label && <div className='all-course-points-item__label'>{point.label}</div>}*/}
                        </a>
                      </RelevantLink>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )), [
    dispatch,
    setLoading,
    directionCourses,
    courseFormat
  ]);

  useLayoutEffect(() => {
    if (animated) {
      gsap.from('.all-courses', {
        opacity: 0,
        ease: 'bounce.in'
      });

      gsap.to('.all-courses', {
        opacity: 1
      });
    } else {
      gsap.from('.all-courses', {
        opacity: 1,
        ease: 'bounce.in'
      });

      gsap.to('.all-courses', {
        opacity: 1
      });

      gsap.from('.all-courses-wrapper', {
        opacity: 0,
        ease: 'bounce.in'
      });

      gsap.to('.all-courses-wrapper', {
        opacity: 1
      });
    }
  }, [
    animated
  ]);

  return (
    <div className='all-courses'>
      <div
        className='all-courses-closeIcon'
        onClick={event => {
          if ((event.target as HTMLDivElement).className === 'all-courses-closeIcon') {
            dispatch(allCoursesVisibleOff());
            dispatch(modalBurgerVisibleOff());
          }
        }}
      >
        <div className='all-courses-closeIcon-wrapper'>
          <div
            onClick={() => dispatch(allCoursesVisibleOff())}
            className='menu-icon'
          >
            <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
              <path d='M24 8L8 24' stroke='#0D0D0D' strokeWidth='2' strokeLinecap='square'
                    strokeLinejoin='round'></path>
              <path d='M8 8L24 24' stroke='#0D0D0D' strokeWidth='2' strokeLinecap='square'
                    strokeLinejoin='round'></path>
            </svg>
          </div>
        </div>
      </div>

      <div className='all-courses-arrowBack'>
        <div
          onClick={() => {
            dispatch(allCoursesVisibleOff());
            dispatch(modalBurgerVisibleOn());
          }}
          className='menu-icon'>
          <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M5.33203 16L4.62492 15.2929L3.91782 16L4.62492 16.7071L5.33203 16ZM25.332 17C25.8843 17 26.332 16.5523 26.332 16C26.332 15.4477 25.8843 15 25.332 15V17ZM12.6249 7.29289L4.62492 15.2929L6.03914 16.7071L14.0391 8.70711L12.6249 7.29289ZM4.62492 16.7071L12.6249 24.7071L14.0391 23.2929L6.03914 15.2929L4.62492 16.7071ZM5.33203 17H25.332V15H5.33203V17Z'
              fill='#0D0D0D'/>
          </svg>
        </div>
      </div>

      {formatSelector}

      <div
        className='all-courses-wrapper'
        onClick={event => {
          if ((event.target as HTMLDivElement).className === 'all-courses-wrapper') {
            dispatch(allCoursesVisibleOff());
            dispatch(modalBurgerVisibleOff());
          }
        }}
      >
        <Masonry
          className='all-courses-masonry'
          columnsCount={3}
        >
          {items}
        </Masonry>
      </div>
    </div>
  );
};
