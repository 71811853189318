import { useCallback, useLayoutEffect } from "react";
import gsap from "gsap";

export const useFadeAnimation = (
  target: string,
  delay?: number,
) => {
  const trigger = useCallback(() => {
    gsap.from(target, {
      opacity: 0,
      ease: 'cubic-bezier(0.25, 1, 0.5, 1)',
    });

    gsap.to(target, {
      opacity: 1,
    });
  }, [target]);

  useLayoutEffect(() => {
    if (delay) {
      setTimeout(() => trigger(), delay);
    } else {
      trigger();
    }
  }, [
    trigger,
    target,
    delay
  ]);

  return trigger;
}
