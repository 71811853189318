import { useContext } from 'react';
import { CourseContext } from '../context/course-context';

export enum PageType {
  COURSE,
  DIRECTION
}

export const usePageType = () => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  if (course) {
    return PageType.COURSE;
  }

  return PageType.DIRECTION;
}
