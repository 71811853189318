import React, { useContext, useMemo } from 'react';
import { Frame } from '../../components/frame';
import './index.css';
import { BackgroundLine } from './svg/background-line';
import { IdProps } from '../../interfaces/id-props';
import { CourseContext } from '../../context/course-context';
import { getFileUrl } from '../../api/url';
import { CourseFormat } from "../../api/interfaces/course/course-format";
import { ifClassExists } from "../../utils/if-class-exists";
import { CourseType } from "../../api/interfaces/course/course-type";

export const AuthorFrame: React.FC<IdProps> = (
  {
    id
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;
  
  // console.log(course);
  

  const authorProfession = useMemo(() => {
    switch (course?.format) {
      
      case CourseFormat.online:
        if(course?.id=='af93fbea-1e76-4eef-99d0-dfaaef18e231' || course?.id=='01229624-4723-4595-96f9-1d9f3440254a'){
          return "Куратор курса";
        } else{
          return "Автор курса";
        }
      case CourseFormat.offline:
        return "Преподаватель курса";
    }
  }, [
    course?.format
  ]);


  return course!.authors.length === 1 && (
    <Frame
      id={id}
      bgClassName={`outer-background-margin ${ifClassExists("outer-background-margin-bottom", course?.type === CourseType.refresher)}`}
      coverClassName={`author-frame no-padding overflow-hidden`}
    >
      <div className='author-frame-cover-content'>
        <div className='author-frame-cover-leftSide'>
          <div className='author-frame-cover-image'>
            <img
              src={getFileUrl(course!.authors[0].imageId ?? "")}
              alt='author'
              className='author-frame-cover-image__img'
            />
          </div>

          <div className='author-frame-cover-info author-info'>
            <div className='author-info-row'>
              <div className='author-frame-cover-image2'>
                <img
                  src={getFileUrl(course!.authors[0].imageId ?? "")}
                  alt='author'
                  className='author-frame-cover-image2__img'
                />
              </div>

              <div className='author-info-column'>
                <div className='author-info__name'>
                  {course!.authors[0].name}
                </div>
                <div className='author-info__prof'>
                  {authorProfession}
                </div>
              </div>
            </div>
            <p className='author-info__text' dangerouslySetInnerHTML={{ __html: course!.authors[0].description }} />
            {/* <p className='author-info__text'>{course!.authors[0].description}</p> */}



            {/*<a*/}
            {/*  className='author-info__link'*/}
            {/*  href='/'*/}
            {/*>*/}
            {/*  <span>Полная коллекция курсов автора</span>*/}
            {/*  <Arrow*/}
            {/*    type='arrow-1'*/}
            {/*    color={`${colors['--brand-itmo-green-lizard']}`}*/}
            {/*  />*/}
            {/*</a>*/}
          </div>
        </div>

        <div className='author-frame-cover-bg'>
          <BackgroundLine/>
        </div>
      </div>
    </Frame>
  );
};
