import React from 'react';
import "./index.css";
import { CourseFormat } from "../../../../api/interfaces/course/course-format";
import { ifClassExists } from "../../../../utils/if-class-exists";

interface FormatSelectorProps {
  courseFormat: CourseFormat | undefined;
  setCourseFormat: React.Dispatch<React.SetStateAction<CourseFormat | undefined>>;
  anyOffline: boolean,
  anyOnline: boolean,
}

export const FormatSelector: React.FC<FormatSelectorProps> = (
  {
    courseFormat,
    setCourseFormat,
    anyOffline,
    anyOnline,
  }
) => {
  return (
    <div className='format-selector-wrapper'>
      <div className='format-selector'>
        <div
          className={`format-selector-option ${ifClassExists('selected', courseFormat === undefined)}`}
          onClick={() => setCourseFormat(undefined)}
        >
          Все курсы
        </div>

        {anyOffline && <div
          className={`format-selector-option ${ifClassExists('selected', courseFormat === CourseFormat.offline)}`}
          onClick={() => setCourseFormat(CourseFormat.offline)}
        >
          В классе
        </div>}

        {anyOnline && <div
          className={`format-selector-option ${ifClassExists('selected', courseFormat === CourseFormat.online)}`}
          onClick={() => setCourseFormat(CourseFormat.online)}
        >
          Онлайн
        </div>}
      </div>
    </div>
  );
};
