import React from 'react';
import './index.css';
import { Frame } from '../../../components/frame';
import { InfoTag } from './info-tag';
import { InfoName } from './info-name';
import { InfoTags } from './info-tags';

interface InfoFrameProps {
  id: string;
}

export const InfoFrame: React.FC<InfoFrameProps> = (
  {
    id
  }
) => {
  return (
    <Frame
      id={id}
      sectionClassName={'start-frame catalog'}
      bgClassName={'bg-brand-itmo-bluetiful'}
      coverClassName={'catalog'}
    >
      <div className='info-frame'>
        <div className='info-frame-title-area'>
          <InfoTag/>
          <InfoName/>
        </div>

        <InfoTags/>
      </div>
    </Frame>
  );
};
