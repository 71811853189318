export const HOST = process.env.REACT_APP_BACKEND_HOST ?? `${window.location.protocol}//${window.location.hostname}/api`;

export const DIRECTION_PATH = '/direction';
export const REQUESTS_PATH = '/requests';
export const QUESTIONS_PATH = '/questions';
export const COURSES_PATH = '/courses';
export const FILES_PATH = '/files';
export const STYLES_PATH = '/styles';

export const COURSES_URL = `${HOST}${COURSES_PATH}`;
export const FILES_URL = `${HOST}${FILES_PATH}`;
export const FILES_ATTACHMENT_URL = `${HOST}${FILES_PATH}/attachment`;
export const DIRECTION_URL = `${HOST}${DIRECTION_PATH}`;
export const REQUESTS_URL = `${HOST}${REQUESTS_PATH}`;
export const QUESTIONS_URL = `${HOST}${QUESTIONS_PATH}`;
export const STYLES_URL = `${HOST}${STYLES_PATH}`;

export const getCoursesUrl = (ids?: string[]) => `${COURSES_URL}?ids=${ids?.join(",")}`;

export const getFileUrl = (fileName: string = "") => `${FILES_URL}/${fileName}`;
export const getFileAttachmentUrl = (fileName: string = "") => `${FILES_ATTACHMENT_URL}/${fileName}`;

export const getDirectionUrl = () => `${DIRECTION_URL}`;

export const getStylesUrl = () => `${STYLES_URL}`;

