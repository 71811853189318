import React, { createContext } from 'react';
import { DirectionDto } from '../api/interfaces/direction/direction-dto';

interface IDirectionContext {
  direction: DirectionDto | undefined;
  setDirection: React.Dispatch<DirectionDto>;
  directionType: string;
}

export const DirectionContext = createContext({} as IDirectionContext);
