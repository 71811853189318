import { Arrow } from '../arrow';
import React, { useLayoutEffect } from 'react';
import gsap from 'gsap';
import parse from 'html-react-parser';
import { ProgramItemDto } from "../../api/interfaces/frames/program/program-item-dto";
import { FaqItemDto } from "../../api/interfaces/frames/faq/faq-item-dto";
import { colors } from "../../constants/colors";

type ItemAccordType2 = 'course' | 'FAQ';

interface BodyAccordionProps {
  typeItem: ItemAccordType2;
  content: ProgramItemDto | FaqItemDto;
  index: number;
}

export const CustomBodyAccordion: React.FC<BodyAccordionProps> = (
  {
    typeItem,
    content,
    index
  }
) => {
  useLayoutEffect(() => {
    const accordionItem = document.getElementById(`accordion-content-${index}`);

    gsap.from(accordionItem, {
      translateY: -30,
      opacity: 0,
      ease: 'bounce.in',
    });

    gsap.to(accordionItem, {
      translateY: 0,
      opacity: 1,
    });
  }, [
    index
  ]);

  switch (typeItem) {
    case 'course':
      return (
        <div
          id={`accordion-content-${index}`}
          className='accordion-content'
        >
          {!!((content as ProgramItemDto).descriptionPoints ?? []).length && !!((content as ProgramItemDto).taskPoints ?? []).length &&
            <div className='acl-points-title'>
              Занятия:
            </div>}
          <ul className='accordion-content-list'>
            {((content as ProgramItemDto).descriptionPoints ?? []).map(descriptionPoint => (
              <li className='accordion-content-list__item'>
                {<Arrow
                  type='arrow-2'
                  color={`${colors['--brand-itmo-green-lizard']}`}
                />}
                {parse(descriptionPoint)}
              </li>
            ))}
          </ul>

          {!!((content as ProgramItemDto).taskPoints ?? []).length &&
            <div className='acl-points-title'>
              Задания:
            </div>
          }
          <ul className='accordion-content-list'>
            {((content as ProgramItemDto).taskPoints ?? []).map(taskPoint => (
              <li className='accordion-content-list__item'>
                {<Arrow
                  type='arrow-2'
                  color={`${colors['--brand-itmo-green-lizard']}`}
                />}
                {parse(taskPoint)}
              </li>
            ))}
          </ul>
        </div>);
    case 'FAQ':
      return (
        <div
          id={`accordion-content-${index}`}
          className='accordion-content question'
        >
          {<div className='accordion-content__text' key={index}>
            {parse((content as FaqItemDto).answer)}
          </div>}
        </div>);
  }
};
