import { IdProps } from '../../interfaces/id-props';
import React, { ReactNode, useContext } from 'react';
import './index.css';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import { ModalContext } from '../../context/modal-context';
import { Point } from '../../components/point/point';
import { CourseContext } from '../../context/course-context';
import { dateToString, getWeekDay } from "../../utils/dates";
import { getClassDates } from "../../hooks/get-class-dates";
import { ScheduleFrameDto } from "../../api/interfaces/frames/schedule/schedule-frame-dto";

interface ScheduleFrameProps extends IdProps {
  timetableArray?: Array<string>;
  titleContent: ReactNode;
  colorPoint: string;
  scheduleFrame: ScheduleFrameDto;
}

const Arrow = (
  <svg className="arrow-icon" width='24' height='24' viewBox='0 0 24 24' fill='none'
       xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4912_97838)'>
      <path
        d='M22.5 12.9994L16.8829 8.09033V12.1812H2.25V13.8176H16.8829V17.9085L22.5 12.9994Z'
        fill='#0D0D0D'
      />
    </g>
    <defs>
      <clipPath id='clip0_4912_97838'>
        <rect width='20.25' height='9.81818' fill='white' transform='translate(2.25 8.09131)'/>
      </clipPath>
    </defs>
  </svg>
);

const Clock = (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none'
       xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12.5' r='7' stroke='#00CCFF' strokeWidth='1.5'/>
    <path
      d='M5.96472 3.6363C5.2865 3.81803 4.66807 4.17508 4.17157 4.67157C3.67508 5.16807 3.31803 5.7865 3.1363 6.46472'
      stroke='#00CCFF'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M18.0353 3.6363C18.7135 3.81803 19.3319 4.17508 19.8284 4.67157C20.3249 5.16807 20.682 5.7865 20.8637 6.46472'
      stroke='#00CCFF'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M12 8.5V12.25C12 12.3881 12.1119 12.5 12.25 12.5H15'
      stroke='#00CCFF'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);

const Location = (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none'
       xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12.5' r='7' stroke='#00CCFF' strokeWidth='1.5'/>
    <circle cx='12' cy='12.5' r='2' fill='#00CCFF' stroke='#00CCFF' strokeWidth='1.5'/>
    <path d='M12 5.5V3.5' stroke='#00CCFF' strokeWidth='1.5' strokeLinecap='round'/>
    <path d='M19 12.5L21 12.5' stroke='#00CCFF' strokeWidth='1.5' strokeLinecap='round'/>
    <path d='M12 21.5L12 19.5' stroke='#00CCFF' strokeWidth='1.5' strokeLinecap='round'/>
    <path d='M3 12.5H5' stroke='#00CCFF' strokeWidth='1.5' strokeLinecap='round'/>
  </svg>
);

export const ScheduleFrame: React.FC<ScheduleFrameProps> = (
  {
    id,
    titleContent,
    colorPoint,
    scheduleFrame
  }
) => {
  const DateLabel = (
    dataStart: string,
    dataEnd: string,
    classList: Array<string>
  ) => (
    <div
      className={`schedule-label ${classList?.join(' ')} schedule-label--main`}
    >
      <div className='schedule-label__text'>
        {dataStart}
      </div>

      {Arrow}

      <div className='schedule-label__text'>
        {dataEnd}
      </div>
    </div>
  );

  const { showProperLocationModal } = useContext(ModalContext);

  const {
    classDates,
    firstClassDate,
    lastClassDate
  } = getClassDates(scheduleFrame);

  return (
    <Frame
      id={id}
      coverClassName={`schedule-frame`}
      bgClassName='outer-background-margin'
    >
      <div className='schedule-frame-content'>
        <BorderedTitle titleClassList={['text-design-bim-true-white']}>
          {titleContent}
        </BorderedTitle>

        <div className='schedule-wrapper'>
          {firstClassDate && lastClassDate &&
            DateLabel(
              dateToString(firstClassDate),
              dateToString(lastClassDate),
              ['schedule-label--green']
            )}

          {classDates && (
            <div className='schedule-wrapper-timetable'>
              {classDates.map((classDate, index) => (
                <div className='schedule_dayBlock'>
                    <span className='schedule_dayBlock__text'>
                      <span className='schedule_dayBlock__text-content'>
                        {getWeekDay(new Date(classDate))}
                      </span>
                    </span>

                  {`${dateToString(classDate, false)}`}
                </div>
              ))}
            </div>
          )}

          <div className='schedule-footer'>
            <div className='schedule-footer-time'>
              {Clock}
              <div className='schedule-footer-time-block'>
                Время:
                <span className='schedule-footer-time-block__text'>
                    {scheduleFrame.time}
                  </span>
              </div>
            </div>

            <div className='schedule-footer-address'>
              {Location}
              <div className='schedule-footer-address-block'>
                Адрес:
                <div className='schedule-footer-addressBlock'>
                    <span
                      onClick={showProperLocationModal}
                      className='schedule-footer-address-block__text'
                      style={{ color: colorPoint }}
                    >
                      {scheduleFrame.address}
                    </span>

                  <Point color={colorPoint}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Frame>
  );
};
