import React from 'react';
import ReactPlayer from 'react-player';
import './index.css';

interface VideoModalProps {
}

export interface VideoModalState {
  url: string | undefined;
}

interface VideoModalProps {
  videoModalState: VideoModalState;
}
const styleDiv = {
  'width': '100%',
  'height': '100%',
}

export const VideoModalContent: React.FC<VideoModalProps> = (
  {
    videoModalState,
  }
) => {
  // console.log("test");
  // console.log(videoModalState.url);
  let link_video = videoModalState.url+"?autoplay=0&amp;mute=0&amp;controls=1&amp;origin=https%3A%2F%2Fcad.itmo.ru&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1";
  return (
    <div className="videoBlock-wrapper">
      <div className='videoBlock'>
        {/* <ReactPlayer
          controls={true}
          className='react-player'
          url={videoModalState.url ?? ""}
          width='100%'
          height='100%'
          playing={false}
          style={{ borderRadius: '40px', overflow: 'hidden' }}
        /> */}
        <div style={styleDiv}>
          <iframe
            frameBorder="0"
            // allowFullScreen=""
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            title="Пример занятия"
            width="100%"
            height="100%"
            src={link_video}
            // src="https://rutube.ru/play/embed/af04353f0fb2534275930e71dec9225e?autoplay=0&amp;mute=0&amp;controls=1&amp;origin=https%3A%2F%2Fcad.itmo.ru&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1"
            id="widget2"
          >
          </iframe>
        </div>
      </div>
    </div>
  );
};
