import React from "react";
import { EducationFrameDto } from "../../../api/interfaces/frames/education/education-stage-dto";
import { getFileUrl } from "../../../api/url";

export enum EEducationFeatureType {
  PLAIN,
  VIDEO,
  SUMMARY,
  DOCUMENT
}

export interface IEducationFrameFeature {
  title: React.ReactNode;
  description: React.ReactNode;
  type: EEducationFeatureType;
  image: React.ReactNode;
}

export interface IEducationFrameStaticData {
  title: React.ReactNode;
  features: IEducationFrameFeature[];
}

export const educationFrameStaticDataOffline = (
  educationFrame: EducationFrameDto | null | undefined,
  showProperAskQuestionModal: () => void,
  proceedToFaqFrame: () => void,
  showProperSignupStateModal: () => void,
): IEducationFrameStaticData => ({
  title: <>Как <span>устроено</span> очное обучение в ИТМО</>,
  features: [
    {
      title: "Предварительная бесплатная консультация",
      description: <>
        Мы всегда рады вам помочь, облегчить выбор подходящих курсов и программ или ответить на вопросы.
        Получить бесплатную индивидуальную консультацию <span onClick={showProperAskQuestionModal}>вы можете здесь</span>.
        Также на сайте есть <span onClick={proceedToFaqFrame}>специальный
        раздел</span>, где мы собрали самые популярные вопросы от слушателей и подробно на них ответили.
      </>,
      type: EEducationFeatureType.PLAIN,
      image: <img
        className='educ-process-slider-column-img--content'
        src={getFileUrl(educationFrame?.offlineConsultationImageId)}
        alt=""
      />
    },
    {
      title: "Приём заявки и оформление документов",
      description: <>
        <span onClick={showProperSignupStateModal}>Оставьте заявку</span> на обучение, и мы пришлем все
        необходимое ответным письмом; чаще всего ответ от
        Центра приходит за 1-2 рабочих дня. К письму будут приложены шаблоны документов для официального
        зачисления на курс повышения квалификации Университета ИТМО, а также дальнейшие инструкции.
      </>,
      type: EEducationFeatureType.PLAIN,
      image: <img
        className='educ-process-slider-column-img--content'
        src={getFileUrl(educationFrame?.offlineDocsApplicationImageId)}
        alt=""
      />
    },
    {
      title: "Анкета выпускника и Удостоверение ИТМО",
      description: <>
        На последнем занятии слушателям выдается Удостоверение о повышении квалификации Университета ИТМО.
        Главное условие для получения документа – выполненные в полном объеме практические задания. Также мы
        просим выпускников заполнить небольшую анкету о качестве очного обучения.
      </>,
      type: EEducationFeatureType.DOCUMENT,
      image: <img
        className='educ-process-slider-column-img--content'
        src={getFileUrl(educationFrame?.offlineGraduationDocumentImageId)}
        alt=""
      />
    },
  ]
});

export const educationFrameStaticDataOnline = (
  educationFrame: EducationFrameDto | null | undefined
): IEducationFrameStaticData => ({
  title: <>Как <span>устроено</span> онлайн-обучение в ИТМО</>,
  features: [
    {
      title: <>
        24/7 доступ к системе на целый год
      </>,
      description: <>
        Покупая курс, вы получаете неограниченный доступ ко всем материалам и возможность сразу приступать к
        обучению. Лекции можно просматривать столько раз, сколько это необходимо, а также настраивать
        собственный темп их воспроизведения (быстрее/медленнее). Занимайтесь практикой по мере готовности —
        никаких дедлайнов по выполнению заданий не предусмотрено.
      </>,
      type: EEducationFeatureType.VIDEO,
      image: <img
        className='educ-process-slider-column-img--content'
        src={getFileUrl(educationFrame?.onlineAccessImageId)}
        alt=""
      />
    },
    {
      title: <>
        Идеальный баланс теории/практики
      </>,
      description: <>
        В зависимости от типа курса (практический/теоретический) в нем будут задания, которые проверяет
        куратор, и/или системные тесты, которые проверяются автоматически. Всю теорию можно осваивать
        обособленно, вне зависимости от результатов проверочных работ. Своевременное выполнение всех заданий
        курса гарантирует онлайн-выпускнику Удостоверение ИТМО.
      </>,
      type: EEducationFeatureType.PLAIN,
      image: <img
        className='educ-process-slider-column-img--content'
        src={getFileUrl(educationFrame?.onlineBalanceImageId)}
        alt=""
      />
    },
    {
      title: <>
        Помощь куратора и Телеграм-чат
      </>,
      description: <>
        Учебный Телеграм-чат — это вспомогательный инструмент для оперативной обратной связи с
        профессиональными кураторами курса. В чате слушатели получают помощь с освоением материала, ответы на
        любые вопросы по обучению и консультации по выполнению практических заданий. Кроме этого, чат дает
        возможность познакомиться с интересными специалистами из вашей профессии.
      </>,
      type: EEducationFeatureType.PLAIN,
      image: <img
        className='educ-process-slider-column-img--content'
        src={getFileUrl(educationFrame?.onlineSupervisorImageId)}
        alt=""
      />
    }
  ]
});
