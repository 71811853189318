import React, { SetStateAction } from 'react';
import { CoursesFilter, useCoursesFilter } from '../../frames/direction/courses-frame/filter/use-courses-filter';
import './index.css';
import { CourseAmountInfo } from '../../frames/direction/courses-frame';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { useSearchParams } from "react-router-dom";
import { CategoryDto } from "../../api/interfaces/category/category-dto";

export interface FilterModalState {
  coursesFilter: CoursesFilter;
}

interface FilterModalProps {
  filterModalState: FilterModalState;
  applyClick: Function;
  courseAmountInfo: CourseAmountInfo;

  selectedFormat: CourseFormat | undefined,
  selectedCategories: CategoryDto[],
  setSelectedFormat: React.Dispatch<SetStateAction<CourseFormat | undefined>>;
  setSelectedCategories: React.Dispatch<SetStateAction<CategoryDto[]>>;
}

export const FilterModalContent: React.FC<FilterModalProps> = (
  {
    filterModalState,
    applyClick,
    courseAmountInfo,
    selectedFormat,
    selectedCategories,
    setSelectedFormat,
    setSelectedCategories,
  }
) => {
  const [
    searchParams,
    setSearchParams
  ] = useSearchParams();

  const coursesFilter = useCoursesFilter(courseAmountInfo, selectedFormat, selectedCategories, false);

  return (
    <div className='filter-modal-area'>
      <div className='filter-modal-area-title'>
        <div className='filter-modal-area-title--text'>
          Фильтры
        </div>

        <div
          className='filter-modal-area-title--reset-button'
          onClick={() => coursesFilter.resetData()}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
            <path d='M11.6668 13L8.3335 16.3333L11.6668 19.6667' stroke='#0B68FE' strokeWidth='1.5'/>
            <path
              d='M4.94835 13.4165C4.28746 12.2718 4.03492 10.9366 4.23211 9.62961C4.42929 8.32262 5.06451 7.12134 6.0337 6.22258C7.00289 5.32381 8.24858 4.78085 9.5667 4.68263C10.8848 4.58441 12.1972 4.93677 13.2889 5.68197C14.3806 6.42717 15.1868 7.52104 15.5756 8.78437C15.9643 10.0477 15.9124 11.4056 15.4284 12.6356C14.9445 13.8656 14.0572 14.8948 12.9118 15.5545C11.7665 16.2143 10.431 16.4655 9.12423 16.267'
              stroke='#0B68FE' strokeWidth='1.5' strokeLinecap='round'/>
          </svg>

          Сброс
        </div>
      </div>

      {coursesFilter.CoursesFilter}

      <div
        className='filter-modal-area-title--apply-button'
        onClick={() => {
          const format = coursesFilter.selectedFormat?.toString() ?? [];
          const categories = coursesFilter.selectedCategories.map(categoryObject => categoryObject.type);

          setSearchParams({
            format,
            categories
          });

          applyClick();
        }}
      >
        <div className='filter-modal-area-title--apply-button--text'>
          Применить {!!coursesFilter.filtersAmount && <span>{`(${coursesFilter.filtersAmount})`}</span>}
        </div>
      </div>
    </div>
  );
};
