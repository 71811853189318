import { ifClassExists } from '../../utils/if-class-exists';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { CourseContext } from "../../context/course-context";
import { getCourseFormatName } from "../../api/interfaces/course/course-format";
import { CourseDto } from "../../api/interfaces/course/course-dto";
import "./index.css";
import { SuccessStatus } from "./success-status";
import { useRadioBlock } from "../../components/radio/use-radio-block";
import parse from "html-react-parser";
import { useSignupForm } from "../../forms/use-signup-form/use-signup-form";

export interface SignupModalState {
  tabIndex: number;
}

interface SignupModalProps {
  signupModalState: SignupModalState;
}

export const SignupModalContent: React.FC<SignupModalProps> = (
  {
    signupModalState
  }
) => {
  const [selectedTypeUserTabIndex, setSelectedTypeUserTabIndex] = useState(signupModalState.tabIndex);

  const {
    courseData
  } = useContext(CourseContext);

  const getCourseName = (course: CourseDto | undefined) => course ? `${parse(course.name)} (${getCourseFormatName(course.format)})` : "";

  const courseName = useMemo(() => getCourseName(courseData?.course) ?? "", [
    courseData
  ]);

  const course = courseData?.course;

  const formRef = useRef({} as HTMLFormElement);

  const {
    RelevantRadioBlock,
    selectedVariant: communicationMethod
  } = useRadioBlock(['По телефону', 'По почте'], 'typeCall');

  const {
    SignupForm,
    Buttons,
    responseStatus
  } = useSignupForm(course?.id, selectedTypeUserTabIndex);

  return (
    <div className='form-request'>
      {responseStatus !== 200 ? (
        <>
          <div className='modal__title'>Записаться на обучение</div>

          {/*<div className='form-request-course-title-area'>*/}
          {/*  <div className='form-request-course-title'>*/}
          {/*    {courseName}*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className='form-request-tab'>
            <div
              className={`form-request-tab__item ${ifClassExists('selected', selectedTypeUserTabIndex === 0)}`}
              onClick={() => setSelectedTypeUserTabIndex(0)}
            >
              Физическое лицо
            </div>

            <div
              className={`form-request-tab__item ${ifClassExists('selected', selectedTypeUserTabIndex === 1)}`}
              onClick={() => setSelectedTypeUserTabIndex(1)}
            >
              Юридическое лицо
            </div>
          </div>

          {/*<form*/}
          {/*  ref={formRef}*/}
          {/*  className='request-form'*/}
          {/*  onSubmit={event => {*/}
          {/*    event.preventDefault();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <input type='hidden' name='typeUser' value={selectedTypeUserTabIndex}/>*/}

          {/*  {selectedTypeUserTabIndex === 1 && (*/}
          {/*    <div className='request-form-block'>*/}
          {/*      <div className='request-form-block__title'>Информация об организации:</div>*/}
          {/*      <InputBlock*/}
          {/*        idInput='nameOrganization'*/}
          {/*        nameInput='nameOrganization'*/}
          {/*        labelText={*/}
          {/*          <>*/}
          {/*            Наименование организации <span>*</span>*/}
          {/*          </>*/}
          {/*        }*/}
          {/*        typeInput={'text'}*/}
          {/*      />*/}
          {/*      <InputBlock*/}
          {/*        idInput='countUser'*/}
          {/*        nameInput='countUser'*/}
          {/*        labelText={*/}
          {/*          <>*/}
          {/*            Количество человек <span>*</span>*/}
          {/*          </>*/}
          {/*        }*/}
          {/*        typeInput={'number'}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*  <div className='request-form-block'>*/}
          {/*    <div className='request-form-block__title'>Контактные данные:</div>*/}
          {/*    {selectedTypeUserTabIndex === 0 && (*/}
          {/*      <InputBlock*/}
          {/*        idInput='nameUser'*/}
          {/*        nameInput='nameUser'*/}
          {/*        labelText={*/}
          {/*          <>*/}
          {/*            Фамилия Имя Отчество <span>*</span>*/}
          {/*          </>*/}
          {/*        }*/}
          {/*        typeInput={'text'}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*    <InputBlock*/}
          {/*      idInput='telUser'*/}
          {/*      nameInput='telUser'*/}
          {/*      labelText={*/}
          {/*        <>*/}
          {/*          Телефон <span>*</span>*/}
          {/*        </>*/}
          {/*      }*/}
          {/*      typeInput={'text'}*/}
          {/*      textToPhone*/}
          {/*    />*/}
          {/*    <InputBlock*/}
          {/*      idInput='emailUser'*/}
          {/*      nameInput='emailUser'*/}
          {/*      labelText={*/}
          {/*        <>*/}
          {/*          Электронная почта <span>*</span>*/}
          {/*        </>*/}
          {/*      }*/}
          {/*      typeInput={'text'}*/}
          {/*      textToEmail*/}
          {/*    />*/}
          {/*    {selectedTypeUserTabIndex === 1 && (*/}
          {/*      <InputBlock*/}
          {/*        idInput='contactItem'*/}
          {/*        nameInput='contactItem'*/}
          {/*        labelText={*/}
          {/*          <>*/}
          {/*            Контактное лицо<span>*</span>*/}
          {/*          </>*/}
          {/*        }*/}
          {/*        typeInput={'text'}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </div>*/}

          {/*  <div className='request-form-block'>*/}
          {/*    <div className='request-form-block__title'>Выберите удобный способ связи:</div>*/}
          {/*    {RelevantRadioBlock}*/}
          {/*  </div>*/}

          {/*  <div*/}
          {/*    className='request-form-buttons'*/}
          {/*  >*/}
          {/*    <CustomButton*/}
          {/*      buttonType="button"*/}
          {/*      type={'button'}*/}
          {/*      loading={loading}*/}
          {/*      title={'Отправить заявку'}*/}
          {/*      classList={['button--blue', 'fsz16-lh20', 'pd-bl-but2']}*/}
          {/*      onClick={() => {*/}
          {/*        if (formRef.current.checkValidity()) {*/}
          {/*          postRequest({*/}
          {/*            data: {*/}
          {/*              name: (document.getElementById("nameUser") as HTMLInputElement)?.value || (document.getElementById("contactItem") as HTMLInputElement)?.value,*/}
          {/*              course: {*/}
          {/*                id: course?.id*/}
          {/*              },*/}
          {/*              email: (document.getElementById("emailUser") as HTMLInputElement)?.value,*/}
          {/*              phone: (document.getElementById("telUser") as HTMLInputElement)?.value,*/}
          {/*              organization: (document.getElementById("nameOrganization") as HTMLInputElement)?.value,*/}
          {/*              participants: (document.getElementById("countUser") as HTMLInputElement)?.value,*/}
          {/*              communicationMethod,*/}
          {/*              type: !!(document.getElementById("nameOrganization") as HTMLInputElement)?.value ? RequestType.legal_entity : RequestType.physical_person*/}
          {/*            } as RequestDto*/}
          {/*          });*/}
          {/*        }*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <p className='request-form__text'>Нажимая "Отправить заявку", вы принимаете <a href="https://itmo.ru/file/pages/79/personal_data_policy.pdf" target="_blank" rel="noreferrer">условия обработки персональных данных</a></p>*/}
          {/*  </div>*/}
          {/*</form>*/}

          {SignupForm}
          {Buttons}
        </>
      ) : (
        <SuccessStatus type="request"/>
      )}
    </div>
  );
};
