import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import './index.css';
import { Label } from '../label';
import { Arrow } from '../arrow';
import { colors } from '../../constants/colors';
import gsap from 'gsap';
import { CourseContext } from '../../context/course-context';
import { courseTypeFullNameMap } from '../../api/mapper/map';
import { ModalContext } from '../../context/modal-context';
import parse from "html-react-parser";
import { DirectionContext } from "../../context/direction-context";

interface BottomInfoZoneProps {
}

export const BottomInfoZone: React.FC<BottomInfoZoneProps> = () => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    direction
  } = useContext(DirectionContext);

  const {
    showProperSignupStateModal
  } = useContext(ModalContext);

  const initialScrollY = useRef(0);
  const bottomInfoZoneRef = useRef({} as HTMLDivElement);

  useEffect(() => {
    window.addEventListener('scroll', e => {
      if (window.userManualScroll) {
        const currentScroll = window.scrollY;

        if (currentScroll >= 0 && currentScroll <= document.documentElement.scrollHeight) {
          if (currentScroll - initialScrollY.current > 20) {
            initialScrollY.current = currentScroll;
            if (bottomInfoZoneRef.current) {
              bottomInfoZoneRef.current.style.transform = 'translate(0px, 100px)';
            }
          }

          if (initialScrollY.current - currentScroll > 20) {
            initialScrollY.current = currentScroll;

            if (bottomInfoZoneRef.current) {
              bottomInfoZoneRef.current.style.transform = 'translate(0px, 0px)';
            }
          }
        }
      }
    });
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      gsap.from('.bottom-info-zone', {
        translateY: 100,
        ease: 'bounce.in'
      });

      gsap.to('.bottom-info-zone', {
        translateY: 0
      });
    }, 1200);
  }, []);

  return course && (
    <div
      id='bottom-info-zone'
      ref={bottomInfoZoneRef}
      className='bottom-info-zone'
    >
      <div className='bottom-info-zone-wrapper'>
        <div
          className={`bottom-info-zone-wrapper-info`}>
          <Label
            classList={['labelBorder']}
            courseType={`${courseTypeFullNameMap(course.type, course.format)}`}
          />
          <div className={`bottom-info-zone-wrapper-info-title ${course.type.toLowerCase()} ${course.format.toLowerCase()}`}>
            {parse(course.name)}
          </div>
        </div>

        <div className='bottom-info-zone-wrapper-price'>
          <span className='bottom-info-zone-wrapper-price-value'>
            {course.priceFrame?.usualPriceValue} ₽
          </span>
          <div
            onClick={() => {
              window.ym(direction?.meta?.counter, 'reachGoal', `${direction?.meta?.signUpButtonYandexTargetName}`);

              showProperSignupStateModal({ tabIndex: 0 });
            }}
            className='bottom-info-zone-wrapper-signup-button'
          >
            <span className='bottom-info-zone-wrapper-signup-button-value'>
              Записаться
              <Arrow
                type='arrow-1'
                color={colors['--design-bim-primary-black']}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
