import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const useScrollToSection = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
    }
  }, [pathname, hash, key]);
}
