import React, { forwardRef, SetStateAction, useImperativeHandle } from 'react';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';
import { useFilterModal } from '../filter-modal/use-filter-modal';
import { FilterModalState } from '../filter-modal';
import { CourseAmountInfo } from '../../frames/direction/courses-frame';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { CategoryDto } from "../../api/interfaces/category/category-dto";

export const FilterModalWrapper = forwardRef((
  props: {
    applyClick: Function;
    courseAmountInfo: CourseAmountInfo;
    lastSelectedFormat: CourseFormat | undefined;
    lastSelectedCategories: CategoryDto[];
    setSelectedFormat: React.Dispatch<SetStateAction<CourseFormat | undefined>>;
    setSelectedCategories: React.Dispatch<SetStateAction<CategoryDto[]>>;
  },
  ref
) => {
  const {
    FilterModal,
    showModal,
    showStatedModal,
    setFilterModalState
  } = useFilterModal(() => {
    removeElementClass(document.body, 'modal-open');
  }, () => {
    props.applyClick();
  }, props.courseAmountInfo, props.lastSelectedFormat, props.lastSelectedCategories, props.setSelectedFormat, props.setSelectedCategories);

  useImperativeHandle(ref, () => ({
    showModal: () => showModal(),
    showStatedModal: (filterModalState: FilterModalState) => showStatedModal(filterModalState),
    setFilterModalState: (filterModalState: FilterModalState) => setFilterModalState(filterModalState)
  }));

  return FilterModal;
});
