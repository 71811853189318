export enum CourseFormat {
  online = 'online',
  offline = 'offline'
}

export const getCourseFormatName = (courseFormat: CourseFormat): string => {
  switch (courseFormat) {
    case CourseFormat.online:
      return "Онлайн"
    case CourseFormat.offline:
      return "В классе";
  }
}

export const getCourseFormat = (
  courseFormat: string | undefined | null
) => {
  switch (courseFormat) {
    case 'offline':
      return CourseFormat.offline;
    case 'online':
      return CourseFormat.online;
    default:
      return undefined;
  }
}
