import React, { useContext } from 'react';
import { IdProps } from '../../interfaces/id-props';
import { Frame } from '../../components/frame';
import './index.css';
import { DirectionContext } from "../../context/direction-context";
import parse from "html-react-parser";
import { getFileUrl } from "../../api/url";

interface AdditionalQuestionsFrameProps extends IdProps {

}

export const AdditionalQuestionsFrame: React.FC<AdditionalQuestionsFrameProps> = (
  {
    id
  }
) => {
  const {
    direction
  } = useContext(DirectionContext);

  return (
    <Frame
      id={id}
      bgClassName='outer-background-margin'
      coverClassName={`additional-questions-frame`}
    >
      <div className='additional-questions-frame'>
        <div className='additional-questions-frame-left'>
          <div className='additional-questions-description-title'>
            {parse(direction?.additionalQuestionsFrame?.title ?? "")}
          </div>
          <div className='additional-questions-description-description'>
            {parse(direction?.additionalQuestionsFrame?.description ?? "")}
          </div>
        </div>

        <div className='additional-questions-frame-right'>
          <img
            src={getFileUrl(direction?.additionalQuestionsFrame?.imageId)}
            alt={direction?.additionalQuestionsFrame?.imageId}
          />
        </div>
      </div>
    </Frame>
  );
};
