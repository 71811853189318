import React, { useContext } from 'react';
import './index.css';
import { DirectionContext } from '../../../../context/direction-context';
import parse from 'html-react-parser';
import { colors } from "../../../../constants/colors";

export const InfoTags: React.FC = () => {
  const {
    direction
  } = useContext(DirectionContext);

  return (
    <div className='info-tags'>
      {(direction?.tags ?? []).map((tag, index) => (
        <div key={index} className='info-tag'>
          <svg className='info-tag-icon' width="20" height="16" viewBox="0 0 20 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.1357 7.19334L19.0728 7.19363C18.0193 7.1852 16.554 6.92163 15.3687 6.01375C14.2183 5.13265 13.2261 3.56472 13.2261 0.714844H11.7261C11.7261 3.95317 12.8774 5.98948 14.4451 7.1958H0.863281V8.68896H14.5521C14.5258 8.70884 14.4996 8.72897 14.4735 8.74934C12.8966 9.97934 11.7148 12.0385 11.7148 15.2844H13.2148C13.2148 12.4457 14.2248 10.8457 15.3961 9.93207C16.6006 8.99251 18.0728 8.70468 19.0762 8.69367L19.1223 8.69383L19.1223 8.69341L19.1357 8.69334V8.68896V7.1958V7.19334Z"
                  fill={`${colors['--brand-itmo-green-lizard']}`}/>
          </svg>

          <div className='info-tag-text'>
            {parse(tag)}
          </div>
        </div>
      ))}
    </div>
  );
};
