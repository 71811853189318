import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts/index.css';
import { BrowserRouter } from 'react-router-dom';
import { Styles } from "./styles";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.userManualScroll = true;

document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

window.addEventListener('resize', () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});

root.render(
  <BrowserRouter>
    <Styles/>
  </BrowserRouter>
);
