import { IdProps } from '../../interfaces/id-props';
import React, { useContext, useMemo } from 'react';
import { Frame } from '../../components/frame';
import { CourseContext } from '../../context/course-context';
import './index.css';
import parse from 'html-react-parser';
import { getFileUrl } from '../../api/url';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

interface TeachersFrameProps extends IdProps {
}

export const TeachersFrame: React.FC<TeachersFrameProps> = (
  {
    id
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const TeachersFrameContent = useMemo(() => (
    <div className='teachers-frame'>
      <div className='teachers-frame-area'>
        <div className='teachers-frame-title'>
          {parse(course!.teachersFrame?.description ?? "")}
        </div>

        <div className='teachers-frame-teachers'>
          {course!.authors.map((author, index) => (
            <div className='teachers-frame-teacher_block'>
              <Tippy
                className='teacher-tooltip-wrapper'
                content={
                  <div className='teacher-tooltip'>
                    <div className='teacher-tooltip-name'>
                      {author.name}
                    </div>

                    <div className='teacher-tooltip-info'>
                      {author.description}
                    </div>
                  </div>
                }
              >
                <img
                  className='teachers-frame-teacher'
                  src={getFileUrl(author.imageId)}
                  alt={author.imageId}
                />
              </Tippy>
            </div>
          ))}
        </div>

      </div>
    </div>
  ), [
    course
  ]);

  return course!.authors.length > 1 && (
    <Frame
      id={id}
      sectionClassName={'with-cover'}
      bgClassName={'bg-brand-itmo-bluetiful'}
      coverClassName={` bg-design-bim-true-white`}
    >
      {TeachersFrameContent}
    </Frame>
  );
};
