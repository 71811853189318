import React from 'react';

export const ArrowRight = () => (
  <svg width='56' height='57' viewBox='0 0 56 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle
      cx='28'
      cy='28'
      r='27.25'
      transform='matrix(-1 0 0 1 56 0.333008)'
      fill='white'
      stroke='#0D0D0D'
      strokeWidth='1.5'
    />
    <path
      d='M39.3359 28.3141H16.66M39.3359 28.3141C35.1628 28.0843 26.7636 27.8591 26.7636 17.3271M39.3359 28.3141C35.1761 28.5368 26.7636 28.8068 26.7636 39.3387'
      stroke='#0D0D0D'
      strokeWidth='1.5'
    />
  </svg>
);
