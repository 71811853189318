import s from './style.module.css'

export default function Shedile_block() {
    return (
        <div className={s.shedule_block}>
            <div className={s.shedule_content}>
                <div className={s.shedule_header}>
                    <span>Период</span> обучения
                </div>
                <div className={s.shedule_dates}>

                    <div className={s.shedule_date}>
                        <p className={s.shedule_name}>
                            <span>начало</span> 
                            <svg class="arrow-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4912_97838)"><path d="M22.5 12.9994L16.8829 8.09033V12.1812H2.25V13.8176H16.8829V17.9085L22.5 12.9994Z" fill="#ffffff"></path></g><defs><clipPath id="clip0_4912_97838"><rect width="20.25" height="9.81818" fill="white" transform="translate(2.25 8.09131)"></rect></clipPath></defs></svg>
                            <span>27 января 2025</span>
                        </p>
                        <p className={s.shedule_name}>
                            <span>завершение</span> 
                            <svg class="arrow-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4912_97838)"><path d="M22.5 12.9994L16.8829 8.09033V12.1812H2.25V13.8176H16.8829V17.9085L22.5 12.9994Z" fill="#ffffff"></path></g><defs><clipPath id="clip0_4912_97838"><rect width="20.25" height="9.81818" fill="white" transform="translate(2.25 8.09131)"></rect></clipPath></defs></svg>
                            <span>10 июля 2025</span>
                        </p>
                        {/* <p className={s.shedule_info}>
                            <span>27 января 2025</span>
                            <svg class="arrow-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4912_97838)"><path d="M22.5 12.9994L16.8829 8.09033V12.1812H2.25V13.8176H16.8829V17.9085L22.5 12.9994Z" fill="#ffffff"></path></g><defs><clipPath id="clip0_4912_97838"><rect width="20.25" height="9.81818" fill="white" transform="translate(2.25 8.09131)"></rect></clipPath></defs></svg>
                            <span>10 июля 2025</span>
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
