import React, { useMemo, useState } from 'react';
import { useModal } from '../../hooks/use-modal';
import { DocumentModalContent, DocumentModalState } from './index';

export const useDocumentModal = (
  onClick?: () => void
) => {
  const [
    documentModalState,
    setDocumentModalState
  ] = useState({} as DocumentModalState);

  const {
    Modal,
    showModal,
  } = useModal('document-modal-frame-id', onClick);

  const showStatedModal = (documentModalState: DocumentModalState) => {
    setDocumentModalState(documentModalState);
    showModal();
  }

  const documentModal = useMemo(() => (
    <Modal
      classList={['modalMain-wrapper--video', 'modalMain-wrapper--doc']}
    >
      <DocumentModalContent
        documentModalState={documentModalState}
      />
    </Modal>
  ), [
    Modal,
    documentModalState
  ]);

  return (
    {
      documentModal,
      showModal,
      showStatedModal
    }
  );
};
