import React from 'react';
import { colors } from '../../constants/colors';

interface PointProps {
  color?: string;
}

export const Point: React.FC<PointProps> = ({
                                              color = colors['--design-bim-true-white'],
                                            }) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M12.398 20.304C13.881 19.5348 19 16.5163 19 11.5C19 7.63401 15.866 4.5 12 4.5C8.13401 4.5 5 7.63401 5 11.5C5 16.5163 10.119 19.5348 11.602 20.304C11.8548 20.4351 12.1452 20.4351 12.398 20.304ZM12 14.5C13.6569 14.5 15 13.1569 15 11.5C15 9.84315 13.6569 8.5 12 8.5C10.3431 8.5 9 9.84315 9 11.5C9 13.1569 10.3431 14.5 12 14.5Z"
          fill={color}/>
  </svg>);
}
