import React, { MutableRefObject, useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { CoursesEnumeration } from './index';
import { CourseAmountInfo } from '../index';
import gsap from 'gsap';
import { DirectionContext } from '../../../../context/direction-context';
import { CoursesFilter } from "../filter/use-courses-filter";
import { SplashScreenContext } from "../../../../context/splash-screen-context";
import { nearestCategory } from "../../../../api/interfaces/category/category-dto";
import { InfoFavouriteCourse } from "../../info-frame/info-favourite-course";

interface DynamicCoursesEnumerationProps {
  courseAmountInfo: CourseAmountInfo;
  coursesFilter: CoursesFilter;
  dynamicCoursesDivHeight: MutableRefObject<number>;
  allCatalog: boolean;
  specificEnabled: boolean;
}

export const DynamicCoursesEnumeration: React.FC<DynamicCoursesEnumerationProps> = (
  {
    courseAmountInfo,
    coursesFilter,
    dynamicCoursesDivHeight,
    allCatalog,
    specificEnabled
  }
) => {
  const {
    direction
  } = useContext(DirectionContext);

  const favouriteCourse = useMemo(() => {
    if (direction?.courses) {
      return direction.courses.find(course => course.fullPath === direction.favouriteCoursePath);
    }
  }, [direction]);

  useLayoutEffect(() => {
    gsap.from(`.courses-enumeration-area`, {
      translateY: 100,
      opacity: 0,
      ease: 'bounce.in'
    });

    gsap.to(`.courses-enumeration-area`, {
      translateY: 0,
      opacity: 1
    });
  }, [
    courseAmountInfo,
    coursesFilter.selectedFormat,
    coursesFilter.selectedCategories
  ]);

  const dynamicCoursesItemsRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    dynamicCoursesItemsRef.current = dynamicCoursesItemsRef.current.slice(0, Array.from(courseAmountInfo.categoriesAmountsMap).length);
  }, [
    courseAmountInfo.categoriesAmountsMap
  ]);

  const {
    setLoading
  } = useContext(SplashScreenContext);

  const favouriteCoursePreCategoryIndex = useMemo(() => {
    if (courseAmountInfo.categoriesAmountsMap && direction?.courses.length) {
      let coursesCount = 0;
      const coursesAmount = Array.from(courseAmountInfo.categoriesAmountsMap).map(([category, courses], index) => courses.length);

      for (let i = 0; i < coursesAmount.length - 1; i++) {
        if (coursesAmount.length === 1) {
          return i;
        }

        if (coursesCount + coursesAmount[i] < direction?.courses.length / 2) {
          coursesCount += coursesAmount[i];
        } else {
          return i;
        }

        if (i === coursesAmount.length - 2) {
          return i;
        }
      }
    }
  }, [courseAmountInfo.categoriesAmountsMap, direction?.courses.length]);

  return (
    <>
      {!allCatalog && coursesFilter.selectedCategories.map(selectedCategory => selectedCategory.type).includes("nearest") && (
        <CoursesEnumeration
          category={nearestCategory}
          courses={coursesFilter.nearestOfflineCourses}
          itemsRef={dynamicCoursesItemsRef}
          index={0}
        />
      )}

      {Array.from(courseAmountInfo.categoriesAmountsMap).map(([category, courses], index) => {
        if (allCatalog) {
          return (
            <>
              {favouriteCoursePreCategoryIndex === index && (
                <InfoFavouriteCourse
                  id='info-favourite-course'
                  favouriteCourse={favouriteCourse}
                  setLoading={setLoading}
                />
              )}
              <CoursesEnumeration
                category={category}
                courses={courses}
                itemsRef={dynamicCoursesItemsRef}
                index={index}
              />
            </>
          );
        }

        const relevantCourses = !coursesFilter.selectedFormat ? courses : courses.filter(course => course.format === coursesFilter.selectedFormat);

        return !!relevantCourses.length && (!coursesFilter.selectedCategories.length || coursesFilter.selectedCategories.includes(category)) && (
          <>
            {!specificEnabled && favouriteCoursePreCategoryIndex === index && (
              <InfoFavouriteCourse
                id='info-favourite-course'
                favouriteCourse={favouriteCourse}
                setLoading={setLoading}
              />
            )}
            <CoursesEnumeration
              category={category}
              courses={relevantCourses}
              itemsRef={dynamicCoursesItemsRef}
              index={index}
            />
          </>
        );
      })}
    </>
  );
};
