import React, { useMemo } from 'react';
import './index.css';
import { Arrow } from '../arrow';
import { colors } from '../../constants/colors';
import { ClassListProps } from '../../interfaces/class-list-props';
import { StyleProps } from '../../interfaces/style-props';
import parse from 'html-react-parser';

interface CardListProps extends ClassListProps, StyleProps {
  arrowColor?: string;
  points: string[];
  icon?: "arrow" | "plus";
  className?: string | undefined;
}

const Plus = (
  <svg className="plus-icon" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
       fill="none">
    <path d="M3 16.48H16.92V4H23.88V16.48H37.72V23.44H23.88V36.4H16.92V23.44H3V16.48Z" fill="#00CCFF"/>
  </svg>
);

export const CardList: React.FC<CardListProps> = ({
                                                    classList = [],
                                                    arrowColor = colors['--design-bim-true-white'],
                                                    style,
                                                    points,
                                                    icon = "arrow",
                                                    className
                                                  }) => {
  const ItemIcon = useMemo(() => {
    switch (icon) {
      case "arrow":
        return (
          <Arrow
            type='arrow-2'
            color={arrowColor!}
          />
        );
      case "plus":
        return Plus;
    }
  }, [arrowColor, icon]);

  return (
    <div
      style={style}
      className={`card-list-area ${className}`}
    >
      {points.map((point, index) => (
        <div className='card-list-item' key={index}>
          {ItemIcon}

          <div className={`card-list-item-text ${classList?.join(' ')}`}>
            <>{parse(point)}</>
          </div>
        </div>
      ))}
    </div>
  );
};
