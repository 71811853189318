import React, { createContext } from 'react';
import { CourseDto } from '../api/interfaces/course/course-dto';

export interface CourseData {
  course: CourseDto | undefined;
  allCourses: CourseDto[] | undefined;
}

export interface CourseContextProps {
  courseData: CourseData | undefined;
  setCourseData: React.Dispatch<CourseData>;
}

export const CourseContext = createContext({} as CourseContextProps);
