import React, { useCallback } from 'react';
import { Frame } from '../../components/frame';
import { IdProps } from '../../interfaces/id-props';
import './index.css';

interface TaxesFrameProps extends IdProps {
  tax: number;
}

export const TaxesFrame: React.FC<TaxesFrameProps> = (
  {
    id,
    tax,
  }
) => {
  const proceedToFaqFrame = useCallback(() => {
    document.getElementById('faq-frame')?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Frame
      id={id}
      bgClassName='outer-background-margin'
      coverClassName={`taxes-frame bg-design-bim-gradient-blue`}
    >
      <div className='taxes-frame'>
        <div className='taxes-frame-left'>
          <div className='taxes-description-title'>
            Получите налоговый вычет и <span className='text-brand-itmo-green-lizard'>верните 13%</span> от
            стоимости обучения
          </div>
          <div className='taxes-description-description'>
            Государство освободит финальные расходы на обучение в нашем Центре от НДФЛ и вернет вам 13% от
            стоимости курса. Подробнее в <span onClick={proceedToFaqFrame}>этом разделе</span>.
          </div>
        </div>

        <div className='taxes-frame-right'>
          <div className='taxes-frame-tax-value'>{tax}%</div>
          <svg xmlns='http://www.w3.org/2000/svg' width='189' height='191' viewBox='0 0 189 191' fill='none'>
            <path
              d='M25.7547 29.1571C22.3658 31.94 22.3658 31.94 18.6349 28.4017C14.904 24.8635 12.559 22.3258 12.559 22.3258C9.93103 19.6979 9.58169 23.7526 9.58169 23.7526L0.0127609 66.4532C0.00613613 66.5724 -0.156123 68.4151 1.87 68.0215L44.1244 58.5022C44.1244 58.5022 48.4833 58.2501 45.6679 55.4347L39.5216 49.2884C35.7488 44.3313 35.7488 44.3313 39.4909 41.4563C69.2119 11.7353 117.679 11.8417 147.531 41.6943C177.386 71.5489 177.388 120.125 147.533 149.98C117.679 179.834 69.101 179.834 39.2463 149.979C31.2656 141.998 25.277 132.621 21.4466 122.107C20.9907 120.846 20.2624 119.701 19.3137 118.753C16.7917 116.231 12.9973 115.389 9.64709 116.609C4.87959 118.348 2.41306 123.64 4.15027 128.408C8.90742 141.463 16.3356 153.1 26.2303 162.994C63.2618 200.026 123.517 200.027 160.548 162.995C197.58 125.964 197.579 65.7085 160.548 28.677C123.664 -8.20719 63.5296 -8.32558 26.501 28.415C26.5002 28.4175 25.9405 28.9713 25.7547 29.1571Z'
              fill='#00CCFF'/>
          </svg>
        </div>
      </div>
    </Frame>
  );
};
