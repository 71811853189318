import React from 'react';

export const BackgroundLine = () => (
  <svg width='407' height='414' viewBox='0 0 407 414' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d_3329_8625)'>
      <path
        d='M67.5674 322.118C76.8424 301.114 93.8859 277.897 123.912 269.631C166.21 257.987 200.806 272.631 226.332 283.435C239.858 289.16 254.004 295.82 268.915 296.115C277.908 296.294 288.101 293.873 300.961 284.051C308.931 277.964 317.407 263.068 319.56 222.213C321.32 188.807 334.992 157.642 358.21 137.572C382.164 116.865 414.979 109.299 449.678 122.307L434.18 163.649C414.896 156.42 399.046 160.633 387.084 170.974C374.384 181.952 364.886 201.103 363.651 224.536C361.396 267.336 352.361 300.352 327.76 319.14C307.695 334.464 287.898 340.653 268.04 340.259C247.495 339.852 228.42 332.145 209.721 324.392C183.082 313.348 157.636 299.079 131.968 313.255C76.3549 343.969 93.2583 439.77 111.239 486.075L62.5849 497.265C62.5849 497.265 54.323 473.696 52.9521 455.61C49.7631 413.541 50.1285 361.609 67.5674 322.118Z'
        fill='white'/>
    </g>
    <circle cx='248.405' cy='315.346' r='21.6848' transform='rotate(16.0888 248.405 315.346)'
            fill='#0D0D0D'/>
    <defs>
      <filter id='filter0_d_3329_8625' x='34.0385' y='98.5151' width='433.006' height='416.115'
              filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix'/>
        <feColorMatrix in='SourceAlpha' type='matrix'
                       values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
        <feOffset/>
        <feGaussianBlur stdDeviation='8.68288'/>
        <feComposite in2='hardAlpha' operator='out'/>
        <feColorMatrix type='matrix'
                       values='0 0 0 0 0.894118 0 0 0 0 0.929412 0 0 0 0 0.988235 0 0 0 0.8 0'/>
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3329_8625'/>
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3329_8625' result='shape'/>
      </filter>
    </defs>
  </svg>
);
