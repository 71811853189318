import React, { useMemo, useState } from 'react';
import { useModal } from '../../hooks/use-modal';
import { ReviewModalContent, ReviewModalState } from './index';

export const useFeedbackModal = (
  onClick?: () => void
) => {
  const [
    feedbackModalState,
    setFeedbackModalState
  ] = useState({} as ReviewModalState);

  const {
    Modal,
    showModal,
  } = useModal('feedback-modal-frame-id', onClick);

  const showStatedModal = (feedbackModalState: ReviewModalState) => {
    setFeedbackModalState(feedbackModalState);
    showModal();
  }

  const FeedbackModal = useMemo(() => (
    <Modal
      classList={['modalMain-wrapper--comment']}
    >
      <ReviewModalContent
        reviewModalState={feedbackModalState}
      />
    </Modal>
  ), [
    Modal,
    feedbackModalState
  ]);

  return (
    {
      FeedbackModal,
      showStatedModal
    }
  );
};
