import React, { useMemo } from 'react';
import './index.css';
import { Arrow } from '../arrow';
import { ClassListProps } from '../../interfaces/class-list-props';
import { Loader } from "../loader";

type ButtonType = "link" | "button";

interface ButtonProps extends ClassListProps {
  buttonType: "button" | "submit";
  type: ButtonType;
  loading?: boolean;
  title: string | undefined;
  classList?: Array<string>;
  onClick?: (() => void) | undefined;
  link?: string;
  arrowVisible?: boolean;
  arrowColor?: string;
  form?: string;
  arrowClassName?: string;
}

export const CustomButton: React.FC<ButtonProps> = (
  {
    form,
    buttonType,
    type,
    loading = false,
    classList = [],
    title,
    onClick = () => {
    },
    link,
    arrowVisible = false,
    arrowColor = "#fff",
    arrowClassName
  }
) => {
  return useMemo(() => {
    const commonProps: React.HTMLAttributes<HTMLElement> = {
      className: `button no-wrap ${classList?.join(' ')}`
    };

    switch (type) {
      case "link":
        return (
          <a
            onClick={onClick}
            href={link}
            {...commonProps}
          >
            {loading && <Loader className='splash-screen--button'/>}
            {title}
            {arrowVisible ? <Arrow className={arrowClassName} color={arrowColor as string} type="arrow-1"/> : ''}
          </a>
        );
      case "button":
        return (
          <button
            form={form}
            type={buttonType}
            onClick={onClick}
            {...commonProps}
          >
            {loading && <Loader className='splash-screen--button'/>}
            {title}
            {arrowVisible ? <Arrow className={arrowClassName} color={arrowColor as string} type="arrow-1"/> : ''}
          </button>
        );
    }
  }, [
    form,
    buttonType,
    loading,
    arrowColor,
    arrowVisible,
    classList,
    onClick,
    link,
    title,
    type
  ]);
}
