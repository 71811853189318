import React, { useContext } from 'react';
import './index.css';
import { CourseContext } from '../../context/course-context';

interface LocationModalProps {
}

export const LocationModalContent: React.FC<LocationModalProps> = (
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  return (
    <div
      className='location-modal'
    >
      <iframe
        title='Отзывы'
        className='yandex-reviews'
        src={"https://yandex.ru/maps-reviews-widget/103342218698?comments"}
      />

      <iframe
        title='Карты'
        className='yandex-map'
        src={"https://yandex.ru/map-widget/v1/?um=constructor%3A651bb28dfe1d06e6cd12d9ac63e737a82861bb6a08556392b90fe9b35bdd77c5&source=constructor"}
      />
    </div>
  );
};
