import { forwardRef, useImperativeHandle } from 'react';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';
import { useAskQuestionModal } from '../ask-question-modal/use-ask-question-modal';

export const AskQuestionModalWrapper = forwardRef((
  props,
  ref
) => {
  const {
    AskQuestionModal,
    showModal
  } = useAskQuestionModal(() => {
    removeElementClass(document.body, 'modal-open');
  });

  useImperativeHandle(ref, () => ({
    showModal: () => showModal()
  }));

  return AskQuestionModal;
});
