import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import { ifClassExists } from '../../utils/if-class-exists';
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useTrigger } from '../../hooks/use-trigger';
import { IdProps } from '../../interfaces/id-props';

import './index.css';
import { CourseContext } from '../../context/course-context';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { CustomButton } from '../../components/button';
import { IEducationButton } from '../../interfaces/education-stages';
import { ModalContext } from '../../context/modal-context';
import { CourseType } from '../../api/interfaces/course/course-type';
import { educationStagesFrameStaticDataOffline, educationStagesFrameStaticDataPlainOnline, educationStagesFrameStaticDataRefresherOnline, EEducationStageType } from "./static-data/education-stages-frame-static-data";
import { useNavigate } from "react-router-dom";
import { RelevantLink } from "../../components/relevant-link";
import { DirectionContext } from "../../context/direction-context";
import TestAccessCourse from '../../components/TestAccessCourse/TestAccessCourse';


interface EducationStagesFrameProps extends IdProps {
  onClick?: () => void;
}

const ArrowLeft = (
  <svg width='56' height='56' viewBox='0 0 56 56' fill='none'
       xmlns='http://www.w3.org/2000/svg'>
    <circle cx='28' cy='28' r='27.25' transform='matrix(-1 0 0 1 56 0)' fill='white'
            stroke='#0D0D0D' strokeWidth='1.5'/>
    <path
      d='M16.6619 27.9811H39.3378M16.6619 27.9811C20.835 27.7513 29.2342 27.5261 29.2342 16.9941M16.6619 27.9811C20.8217 28.2038 29.2342 28.4737 29.2342 39.0057'
      stroke='#0D0D0D' strokeWidth='1.5'/>
  </svg>
);

const ArrowRight = (
  <svg width='56' height='57' viewBox='0 0 56 57' fill='none'
       xmlns='http://www.w3.org/2000/svg'>
    <circle cx='28' cy='28' r='27.25' transform='matrix(-1 0 0 1 56 0.333008)' fill='white'
            stroke='#0D0D0D' strokeWidth='1.5'/>
    <path
      d='M39.3359 28.3141H16.66M39.3359 28.3141C35.1628 28.0843 26.7636 27.8591 26.7636 17.3271M39.3359 28.3141C35.1761 28.5368 26.7636 28.8068 26.7636 39.3387'
      stroke='#0D0D0D' strokeWidth='1.5'/>
  </svg>
);

const buttonsTypeMap: Map<EEducationStageType, IEducationButton[]> = new Map<EEducationStageType, IEducationButton[]>([
    [
      EEducationStageType.SIGNUP, [{
      id: 'application-signup-button',
      content: ((_1, _2, onClick) => (
        <div>
          <CustomButton
            buttonType={"button"}
            onClick={onClick}
            classList={[
              'bg-brand-itmo-bluetiful',
              'color--online-color-text',
              'border--offline-color',
              'pd-bl-but',
              'fsz16-lh20'
            ]}
            arrowVisible
            type='button'
            title='Оставить заявку'
            arrowClassName={"color--online-color-text"}
          />
        </div>
      ))
    }
    ]],
    [EEducationStageType.PLAIN, []],
    [EEducationStageType.DOCUMENTS, [
      {
        id: 'download-document-physical-person',
        content: ((physicalPersonDocumentLink, _, onClick) => (
          <RelevantLink
            to={physicalPersonDocumentLink}
            relative={"route"}
            target={"_blank"}
          >
            <div>
              <CustomButton
                buttonType={"button"}
                onClick={onClick}
                classList={[
                  'bg-brand-itmo-bluetiful',
                  'color--online-color-text',
                  'border--offline-color',
                  'pd-bl-but',
                  'fsz16-lh20'
                ]}
                type='link'
                title='Скачать договор (для физ.лиц)'
              />
            </div>
          </RelevantLink>
        ))
      },
      {
        id: 'download-document-legal-entity',
        content: ((_, legalEntityDocumentLink, onClick) => (
          <RelevantLink
            to={legalEntityDocumentLink}
            relative={"route"}
            target={"_blank"}
          >
            <div>
              <CustomButton
                buttonType={"button"}
                onClick={onClick}
                classList={[
                  'bg-brand-itmo-bluetiful',
                  'color--online-color-text',
                  'border--offline-color',
                  'pd-bl-but',
                  'fsz16-lh20'
                ]}
                type='link'
                title='Скачать договор (для юр.лиц)'
              />
            </div>
          </RelevantLink>
        ))
      }
    ]
    ]
  ]
);

export const EducationStagesFrame: React.FC<EducationStagesFrameProps> = (
  {
    id
  }
) => {
  const {
    direction
  } = useContext(DirectionContext);

  const {
    showProperSignupStateModal
  } = useContext(ModalContext);

  const navigate = useNavigate();

  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const buttonsMap: Map<string, () => void> = useMemo(() => new Map([
    ['application-signup-button', () => {
      window.ym(direction?.meta?.counter, 'reachGoal', `${direction?.meta?.signUpButtonYandexTargetName}`);

      showProperSignupStateModal({ tabIndex: 0 })
    }],
  ]), [
    showProperSignupStateModal,
    direction?.meta?.counter,
    direction?.meta?.signUpButtonYandexTargetName
  ]);

  const [
    selectedEducationStagesTabIndex,
    setSelectedEducationStagesTabIndex
  ] = useState(0);

  const [
    swiperVisible, {
      onHandler: showSwiper,
      offHandler: hideSwiper
    }
  ] = useTrigger(false);

  const resizeHandler = useCallback(() => {
    let clientWidth = window.innerWidth;

    if (clientWidth < 577) {
      showSwiper();
    } else {
      hideSwiper();
    }
  }, [hideSwiper, showSwiper]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, [
    resizeHandler
  ]);

  const relevantEducationStagesFrameStaticData = useMemo(() => {
    switch (course?.format) {
      case CourseFormat.online:
        switch (course?.type) {
          case CourseType.plain:
            return educationStagesFrameStaticDataPlainOnline(direction?.educationStagesFrame);
          case CourseType.refresher:
            return educationStagesFrameStaticDataRefresherOnline(direction?.educationStagesFrame);
        }
        break;
      case CourseFormat.offline:
        switch (course?.type) {
          case CourseType.plain:
            return educationStagesFrameStaticDataOffline(direction?.educationStagesFrame);
          case CourseType.refresher:
            return educationStagesFrameStaticDataOffline(direction?.educationStagesFrame);
        }
    }
  }, [course, direction?.educationStagesFrame]);

  const ESTabs = useMemo(() => {
    switch (course!.format) {
      case CourseFormat.online:
        return (
          <div className='es-tabs'>
            {relevantEducationStagesFrameStaticData?.tabs.map((educationStagesTab, index) => (
              <div
                className={`es-tab ${ifClassExists('selected', selectedEducationStagesTabIndex === index)}`}
                onClick={() => setSelectedEducationStagesTabIndex(index)}
              >
                <div className='es-tab-text'>
                  <div className='es-stage-name'>
                    {educationStagesTab.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
    }
  }, [course, selectedEducationStagesTabIndex, relevantEducationStagesFrameStaticData]);

  const EducationStagesFrameContent = useMemo(() => (
    <div className='education-stages-frame-content'>
      <BorderedTitle
        titleClassList={[
          'text-design-bim-true-white'
        ]}
        descriptionClassList={[
          'text-design-bim-true-white'
        ]}
        description={relevantEducationStagesFrameStaticData?.titleDescription}
      >
        {relevantEducationStagesFrameStaticData?.title}
      </BorderedTitle>

      <div className='es-tabs-area'>
        {ESTabs}

        {swiperVisible ?
          <Swiper
            className='swiper-WidthFull'
            spaceBetween={50}
            pagination={{
              type: 'fraction',
              el: '.swiper-buttonCont-pagination',
              clickable: true,
              renderFraction: (currentClass: string, totalClass: string) => (
                '<span class="' + currentClass + '"></span>' +
                ' из ' +
                '<span class="' + totalClass + '"></span>'
              )
            }}
            navigation={{
              nextEl: '.swiper-buttonCont-next',
              prevEl: '.swiper-buttonCont-prev'
            }}
            modules={[
              Pagination,
              Navigation
            ]}
          >
            {relevantEducationStagesFrameStaticData?.tabs[selectedEducationStagesTabIndex].stages.map((stage, index) => (
              <SwiperSlide>
                <div className='es-stage'>
                  <div className='es-stage-leftSide'>
                    <div className='es-info-area'>
                      <div className='es-info-index'>
                        {index + 1}
                      </div>

                      <div className='es-info'>
                        <div className='es-info-title'>
                          {stage.title}
                        </div>

                        <div className='es-info-description'>
                          {stage.description}
                        </div>

                        <div className='es-info-buttons'>
                          {buttonsTypeMap.get(stage.type)?.map((button: IEducationButton) => button.content(course?.physicalPersonDocumentLink, course?.legalEntityDocumentLink, buttonsMap.get(button.id)))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='es-stage-rightSide'>
                    {stage.image}
                  </div>
                </div>
              </SwiperSlide>
            ))}

            <div className='swiper-buttonCont'>
              <div className='swiper-buttonCont-button swiper-buttonCont-prev'>
                {ArrowLeft}
              </div>

              <div className='swiper-buttonCont-pagination'></div>

              <div className='swiper-buttonCont-button swiper-buttonCont-next'>
                {ArrowRight}
              </div>
            </div>
          </Swiper> :
          <div className='es-stages-area'>
            {relevantEducationStagesFrameStaticData?.tabs[selectedEducationStagesTabIndex].stages.map((stage, index) => (
              <div className='es-stage'>
                <div className='es-stage-leftSide'>
                  <div className='es-info-area'>
                    <div className='es-info-index'>
                      {index + 1}
                    </div>

                    <div className='es-info'>
                      <div className='es-info-title'>
                        {stage.title}
                      </div>

                      <div className='es-info-description'>
                        {stage.description}
                      </div>

                      <div className='es-info-buttons'>
                        {buttonsTypeMap.get(stage.type)?.map((button: IEducationButton) => button.content(course?.physicalPersonDocumentLink, course?.legalEntityDocumentLink, buttonsMap.get(button.id)))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='es-stage-rightSide'>
                  {stage.image}
                </div>
              </div>
            ))}
          </div>}
      </div>
    </div>
  ), [
    ESTabs,
    buttonsMap,
    selectedEducationStagesTabIndex,
    swiperVisible,
    relevantEducationStagesFrameStaticData,
    course?.legalEntityDocumentLink,
    course?.physicalPersonDocumentLink,
  ]);

  return useMemo(() => {
    switch (course!.type) {
      case CourseType.plain:
        return (
          <Frame
            id={id}
            bgClassName='outer-background-margin'
            coverClassName={`bg----main-offline-color `}
            sectionClassName={'padding-bottom-80'}
          >
            {EducationStagesFrameContent}
          </Frame>
        );
      case CourseType.refresher:
        if(course!.fullPath == 'courses/refresher/online/software-tester'){
          return (
            <TestAccessCourse />
          )
          // return false;
        }
        return (
          <Frame
            id={id}
            coverClassName={`bg----main-offline-color `}
            sectionClassName={`${course!.type.toLowerCase()}`}
            bgClassName='outer-background-margin'
          >
            {EducationStagesFrameContent}
          </Frame>
        );
    }
  }, [EducationStagesFrameContent, course, id]);
};
