import React, { useContext, useRef } from 'react';
import './index.css';
import { useRadioBlock } from "../../components/radio/use-radio-block";
import { SuccessStatus } from "../signup-modal/success-status";
import { useQuestionForm } from "../../forms/use-question-form/use-question-form";
import { CourseContext } from "../../context/course-context";
import { DirectionContext } from "../../context/direction-context";

interface AskQuestionModalProps {}

export const AskQuestionModalContent: React.FC<AskQuestionModalProps> = () => {
  const {
    courseData
  } = useContext(CourseContext);

  const {
    direction
  } = useContext(DirectionContext);

  const {
    RelevantRadioBlock,
    selectedVariant: communicationMethod
  } = useRadioBlock(['По телефону', 'По почте'], 'typeCallQuestion');

  const formRef = useRef({} as HTMLFormElement);

  const {
    QuestionForm,
    Buttons,
    responseStatus
  } = useQuestionForm(
    courseData?.course?.id,
    direction?.id
  );

  return (
    <div className='form-request'>
      {responseStatus !== 200 ? (
        <>
          <div className='modal-textBlock'>
            <div className='modal__title'>Задать вопрос</div>
            <p className='modal__text'>Мы с удовольствием ответим на все ваши вопросы и порекомендуем подходящий план обучения</p>
          </div>

          {QuestionForm}
          {Buttons}
        </>
      ) : (
        <SuccessStatus type="question"/>
      )}
    </div>
  );
};
