import React from 'react';
import './index.css';

interface radioBlockProps {
  variants: Array<string>;
  blockName: string,
  selectedVariant: string;
  setSelectedVariant: React.Dispatch<React.SetStateAction<string>>;
}

export const RadioBlock: React.FC<radioBlockProps> = (
  {
    variants,
    blockName,
    selectedVariant,
    setSelectedVariant
  }
) => {
  return (
    <>
      <div className='radioBlock'>
        {variants.map((variant, index) => (
          <div key={index} className='radioBlock-Item'>
            <input
              type='radio'
              name={blockName}
              value={variant}
              id={`radio-${blockName}-${index}`}
              className='custom-radio'
              checked={selectedVariant === variant}
              onChange={() => setSelectedVariant(variant)}
            />

            <label htmlFor={`radio-${blockName}-${index}`}>{variant}</label>
          </div>
        ))}
      </div>
    </>
  );
};
