import React, { useCallback, useContext, useLayoutEffect, useMemo } from 'react';
import { NavList } from '../nav-list';
import './index.css';
import { Label } from '../../label';
import { CustomButton } from '../../button';
import gsap from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { NavBarRootState } from '../store/store';
import { allCoursesVisibleOn, modalBurgerVisibleOff } from '../store/slices/navbar-slice';
import { CourseContext } from '../../../context/course-context';
import { courseTypeFullNameMap } from '../../../api/mapper/map';
import { NavBarDto } from '../../../api/interfaces/elements/nav-bar/nav-bar-dto';
import { DirectionContext } from "../../../context/direction-context";
import { ModalContext } from "../../../context/modal-context";
import parse from "html-react-parser";
import moment from "moment/moment";

interface BurgerMenuModalProps {
  openModal: () => void;
  navBar: NavBarDto | undefined;
  className: string | undefined;
}

export const BurgerMenuModal: React.FC<BurgerMenuModalProps> = (
  {
    openModal,
    navBar,
    className
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const {
    direction
  } = useContext(DirectionContext);

  const {
    showProperSignupStateModal,
    showProperAskQuestionModal
  } = useContext(ModalContext);

  const course = courseData?.course;

  const animated = useSelector<NavBarRootState>(state => state.navBar.animated);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (animated) {
      gsap.from('.modal', {
        opacity: 0,
        ease: 'bounce.in',
      });

      gsap.to('.modal', {
        opacity: 1
      });
    } else {
      gsap.from('.modal', {
        opacity: 1,
        ease: 'bounce.in',
      });

      gsap.to('.modal', {
        opacity: 1
      });

      gsap.from('.modal-wrapper', {
        opacity: 0,
        ease: 'bounce.in',
      });

      gsap.to('.modal-wrapper', {
        opacity: 1
      });
    }
  }, [
    animated
  ]);

  const continueToNavList = useCallback(() => {
    dispatch(modalBurgerVisibleOff());
    dispatch(allCoursesVisibleOn());
  }, [
    dispatch
  ]);


  return useMemo(() => {
    if (course) {
      return (
        <div className='modal modalMenu'>
          <div className='modal-wrapper burgerMenu'>
            <div className='modal-container-top'>
              <div className='burgerMenu-courseInfo'>
                <h2 className='burgerMenu-courseInfo__title'>
                  {parse(course.name)}
                </h2>

                <Label
                  courseType={`${courseTypeFullNameMap(course.type, course.format)}`}
                />
              </div>
            </div>

            <nav className='burgerMenu-nav'>
              <NavList
                className={className}
                navBar={navBar}
              />
            </nav>

            <div className='modal-container'>
              <div className='burgerMenu-buttonContainer'>
                <CustomButton
                  buttonType={"button"}
                  type='button'
                  classList={[
                    'button--blue',
                    'color--online-color-text',
                    'border--offline-color'
                  ]}
                  title='Начать учиться'
                  arrowVisible
                  onClick={() => {
                    window.ym(direction?.meta?.counter, 'reachGoal', `${direction?.meta?.signUpButtonYandexTargetName}`);

                    openModal();
                    showProperSignupStateModal({ tabIndex: 0 });
                  }}
                />

                <CustomButton
                  buttonType={"button"}
                  onClick={continueToNavList}
                  type='link'
                  classList={[
                    'button--white'
                  ]}
                  title='Все курсы'
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (direction) {
      return (
        <div className='modal modalMenu'>
          <div className='modal-wrapper burgerMenu'>
            <div className='modal-container-top'>
              <div className='burgerMenu-courseInfo'>
                <h2 className='burgerMenu-courseInfo__title'>
                  {parse(direction.name ?? "")}
                </h2>

                <Label
                  classList={["uppercase"]}
                  courseType={`${direction.type} ${moment().year()}`}
                />
              </div>
            </div>

            <nav className='burgerMenu-nav'>
              <NavList
                className={className}
                navBar={navBar}
              />
            </nav>

            <div className='modal-container'>
              <div className='burgerMenu-buttonContainer'>
                <CustomButton
                  buttonType={"button"}
                  type='button'
                  classList={[
                    'button--blue',
                    'border--offline-color'
                  ]}
                  title='Связаться с нами'
                  onClick={() => {
                    openModal();
                    showProperAskQuestionModal();
                  }}
                />

                <CustomButton
                  buttonType={"button"}
                  onClick={continueToNavList}
                  type='link'
                  classList={['button--white']}
                  title='Все курсы'
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }, [direction, className, continueToNavList, course, navBar, openModal, showProperAskQuestionModal, showProperSignupStateModal]);
};
