import { forwardRef, useImperativeHandle } from 'react';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';
import { useSignupModal } from '../signup-modal/use-signup-modal';
import { SignupModalState } from '../signup-modal';

export const SignupModalWrapper = forwardRef((
  props,
  ref
) => {
  const {
    SignupModal,
    showModal,
    showStatedModal
  } = useSignupModal(() => {
    removeElementClass(document.body, 'modal-open');
  });

  useImperativeHandle(ref, () => ({
    showModal: () => showModal(),
    showStatedModal: (signupModalState: SignupModalState) => showStatedModal(signupModalState)
  }));

  return SignupModal;
});
