import React from "react";
import { EducationStagesFrameDto } from "../../../api/interfaces/frames/education-stages/education-stages-frame-dto";
import { getFileUrl } from "../../../api/url";

export enum EEducationStageType {
  PLAIN,
  SIGNUP,
  DOCUMENTS
}

export interface IEducationStage {
  title: React.ReactNode;
  description: React.ReactNode;
  image: React.ReactNode;
  type: EEducationStageType;
}

export interface IEducationStagesFrameTab {
  name?: React.ReactNode;
  stages: IEducationStage[];
}

export interface IEducationStagesFrameStaticData {
  title: React.ReactNode;
  titleDescription: React.ReactNode;
  tabs: IEducationStagesFrameTab[];
}

export const educationStagesFrameStaticDataRefresherOnline = (
  educationStagesFrame: EducationStagesFrameDto | null | undefined
): IEducationStagesFrameStaticData => ({
  title: <>
    Процесс обучения: от <span>начала</span> до <span>финала</span>
  </>,
  titleDescription: <>
    Вам не нужно лично приезжать в ИТМО — мы онлайн на все 100%!
  </>,
  tabs: [
    {
      name: <>
        <span>🚀</span> Быстрый вариант
      </>,
      stages: [
        {
          title: <>
            Вы оставляете на сайте заявку
          </>,
          description: <>
            Наши специалисты свяжутся с вами через email для подтверждения заявки (чаще всего это происходит
            день в день). Вам придёт письмо с инструкцией для быстрого старта: всё, что от вас потребуется
            — <b>заполнить готовые шаблоны документов</b> для обучения с ИТМО, выбрать удобный вариант оплаты
            и отправить файлы ответным письмом, приложив к ним <b>скан-копию имеющегося у вас Диплома</b>.
          </>,
          type: EEducationStageType.SIGNUP,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineSignUpImageId)}
            alt=""
          />
        },
        {
          title: <>
            Мы открываем доступ к обучению
          </>,
          description: <>
            В течение 1-3 дней мы подготовим для вас официальные документы по стандарту Университета и
            оперативно пришлём на email реквизиты для оплаты обучения. Получив с вашей стороны подтверждение
            (чек или платежное поручение), мы отправим <b>данные для входа</b> в Личный кабинет,
            где открывается доступ ко всем материалам программы.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineAccessImageId)}
            alt=""
          />
        },
        {
          title: <>
            Вы учитесь на удобной платформе
          </>,
          description: <>
            Виртуальная обучающая система ИТМО открывает вам все возможности <b>для комфортной и
            эффективной учебы</b>. Свободный график учёбы и отсутствие дедлайнов, продуманные практические
            задания и тесты, удобный лаконичный интерфейс — система организована таким образом, чтобы
            поддерживать высокую мотивацию на протяжении всего курса и помогать систематически приближаться к
            финалу обучения.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlinePlatformImageId)}
            alt=""
          />
        },
        {
          title: <>
            Мы выдаём Диплом ИТМО
          </>,
          description: <>
            После успешного завершения онлайн-курса мы подготовим для вас <b>Диплом о профессиональной
            переподготовке Университета ИТМО</b>. Вы сами выберете, как его получить: лично (актуально для
            жителей Санкт-Петербурга) или <b>почтой</b> (актуально для слушателей из других регионов). По
            запросу мы можем выслать вам скан-копию готового Диплома; сам документ изготавливается до 30 дней.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineDiplomaImageId)}
            alt=""
          />
        }
      ]
    },
    {
      name: <>
        <span>🔥</span> Популярный вариант
      </>,
      stages: [
        {
          title: <>
            Вы сразу присылаете документы
          </>,
          description: <>
            Вы самостоятельно скачиваете готовые шаблоны документов для обучения с ИТМО.
            Внимательно заполняете все три файла (Договор, Зачисление,
            Согласие) и присылаете всё на <a href='mailto:ado@itmo.ru'>ado@itmo.ru</a> (допустимые форматы:
            .doc,.docx). К письму обязательно приложите скан-копию своего Диплома о высшем или среднем
            профессиональном образовании.
          </>,
          type: EEducationStageType.DOCUMENTS,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineFillInDocsImageId)}
            alt=""
          />
        },
        {
          title: <>
            Мы открываем доступ к обучению
          </>,
          description: <>
            В течение 1-3 дней мы подготовим для вас официальные документы по стандарту Университета и
            оперативно пришлём на email реквизиты для оплаты обучения. Получив с вашей стороны подтверждение
            (чек или платежное поручение), мы отправим <b>данные для входа</b> в Личный кабинет,
            где открывается доступ ко всем материалам программы.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineAccessImageId)}
            alt=""
          />
        },
        {
          title: <>
            Вы учитесь на удобной платформе
          </>,
          description: <>
            Виртуальная обучающая система ИТМО открывает вам все возможности <b>для комфортной и
            эффективной учебы</b>. Свободный график учёбы и отсутствие дедлайнов, продуманные практические
            задания и тесты, удобный лаконичный интерфейс — система организована таким образом, чтобы
            поддерживать высокую мотивацию на протяжении всего курса и помогать систематически приближаться к
            финалу обучения.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlinePlatformImageId)}
            alt=""
          />
        },
        {
          title: <>
            Мы выдаём Диплом ИТМО
          </>,
          description: <>
            После успешного завершения онлайн-курса мы подготовим для вас <b>Диплом о профессиональной
            переподготовке Университета ИТМО</b>. Вы сами выберете, как его получить: лично (актуально для
            жителей Санкт-Петербурга) или <b>почтой</b> (актуально для слушателей из других регионов). По
            запросу мы можем выслать вам скан-копию готового Диплома; сам документ изготавливается до 30 дней.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineDiplomaImageId)}
            alt=""
          />
        }
      ]
    }
  ]
});

export const educationStagesFrameStaticDataPlainOnline = (
  educationStagesFrame: EducationStagesFrameDto | null | undefined
): IEducationStagesFrameStaticData => ({
  title: <>
    Процесс обучения: от <span>начала</span> до <span>финала</span>
  </>,
  titleDescription: <>
    Вам не нужно лично приезжать в ИТМО — мы онлайн на все 100%!
  </>,
  tabs: [
    {
      name: <>
        <span>🚀</span> Быстрый вариант
      </>,
      stages: [
        {
          title: <>
            Вы оставляете на сайте заявку
          </>,
          description: <>
            Наши специалисты свяжутся с вами через email для подтверждения заявки (чаще всего это происходит
            день в день). Вам придёт письмо с инструкцией для быстрого старта: всё, что от вас потребуется
            — <b>заполнить готовые шаблоны документов</b> для обучения с ИТМО, выбрать удобный вариант оплаты
            и отправить файлы ответным письмом, приложив к ним <b>скан-копию имеющегося у вас Диплома</b>.
          </>,
          type: EEducationStageType.SIGNUP,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineSignUpImageId)}
            alt=""
          />
        },
        {
          title: <>
            Мы открываем доступ к обучению
          </>,
          description: <>
            В течение 1-3 дней мы подготовим для вас официальные документы по стандарту Университета и
            оперативно пришлём на email реквизиты для оплаты обучения. Получив с вашей стороны подтверждение
            (чек или платежное поручение), мы отправим <b>данные для входа</b> в Личный кабинет,
            где открывается доступ ко всем материалам программы.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineAccessImageId)}
            alt=""
          />
        },
        {
          title: <>
            Вы учитесь на удобной платформе
          </>,
          description: <>
            Виртуальная обучающая система ИТМО открывает вам все возможности <b>для комфортной и
            эффективной учебы</b>. Свободный график учёбы и отсутствие дедлайнов, продуманные практические
            задания и тесты, удобный лаконичный интерфейс — система организована таким образом, чтобы
            поддерживать высокую мотивацию на протяжении всего курса и помогать систематически приближаться к
            финалу обучения.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlinePlatformImageId)}
            alt=""
          />
        },
        {
          title: <>
            Мы выдаём Удостоверение ИТМО
          </>,
          description: <>
            После успешного завершения онлайн-курса мы подготовим для вас <b>Удостоверение о повышении
            квалификации Университета ИТМО</b>. Вы сами выберете, как его получить: лично (актуально для
            жителей Санкт-Петербурга) или <b>почтой</b> (актуально для слушателей из других регионов). По
            запросу мы можем выслать вам скан-копию готового Удостоверения; сам документ изготавливается до 30
            дней.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineCertificateImageId)}
            alt=""
          />
        }
      ]
    },
    {
      name: <>
        <span>🔥</span> Популярный вариант
      </>,
      stages: [
        {
          title: <>
            Вы сразу присылаете документы
          </>,
          description: <>
            Вы самостоятельно скачиваете готовые шаблоны документов для обучения с ИТМО.
            Внимательно заполняете все три файла (Договор, Зачисление,
            Согласие) и присылаете всё на <a href='mailto:ado@itmo.ru'>ado@itmo.ru</a> (допустимые форматы:
            .doc,.docx). К письму обязательно приложите скан-копию своего Диплома о высшем или среднем
            профессиональном образовании.
          </>,
          type: EEducationStageType.DOCUMENTS,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineFillInDocsImageId)}
            alt=""
          />
        },
        {
          title: <>
            Мы открываем доступ к обучению
          </>,
          description: <>
            В течение 1-3 дней мы подготовим для вас официальные документы по стандарту Университета и
            оперативно пришлём на email реквизиты для оплаты обучения. Получив с вашей стороны подтверждение
            (чек или платежное поручение), мы отправим <b>данные для входа</b> в Личный кабинет,
            где открывается доступ ко всем материалам программы.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineAccessImageId)}
            alt=""
          />
        },
        {
          title: <>
            Вы учитесь на удобной платформе
          </>,
          description: <>
            Виртуальная обучающая система ИТМО открывает вам все возможности <b>для комфортной и
            эффективной учебы</b>. Свободный график учёбы и отсутствие дедлайнов, продуманные практические
            задания и тесты, удобный лаконичный интерфейс — система организована таким образом, чтобы
            поддерживать высокую мотивацию на протяжении всего курса и помогать систематически приближаться к
            финалу обучения.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlinePlatformImageId)}
            alt=""
          />
        },
        {
          title: <>
            Мы выдаём Удостоверение ИТМО
          </>,
          description: <>
            После успешного завершения онлайн-курса мы подготовим для вас <b>Удостоверение о повышении
            квалификации Университета ИТМО</b>. Вы сами выберете, как его получить: лично (актуально для
            жителей Санкт-Петербурга) или <b>почтой</b> (актуально для слушателей из других регионов). По
            запросу мы можем выслать вам скан-копию готового Удостоверения; сам документ изготавливается до 30
            дней.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.onlineCertificateImageId)}
            alt=""
          />
        }
      ]
    }
  ]
});

export const educationStagesFrameStaticDataOffline = (
  educationStagesFrame: EducationStagesFrameDto | null | undefined
): IEducationStagesFrameStaticData => ({
  title: <>
    Процесс обучения: от <span>начала</span> до <span>финала</span>
  </>,
  titleDescription: <></>,
  tabs: [
    {
      stages: [
        {
          title: <>
            Комфортное поступление на очный курс
          </>,
          description: <>
            Для поступления на очный курс повышения квалификации в наш корпус достаточно оставить на сайте
            заявку и дождаться обратной связи от команды Центра. Если учебная группа ещё не набрана и есть
            свободные места, за вами закрепляется место в ближайшем потоке. Если группа уже укомплектована, мы
            предварительно бронируем для вас место на следующий поток и заранее уведомляем о старте нового
            обучения.
          </>,
          type: EEducationStageType.SIGNUP,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.offlineSignUpImageId)}
            alt=""
          />
        },
        {
          title: <>
            Расписание занятий, сформированное заранее
          </>,
          description: <>
            Когда группа слушателей набрана, мы формируем итоговое расписание занятий и размещаем его на
            странице курса. Кроме того, расписание появляется на двери аудитории, где будет проходить
            обучение. В случае форс-мажорных изменений в графике мы обязательно уведомляем слушателей по
            e-mail или телефону. Большинство очных курсов идут 2-3 раза в неделю в вечернее время, с 18:30 до
            22:00.
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.offlineScheduleImageId)}
            alt=""
          />
        },
        {
          title: <>
            Очное вечернее обучение в корпусе ИТМО
          </>,
          description: <>
            Учиться предстоит в чудесном месте – наш корпус располагается в самом сердце Московского
            проспекта! Здание, в котором находятся учебные классы и офис нашего Центра, прячется в тихом,
            уютном дворике. Рядом есть большая бесплатная парковка; а если вы на метро, дойти до нас пешком от
            ст.м. "Московская" или "Парк Победы" можно за неспешные 7-10 минут. Очное обучение проходит в
            небольших группах до 10 человек — так преподаватель успевает уделить внимание каждому. Просторные,
            светлые классы оборудованы всей необходимой техникой (ноутбук не понадобится).
          </>,
          type: EEducationStageType.PLAIN,
          image: <img
            className='es-stage-image'
            src={getFileUrl(educationStagesFrame?.offlinePlatformImageId)}
            alt=""
          />
        }
      ]
    }
  ]
});
