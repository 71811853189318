import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import './index.css';
import { AccordionList } from '../../components/accordion';
import { IdProps } from '../../interfaces/id-props';
import { ProgramState } from '../../interfaces/program-state';
import { Tag } from '../../components/tag';
import { CourseContext } from '../../context/course-context';
import parse from 'html-react-parser';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { colors } from "../../constants/colors";

interface ProgramFrameProps extends IdProps {
}

export const ProgramFrame: React.FC<ProgramFrameProps> = (
  {
    id,
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const [programState, setProgramState] = useState<ProgramState>({
    anyActive: false,
    programs: new Array(course!.programFrame?.program.programItems.length ?? 0).fill(false)
  });

  const onToggleAllButtonClick = useCallback(() => {
    setProgramState(programStatesPrev => {
      const programStatePartActive = programStatesPrev.programs.find(programState => programState);

      return (
        {
          anyActive: !programStatePartActive,
          programs: programStatesPrev.programs.map(() => !programStatePartActive)
        }
      );
    });
  }, []);

  const onProgramClick = (index: number) => {
    setProgramState((prevState: ProgramState) => {
      const newState = structuredClone(prevState);

      newState.programs[index - 1] = !newState.programs[index - 1];
      newState.anyActive = newState.programs.find((programState: boolean) => programState) ?? false;

      return newState;
    });
  }

  const AllWrapper = (clazz: string) => (
    <div className={`${clazz} program-frame-all-wrapper`}>
      <div className={`program-frame-all ${programState.anyActive ? 'program-frame-all--active' : ''}`}
           onClick={() => {
             document.getElementById(`accordion-item-course-1`)?.scrollIntoView({ behavior: 'smooth' })
             onToggleAllButtonClick();
           }}
      >
        {programState.anyActive ? <span>Скрыть этапы</span> : <span>Раскрыть этапы</span>}

        <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M18.5 9L12.5 15L6.5 9' stroke={`${colors['--brand-itmo-green-lizard']}`}
                strokeWidth='1.5'/>
        </svg>
      </div>
    </div>
  );

  const Tags = useMemo(() => {
    switch (course?.format) {
      case CourseFormat.online:
        return (
          <>
            {(course.programFrame?.tags ?? []).map(tag => (
              <Tag text={tag}/>
            ))}
          </>
        );
      case CourseFormat.offline:
        return (
          <>
            {(course.programFrame?.tags ?? []).map(tag => (
              <Tag text={tag}/>
            ))}
          </>
        );
    }
  }, [
    course
  ]);

  return (
    <Frame
      id={id}
      bgClassName="outer-background-margin"
      coverClassName={`bg----main-offline-color program-frame`}
    >
      <div className='program-frame-wrapper'>
        <div className={`program-frame-row ${course!.type.toLowerCase()} ${course!.format.toLowerCase()}`}>
          <BorderedTitle
            titleClassList={[
              'text-design-bim-true-white'
            ]}
          >
            {parse(course!.programFrame?.program.title ?? "")}
          </BorderedTitle>

          <div className='program-frame-tags'>
            {Tags}
          </div>
        </div>

        <div className='programm-frame--acordBlock'>
          {<AccordionList
            programState={programState}
            onProgramClick={onProgramClick}
            annotated={course?.onlineAvailable}
          />}

          {AllWrapper('')}
        </div>
      </div>
    </Frame>
  );
};
