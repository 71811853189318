const cyrillicMonths = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

const days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

const getCyrillicMonth = (monthIndex: number) => cyrillicMonths[monthIndex];

export const dateToString = (
  date: Date,
  year: boolean = true
) => (
  `${date.getDate()} ${getCyrillicMonth(date.getMonth())} ${year ? date.getFullYear() : ''}`
);

export const getWeekDay = (date: Date) => {
  return days[date.getDay()];
}
