import React from 'react';
import './index.css';
import { StarRating } from '../../frames/introduction-frame/rating/stars';

interface RatingCommentProps {
  rating: number;
}

export const RatingComment: React.FC<RatingCommentProps> = (
  {
    rating
  }
) => {
  return (
    <div className='rating-comment'>
      <div className='rating-comment__text'>{rating} из 5</div>
      <div className='rating-comment-row'>
        {[...Array(rating)].map((index) =>
          <StarRating key={index}/>
        )}

        {[...Array((5 - rating))].map((index) =>
          <StarRating colorFill='#fff' key={index}/>
        )}
      </div>
    </div>
  );
};
