import { useModal } from '../../hooks/use-modal';
import React, { useMemo, useState } from 'react';
import { WorkModalContent, WorkModalState } from './index';

export const useWorkModal = (
  onClick?: () => void
) => {
  const [
    workModalState,
    setWorkModalState
  ] = useState({} as WorkModalState);

  const showStatedModal = (workModalState: WorkModalState) => {
    setWorkModalState(workModalState);
    showModal();
  }

  const {
    Modal,
    showModal,
  } = useModal('work-modal-frame-id', onClick);

  const WorkModal = useMemo(() => (
    <Modal
      classList={['modalMain-wrapper--workUser']}
    >
      <WorkModalContent
        workModalState={workModalState}
      />
    </Modal>
  ), [
    Modal,
    workModalState
  ]);

  return (
    {
      MoreInfoModal: WorkModal,
      showStatedModal
    }
  );
}
