import React, { useContext, useMemo } from 'react';
import './index.css';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { IdProps } from '../../interfaces/id-props';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { CourseContext } from '../../context/course-context';
import { getFileUrl } from '../../api/url';
import { ModalContext } from '../../context/modal-context';
import Tippy from '@tippyjs/react';
import { ifClassExists } from "../../utils/if-class-exists";

interface WorkFrameProps extends IdProps {
}

export const WorkFrame: React.FC<WorkFrameProps> = (
  {
    id
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    showProperWorkStateModal,
    showProperVideoStateModal
  } = useContext(ModalContext);

  const AdditionalZone = useMemo(() => {
    switch (course?.format) {
      case CourseFormat.online:
        return (
          <div className={`work-community ${ifClassExists('marginTop-0', !course?.workFrame?.works.length)}`}>
            <div className='work-community-header'>
              <div className='work-community-header-tag'>#коммуникация</div>
              <div className='work-community-header-line'></div>
              <div className='work-community-header-button'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <circle cx='12' cy='12' r='11.5' stroke='#0D0D0D' />
                  <rect x='3' y='11.0996' width='18' height='1.8' rx='0.9' fill='#0D0D0D' />
                </svg>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <circle cx='12' cy='12' r='11.5' stroke='#0D0D0D' />
                  <rect x='6.27148' y='5' width='17.9995' height='1.79995' rx='0.899973'
                    transform='rotate(45 6.27148 5)' fill='#0D0D0D' />
                  <rect width='17.9995' height='1.79995' rx='0.899973'
                    transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 6.27148 19)' fill='#0D0D0D' />
                </svg>
              </div>
            </div>

            <div className='work-community-body'>
              <div className='work-community-body-column'>
                <div className='work-community-body-column-b'>
                  <div className='work-community__title text-design-bim-primary-black'>Не нужно справляться в
                    одиночку — всегда есть <span>поддержка</span> и <span>обратная связь</span></div>
                  <div className='work-community-textBlock'>
                    <p className='work-community__text'>Для слушателей онлайн-курсов мы организуем
                      закрытый <span>Telegram-чат</span> — действенный инструмент, чтобы усилить
                      вовлечённость в обучение. </p>
                    <p className='work-community__text'>А ещё общение в мессенджере обеспечит вам прямую и
                      оперативную связь с
                      <Tippy
                        className='work-community__tooltip'
                        content={
                          <div className='work-community__text-tooltip'>
                            <p><span>Куратор</span> — это человек, который поддерживает вас на протяжении
                              всего
                              периода обучения.</p>

                            <p>Он будет помогать разбираться с материалом и находить нужные ответы, свободно
                              ориентироваться на образовательной онлайн-платформе, не испытывать сложностей
                              в
                              ходе выполнения практических заданий.</p>
                            <p>Куратор великолепно осведомлен в теме, которую вы изучаете. Ему можно смело
                              задавать любые вопросы.</p>
                          </div>}>
                        <div
                          className={`mentor tooltip-block-toOPen`}
                        >
                          куратором курса
                        </div>
                      </Tippy>
                    </p>
                  </div>
                </div>
              </div>

              <div className='work-community-body-column2'>
                <img src={getFileUrl(course.workFrame?.onlineChatsImageId)} alt=''
                  className='work-community-body-column2__img two' />
                <img src={getFileUrl(course.workFrame?.onlineChatImageId)} alt=''
                  className='work-community-body-column2__img2 one' />
              </div>
            </div>
          </div>
        );

      case CourseFormat.offline:
        if (course?.videoUrl == 'none') {
          return (
            <div className='work-community' style={{ 'background': 'inherit', 'border': 'none' }}></div>
          );
        } else {
          return (
            <div className={`work-community ${ifClassExists('marginTop-0', !course?.workFrame?.works.length)}`}>
              <div className='work-community-header'>
                <div className='work-community-header-tag'>#бонус</div>
                <div className='work-community-header-line'></div>
                <div className='work-community-header-button'>
                  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='12' cy='12' r='11.5' stroke='#0D0D0D' />
                    <rect x='3' y='11.0996' width='18' height='1.8' rx='0.9' fill='#0D0D0D' />
                  </svg>
                  <svg width='24' height='24' viewBox='0 0 24 24' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='12' cy='12' r='11.5' stroke='#0D0D0D' />
                    <rect x='6.27148' y='5' width='17.9995' height='1.79995' rx='0.899973'
                      transform='rotate(45 6.27148 5)' fill='#0D0D0D' />
                    <rect width='17.9995' height='1.79995' rx='0.899973'
                      transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 6.27148 19)' fill='#0D0D0D' />
                  </svg>
                </div>
              </div>

              <div className='work-community-body'>
                <div className='work-community-body-column'>
                  <div className='work-community-body-column-b'>
                    <div className='work-community__title text-design-bim-primary-black'>Пропустили занятие в
                      классе? Получите <span>видеозанятие в подарок</span>!
                    </div>
                    <div className='work-community-textBlock'>
                      <p className='work-community__text'>
                        Восполнить образовавшийся пробел в знаниях можно за счет материалов вспомогательного
                        видеокурса. Опция доступна в течение всего обучения.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='work-community-body-column2'>
                  <img
                    src={getFileUrl(course?.workFrame?.onlineLecturePreviewImageId)}
                    className='work-community-body-column2__img video'
                    onClick={() => {
                      showProperVideoStateModal({
                        url: course?.videoUrl
                      })
                    }}
                    alt=''
                  />
                </div>
              </div>
            </div>
          );
        }


    }
  }, [course, showProperVideoStateModal]);

  return (!!course?.videoUrl || !!course?.workFrame?.works.length) && (
    <div
      className='outer-background-margin bg-brand-itmo-bluetiful border--design-bim-primary-black--top-bottom'>
      <Frame
        id={id}
        coverClassName={`work-frame no-border ${ifClassExists("padbot-0", !!course?.videoUrl)} bor-rad-0`}
        sectionClassName={'padbot-0 no-padding-top'}
      >
        {!!course?.workFrame?.works.length && <div className='work-frame-row'>
          <BorderedTitle
            titleClassList={[
              'text-design-bim-true-white'
            ]}
          >
            <span>Примеры</span> работ
          </BorderedTitle>
        </div>}

        {!!course?.workFrame?.works.length && <Swiper
          className='swiper-WidthFull swiper-work'
          slidesPerView={'auto'}
          slidesPerGroup={1}
          spaceBetween={16}
          navigation={{
            nextEl: '.swiper-buttonCont-next',
            prevEl: '.swiper-buttonCont-prev'
          }}
          breakpoints={{
            '768px': {
              slidesPerView: 1
            }
          }}
          pagination={{
            type: 'fraction',
            el: '.swiper-buttonCont-pagination',
            clickable: true,
            renderFraction: (currentClass: string, totalClass: string) => (
              '<span class="' + currentClass + '"></span>' +
              ' из ' +
              '<span class="' + totalClass + '"></span>'
            )
          }}
          modules={[
            Navigation,
            Pagination
          ]}
        >
          {course?.workFrame?.works.map((work, index) => (
            <SwiperSlide key={index}>
              <div
                className='work-slide'
                onClick={() => showProperWorkStateModal({
                  index,
                  works: course?.workFrame?.works
                })}
              >
                <div className='work-slide-imgBlock'>
                  {work.videoUrl && <div className='video-tag'>
                    <svg fill="black" width="20px" height="20px" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M19,2 C20.6568542,2 22,3.34314575 22,5 L22,19 C22,20.6568542 20.6568542,22 19,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,5 C2,3.34314575 3.34314575,2 5,2 L19,2 Z M4,17 L4,19 C4,19.5522847 4.44771525,20 5,20 L6,20 L6,17 L4,17 Z M16,13 L8,13 L8,20 L16,20 L16,13 Z M20,17 L18,17 L18,20 L19,20 C19.5522847,20 20,19.5522847 20,19 L20,17 Z M20,9 L18,9 L18,15 L20,15 L20,9 Z M16,4 L8,4 L8,11 L16,11 L16,4 Z M19,4 L18,4 L18,7 L20,7 L20,5 C20,4.44771525 19.5522847,4 19,4 Z M6,4 L5,4 C4.44771525,4 4,4.44771525 4,5 L4,7 L6,7 L6,4 Z M4,15 L6,15 L6,9 L4,9 L4,15 Z" />
                    </svg>
                    Анимация
                  </div>}
                  {work.videoUrl && (
                    <div className="video-tag-play">
                      <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z" fill="black" />
                      </svg>
                    </div>
                  )}
                  <img
                    src={getFileUrl(work.workId)}
                    alt=''
                    className='work-slider__img'
                  />
                </div>
                {!!work.author && <div className='work-slide__author'>Автор: {work.author}</div>}
              </div>
            </SwiperSlide>
          ))}

          <div className='swiper-buttonCont swiper-buttonCont--grid'>
            <div className='swiper-buttonCont-button swiper-buttonCont-prev'>
              <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='28' cy='28' r='27.25' transform='matrix(-1 0 0 1 56 0)' fill='white'
                  stroke='#0D0D0D' strokeWidth='1.5' />
                <path
                  d='M16.6619 27.9811H39.3378M16.6619 27.9811C20.835 27.7513 29.2342 27.5261 29.2342 16.9941M16.6619 27.9811C20.8217 28.2038 29.2342 28.4737 29.2342 39.0057'
                  stroke='#0D0D0D' strokeWidth='1.5' />
              </svg>
            </div>

            <div className='swiper-buttonCont-pagination'></div>

            <div className='swiper-buttonCont-button swiper-buttonCont-next'>
              <svg width='56' height='57' viewBox='0 0 56 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='28' cy='28' r='27.25' transform='matrix(-1 0 0 1 56 0.333008)' fill='white'
                  stroke='#0D0D0D' strokeWidth='1.5' />
                <path
                  d='M39.3359 28.3141H16.66M39.3359 28.3141C35.1628 28.0843 26.7636 27.8591 26.7636 17.3271M39.3359 28.3141C35.1761 28.5368 26.7636 28.8068 26.7636 39.3387'
                  stroke='#0D0D0D' strokeWidth='1.5' />
              </svg>
            </div>
            <div className='work-community-linePos'></div>
          </div>
        </Swiper>}

        {!!course?.videoUrl && AdditionalZone}
      </Frame>
    </div>

  );
};
