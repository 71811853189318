import React from 'react';
import './index.css';
import { ifClassExists } from '../../utils/if-class-exists';
import { ClassListProps } from '../../interfaces/class-list-props';
import parse from 'html-react-parser';
import { colors } from "../../constants/colors";


interface PersonalizedPriceProps extends ClassListProps {
  personTitle: string | undefined;
  price: string | undefined;
  newPrice?: string | undefined;
  discount?: boolean | undefined;
  discountUnavailable?: boolean | undefined;
  discountDescription?: string | undefined;
}



export const PersonalizedPrice: React.FC<PersonalizedPriceProps> = (
  {
    personTitle,
    classList = [],
    price,
    newPrice,
    discount,
    discountUnavailable = false,
    discountDescription,
  }
) => {

  return (
    <div className={`personalized-price text-design-bim-true-white ${classList?.join(' ')}`}>
      <div className='personalized-price-wrapper'>
        <div className='pp-person-area'>
          <div className='pp-person'>
            {discount &&
              <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M19 5.5L5 19.5' stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round' />
                <path
                  d='M6.5 9.5C7.88071 9.5 9 8.38071 9 7C9 5.61929 7.88071 4.5 6.5 4.5C5.11929 4.5 4 5.61929 4 7C4 8.38071 5.11929 9.5 6.5 9.5Z'
                  stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth='2' strokeLinecap='round'
                  strokeLinejoin='round' />
                <path
                  d='M17.5 20.5C18.8807 20.5 20 19.3807 20 18C20 16.6193 18.8807 15.5 17.5 15.5C16.1193 15.5 15 16.6193 15 18C15 19.3807 16.1193 20.5 17.5 20.5Z'
                  stroke={`${colors['--brand-itmo-green-lizard']}`} strokeWidth='2' strokeLinecap='round'
                  strokeLinejoin='round' />
              </svg>
            }

            <div className='pp-person-text'>
              {parse(personTitle ?? '')}
            </div>
          </div>

          {discountDescription &&
            <div className='pp-price-discount-description'>
              {parse(discountDescription)}
            </div>
          }
        </div>

        <div className='pp-price-area'>
          {discount &&
            <div className='pp-price-discount'>
              {parse(price ?? '')}
            </div>
          }
          <div
            className={`pp-price ${ifClassExists('text-brand-itmo-green-lizard', !discountUnavailable && !!discount)}`}>
            {discountUnavailable || discount ?
              <span className='pp-price-text'>
                {parse(newPrice ?? "")}
              </span> :
              <span className='pp-price-text'>
                {parse(price ?? "")}
              </span>
            }
            ₽
          </div>
        </div>

      </div>
      {discountDescription &&
        <div className='pp-price-discount-description pp-price-discount-description2'>
          {discountDescription}
        </div>
      }
    </div>
  );

};
