import { CourseDto } from "../api/interfaces/course/course-dto";
import { ScheduleFrameDto } from "../api/interfaces/frames/schedule/schedule-frame-dto";

interface ReturnClassDates {
  classDates: Date[];
  firstClassDate: Date | undefined;
  lastClassDate: Date | undefined;
}

export const getClassDates = (scheduleFrame: ScheduleFrameDto | undefined) : ReturnClassDates => {
  const classDates = (scheduleFrame?.classDates ?? []).map(classDate => new Date(classDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")));

  const firstClassDate = classDates?.at(0) as Date | undefined;
  const lastClassDate = classDates?.at(-1) as Date | undefined;

  return {
    classDates,
    firstClassDate,
    lastClassDate
  } as ReturnClassDates
}
