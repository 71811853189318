import { forwardRef, useImperativeHandle } from 'react';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';
import { useFeedbackModal } from '../feedback-modal/use-feedback-modal';
import { ReviewModalState } from '../feedback-modal';

export const FeedbackModalWrapper = forwardRef((
  props,
  ref
) => {
  const {
    FeedbackModal,
    showStatedModal
  } = useFeedbackModal(() => {
    removeElementClass(document.body, 'modal-open');
  });

  useImperativeHandle(ref, () => ({
    showStatedModal: (feedbackModalState: ReviewModalState) => showStatedModal(feedbackModalState)
  }));

  return FeedbackModal;
});
