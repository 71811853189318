import React, { useContext } from 'react';
import './index.css';
import { Frame } from '../../components/frame';
import { BorderedTitle } from '../../components/bordered-title';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { RatingComment } from '../../components/rating-comment';
import { IdProps } from '../../interfaces/id-props';
import { CourseContext } from '../../context/course-context';
import parse from 'html-react-parser';
import { ModalContext } from '../../context/modal-context';
import { GenderType } from "../../api/interfaces/frames/review/gender-type";

import male from "./avatar/male.png";
import female from "./avatar/female.png";

interface FeedbackFrameProps extends IdProps {
}

const ArrowLeft = (
  <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='28' cy='28' r='27.25' transform='matrix(-1 0 0 1 56 0)' fill='white'
            stroke='#0D0D0D' strokeWidth='1.5'/>
    <path
      d='M16.6619 27.9811H39.3378M16.6619 27.9811C20.835 27.7513 29.2342 27.5261 29.2342 16.9941M16.6619 27.9811C20.8217 28.2038 29.2342 28.4737 29.2342 39.0057'
      stroke='#0D0D0D' strokeWidth='1.5'/>
  </svg>
);

const ArrowRight = (
  <svg width='56' height='57' viewBox='0 0 56 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='28' cy='28' r='27.25' transform='matrix(-1 0 0 1 56 0.333008)' fill='white'
            stroke='#0D0D0D' strokeWidth='1.5'/>
    <path
      d='M39.3359 28.3141H16.66M39.3359 28.3141C35.1628 28.0843 26.7636 27.8591 26.7636 17.3271M39.3359 28.3141C35.1761 28.5368 26.7636 28.8068 26.7636 39.3387'
      stroke='#0D0D0D' strokeWidth='1.5'/>
  </svg>
);

export const ReviewFrame: React.FC<FeedbackFrameProps> = (
  {
    id
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  const {
    showProperFeedbackModal
  } = useContext(ModalContext);

  return course?.reviewFrame?.reviews && course?.reviewFrame?.reviews.length > 0 && (
    <Frame
      id={id}
      bgClassName='outer-background-margin bg-brand-itmo-bluetiful border--design-bim-primary-black--top-bottom'
      coverClassName={`no-padding-x review-frame no-border`}
    >
      <BorderedTitle
        titleClassList={[
          'text-design-bim-true-white'
        ]}
      >
        <span>Отзывы</span> слушателей
      </BorderedTitle>
      <Swiper
        className='swiper-WidthFull swiper-comment'
        slidesPerView={'auto'}
        slidesPerGroup={1}
        spaceBetween={16}
        pagination={{
          type: 'fraction',
          el: '.swiper-buttonCont-pagination',
          clickable: true,
          renderFraction: (currentClass: string, totalClass: string) => (
            '<span class="' + currentClass + '"></span>' +
            ' из ' +
            '<span class="' + totalClass + '"></span>'
          )
        }}
        navigation={{
          nextEl: '.swiper-buttonCont-next',
          prevEl: '.swiper-buttonCont-prev'
        }}
        breakpoints={{
          '768px': {
            slidesPerView: 1
          }
        }}
        modules={[
          Navigation,
          Pagination
        ]}
      >
        {course?.reviewFrame?.reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <div className='comment-slider'>
              <div className='comment-slider-img'>
                <img
                  className='comment-image'
                  src={review.gender === GenderType.male ? male : female}
                  alt=''
                />
              </div>
              <div className='comment-slider-info'>
                <div className='comment-slider-info__authorName'>
                  {review.author}
                </div>

                <RatingComment rating={review.rating}/>

                <p className='comment-sldier-info__text'>
                  {parse(review.text)}
                </p>

                {review.text &&
                  <div
                    onClick={() => showProperFeedbackModal({ index })}
                    className='comment-showFullComment'
                  >
                    Показать весь отзыв
                  </div>}
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className='swiper-buttonCont'>
          <div className='swiper-buttonCont-button swiper-buttonCont-prev'>
            {ArrowLeft}
          </div>
          <div className='swiper-buttonCont-pagination'/>
          <div className='swiper-buttonCont-button swiper-buttonCont-next'>
            {ArrowRight}
          </div>
        </div>
      </Swiper>
    </Frame>
  );
};
