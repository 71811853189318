import { forwardRef, useImperativeHandle } from 'react';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';
import { useWorkModal } from '../work-modal/use-work-modal';
import { WorkModalState } from '../work-modal';

export const WorkModalWrapper = forwardRef((
  props,
  ref
) => {
  const {
    MoreInfoModal,
    showStatedModal
  } = useWorkModal(() => {
    removeElementClass(document.body, 'modal-open');
  });

  useImperativeHandle(ref, () => ({
    showStatedModal: (workModalState: WorkModalState) => showStatedModal(workModalState)
  }));

  return MoreInfoModal;
});
