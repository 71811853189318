import React from 'react';
import './index.css';
import { StarRating } from './stars';

interface RatingProps {
  value: number | undefined;
}

export const Rating: React.FC<RatingProps> = (
  {
    value
  }
) => {
  return (
    <div className='rating'>
      {value}
      <StarRating/>
    </div>
  );
};
