import React, { useMemo } from 'react';
import certificate from './img/certificate.png';
import diploma from './img/diploma.png';
import './index.css';

export interface DocumentModalState {
  type: "certificate" | "diploma";
}

interface DocumentModalProps {
  documentModalState: DocumentModalState;
}

export const DocumentModalContent: React.FC<DocumentModalProps> = (
  {
    documentModalState
  }
) => {
  const Document = useMemo(() => {
    switch (documentModalState.type) {
      case "certificate":
        return <img src={certificate} alt="" className='document__Img'/>;
      case "diploma":
        return <img src={diploma} alt="" className='document__Img'/>
    }
  }, [
    documentModalState.type
  ]);

  return (
    <div className="documentBlock">
      {Document}
    </div>
  );
};
