import React, { useMemo } from 'react';
import { colors } from '../../constants/colors';
import './index.css';

type ArrowType = "arrow-1" | "arrow-2";

interface ArrowProps {
  type: ArrowType;
  color?: string;
  className?: string | undefined;
}

export const Arrow: React.FC<ArrowProps> = (
  {
    type,
    color = colors['--design-bim-true-white'],
    className = []
  }
) => {
  return useMemo(() => {
    const commonProps: React.HTMLAttributes<SVGElement> = {
      className: `arrow ${className}`
    };

    switch (type) {
      case "arrow-1":
        return (
          <svg {...commonProps} width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4924_14343)">
              <path
                d="M22.5 12.9994L16.8829 8.09033V12.1812H2.25V13.8176H16.8829V17.9085L22.5 12.9994Z"
                fill={color}
              />
            </g>
            <defs>
              <clipPath id="clip0_4924_14343">
                <rect width="20.25" height="9.81818" fill={color} transform="translate(2.25 8.09131)"/>
              </clipPath>
            </defs>
          </svg>
        );
      case "arrow-2":
        return (
          <svg {...commonProps} width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.1357 11.1933L21.0728 11.1936C20.0193 11.1852 18.554 10.9216 17.3687 10.0138C16.2183 9.13265 15.2261 7.56472 15.2261 4.71484H13.7261C13.7261 7.95317 14.8774 9.98948 16.4451 11.1958H2.86328V12.689H16.5521C16.5258 12.7088 16.4996 12.729 16.4735 12.7493C14.8966 13.9793 13.7148 16.0385 13.7148 19.2844H15.2148C15.2148 16.4457 16.2248 14.8457 17.3961 13.9321C18.6006 12.9925 20.0728 12.7047 21.0762 12.6937L21.1223 12.6938L21.1223 12.6934L21.1357 12.6933V12.689V11.1958V11.1933Z"
              fill={color}
            />
          </svg>
        );
    }
  }, [
    className,
    color,
    type
  ]);
}
