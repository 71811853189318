import { configureStore } from '@reduxjs/toolkit';
import { navBarReducer } from './slices/navbar-slice';

export const navBarStore = configureStore({
  reducer: {
    navBar: navBarReducer,
  },
})

export type NavBarRootState = ReturnType<typeof navBarStore.getState>
export type NavBarDispatch = typeof navBarStore.dispatch
