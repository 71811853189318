import React, { useContext } from 'react';
import './index.css';
import { NavBar } from '../../../components/nav-bar';
import { IntroductionFrame } from '../../../frames/introduction-frame';
import { SignupModalWrapper } from '../../../modals/wrappers/signup-modal-wrapper';
import { NavStatus } from '../../../components/nav-bar/nav-status';
import { Provider } from 'react-redux';
import { navBarStore } from '../../../components/nav-bar/store/store';
import { SalaryFrame } from '../../../frames/salary-frame';
import { TargetsFrame } from '../../../frames/targets-frame';
import { AuthorFrame } from '../../../frames/author-frame';
import { ProgramFrame } from '../../../frames/program-frame';
import { GuaranteesFrame } from '../../../frames/guarantees-frame';
import { EducationStagesFrame } from '../../../frames/education-stages-frame';
import { FooterFrame } from '../../../frames/footer-frame';
import { FAQFrame } from '../../../frames/faq-frame';
import { TaxesFrame } from '../../../frames/taxes-frame';
import { WorkFrame } from '../../../frames/work-frame';
import { WorkModalWrapper } from '../../../modals/wrappers/work-modal-wrapper';
import { ReviewFrame } from '../../../frames/review-frame';
import { FeedbackModalWrapper } from '../../../modals/wrappers/feedback-modal-wrapper';
import { PriceFrame } from '../../../frames/price-frame';
import { ContactsFrame } from '../../../frames/contacts-frame';
import { AdditionalQuestionsFrame } from '../../../frames/additional-questions-frame';
import { BottomInfoZone } from '../../../components/bottom-info-zone';
import { ScrollUpButton } from '../../../components/scroll-up-button';
import { AgitateFrame } from '../../../frames/agitate-frame';
import { AskQuestionModalWrapper } from '../../../modals/wrappers/ask-question-modal-wrapper';
import { OtherCoursesFrame } from '../../../frames/other-courses-frame';
import { DocumentModalWrapper } from '../../../modals/wrappers/document-modal-wrapper';
import { ModalContext } from '../../../context/modal-context';
import { EducationFrame } from '../../../frames/education-frame';
import { ScheduleFrame } from '../../../frames/schedule-frame';
import { LocationModalWrapper } from '../../../modals/wrappers/location-modal-wrapper';
import { colors } from '../../../constants/colors';
import { CourseContext } from '../../../context/course-context';
import { useScrollToSection } from "../../../hooks/use-scroll-to-section";
import { usePageTitle } from "../../../hooks/use-page-title";
import { VideoModalWrapper } from "../../../modals/wrappers/video-modal-wrapper";
import Block_freeze_price from '../../../components/Block_freeze_price/Block_freeze_price';


export const OfflineCourse: React.FC = () => {
  const modalContextValue = useContext(ModalContext);

  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  usePageTitle(course, undefined);

  useScrollToSection();

  // console.log('test offline- ');
  // console.log(course?.directionType);
  // console.log(course);
  let arrFrize = {
    "bim": ['8 июля 2024', '#191760', '#D0FF14', 'https://bim.itmo.ru/catalog'],
    "cad": ['05 августа 2024', '#221802', '#FFCC33', 'https://cad.itmo.ru/catalog'],
    "code": ['4 июля 2024', '#191760', '#D0FF14', 'https://code.itmo.ru/catalog'],
    "3d": ['4 июля 2024', '#270A35', '#FFFF33', 'https://3d.itmo.ru/catalog'],
    "md": ['4 июля 2024', '#191760', '#D0FF14', 'https://md.itmo.ru/catalog'],
  };

  const dir = course?.directionType;
  const typePage = 'page';

  return course && (
    <div className='offline-course'>

      <NavStatus />

      <Provider store={navBarStore}>
        <NavBar
          className={`${course.type.toLowerCase()} ${course.format.toLowerCase()}`}
          navBar={course.navBar}
        />
      </Provider>
     
      <IntroductionFrame id='introduction-frame' />


       
      {/* {dir === 'cad' ? <Block_freeze_price type={typePage} dir={dir} styleType={'course'} dateEnd={arrFrize[dir][0]} backColor={arrFrize[dir][1]} mainColor={arrFrize[dir][2]} linkPage={arrFrize[dir][3]} /> : null} */}

      <SalaryFrame id='salary-frame' />

      <TargetsFrame
        id='targets-frame'
      />

      {course.scheduleFrames && course.scheduleFrames.map((scheduleFrame, index) => (
        <ScheduleFrame
          id='schedule-frame'
          titleContent={<><span>Расписание</span> занятий {(course.scheduleFrames?.length ?? 0) > 1 && <span>#{index + 1}</span>}</>}
          colorPoint={`${colors['--brand-itmo-green-lizard']}`}
          scheduleFrame={scheduleFrame}
        />
      ))}

      <AuthorFrame id='author-frame' />

      <ProgramFrame id='program-frame' />

      <GuaranteesFrame id='guarantees-frame' />

      <EducationFrame id='education-process-frame' />

      <EducationStagesFrame id='education-stages-frame' />

      <TaxesFrame id='taxes-frame' tax={13} />

      <WorkFrame id='work-frame' />

      <PriceFrame id='price-frame' />

      <ReviewFrame id='review-frame' />

      <AgitateFrame id='agitate-frame' />

      <ContactsFrame id='contacts-frame' />

      <OtherCoursesFrame id='other-courses-frame' />

      <FAQFrame id='faq-frame' />

      <AdditionalQuestionsFrame id='additional-questions-frame' />

      <FooterFrame />

      <ScrollUpButton />

      <BottomInfoZone />

      <SignupModalWrapper ref={modalContextValue.signupModalWrapperRef} />
      <AskQuestionModalWrapper ref={modalContextValue.askQuestionModalWrapperRef} />
      <VideoModalWrapper ref={modalContextValue.videoModalWrapperRef} />
      <DocumentModalWrapper ref={modalContextValue.documentModalWrapperRef} />
      <FeedbackModalWrapper ref={modalContextValue.feedbackModalWrapperRef} />
      <WorkModalWrapper ref={modalContextValue.workModalWrapperRef} />
      <LocationModalWrapper ref={modalContextValue.locationModalWrapperRef} />
      {/*<MoreInfoModalWrapper ref={modalContextValue.moreInfoModalWrapperRef} />*/}
    </div>
  );
};
