import React, { useMemo } from 'react';
import { useModal } from '../../hooks/use-modal';
import './index.css';
import { AskQuestionModalContent } from './index';

export const useAskQuestionModal = (
  onClick?: () => void
) => {
  const {
    Modal,
    showModal,
  } = useModal('ask-question-modal-frame-id', onClick);

  const AskQuestionModal = useMemo(() => (
    <Modal
      classList={['modalMain-wrapper--main']}
    >
      <AskQuestionModalContent/>
    </Modal>
  ), [
    Modal,
  ]);

  return (
    {
      AskQuestionModal,
      showModal
    }
  );
};
