import React, { useContext, useMemo } from 'react';
import './index.css';
import { Accordion } from './item';
import { ProgramState } from '../../interfaces/program-state';
import { Annotation } from './annotation';
import { CourseContext } from '../../context/course-context';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { DirectionContext } from "../../context/direction-context";
import { CourseType } from "../../api/interfaces/course/course-type";
export type ItemAccordType = 'course' | 'FAQ';

interface AccordionListProps {
  typeItem?: ItemAccordType;
  numberBool?: boolean;
  classList?: Array<string>;
  onProgramClick?: ((index: number) => void) | undefined;
  programState?: ProgramState;
  annotated?: boolean;
}

export const AccordionList: React.FC<AccordionListProps> = (
  {
    typeItem = 'course',
    numberBool = true,
    classList = [],
    onProgramClick,
    programState,
    annotated
  }
) => {
  const {
    courseData
  } = useContext(CourseContext);

  const {
    direction
  } = useContext(DirectionContext);

  const course = courseData?.course;

  const relevantFaqFrame = useMemo(() => {
    switch (course?.type) {
      case CourseType.plain:
        switch (course.format) {
          case CourseFormat.online:
            return direction?.plainOnlineFaqFrame;
          case CourseFormat.offline:
            return direction?.plainOfflineFaqFrame;
        }
        break;
      case CourseType.refresher:
        switch (course.format) {
          case CourseFormat.online:
            return direction?.refresherOnlineFaqFrame;
          case CourseFormat.offline:
            return direction?.refresherOfflineFaqFrame;
        }
        break;
    }
  }, [
    course,
    direction
  ]);

  const PreAccordion = useMemo(() => {
    switch (typeItem) {
      case 'course':
        return (
          <div className={`accordionList ${classList?.join(' ')}`}>
            {course!.programFrame?.program.programItems.map((tab, index) => (
              <Accordion
                key={index}
                index={index + 1}
                title={tab.title}
                content={tab}
                typeItem={typeItem as ItemAccordType}
                numberBool={numberBool as boolean}
                onProgramClick={onProgramClick}
                active={programState ? programState.programs[index] : false}
                classList={index === 0 ? ['first'] : index === (course!.programFrame?.program.programItems.length ?? 0) - 1 ? ['last'] : ['']}
              />
            ))}
          </div>
        );
      case 'FAQ':
        return (
          <div className={`accordionList ${classList?.join(' ')}`}>
            {relevantFaqFrame?.faqItems.map((tab, index) => (
              <Accordion
                key={index}
                index={index + 1}
                title={tab.question}
                content={tab}
                typeItem={typeItem as ItemAccordType}
                numberBool={numberBool as boolean}
                onProgramClick={onProgramClick}
                active={programState ? programState.programs[index] : false}
                classList={index === 0 ? ['first'] : index === (relevantFaqFrame.faqItems.length ?? 0) - 1 ? ['last'] : ['']}
              />
            ))}
          </div>
        );
    }
  }, [classList, course, numberBool, onProgramClick, programState, typeItem, relevantFaqFrame?.faqItems]);

  const FinalAccordion = useMemo(() => {   
    if (!annotated) {
      return PreAccordion
    }
    switch (course!.format) {
      case CourseFormat.offline:
        return (
          <Annotation>
            {PreAccordion}
          </Annotation>
        );
      default:
        return PreAccordion
    }
  }, [PreAccordion, annotated, course]);

  return (
    <>
      {FinalAccordion}
    </>
  );
};
